import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as CompletedIcon } from "../../../../../assets/icons/Views/candidate/assessments/completed.svg";
import { ReactComponent as UnCompletedIcon } from "../../../../../assets/icons/Views/candidate/assessments/redCross.svg";
import { ReactComponent as GrayLineIcon } from "../../../../../assets/icons/Views/candidate/assessments/line.svg";
import { ReactComponent as UnVisitedCircle } from "../../../../../assets/icons/Views/candidate/assessments/unvisitied-circle.svg";
import { ReactComponent as VisitedCircle } from "../../../../../assets/icons/Views/candidate/assessments/visitied-circle.svg";
import { ReactComponent as InfoIcon } from "../../../../../assets/icons/Views/candidate/assessments/info.svg";
import { ReactComponent as QualifyingQuestions } from "../../../../../assets/icons/Views/candidate/dashboard/qq.svg";
import { ReactComponent as SkillTest } from "../../../../../assets/icons/Views/candidate/dashboard/skill.svg";
import { ReactComponent as CodingTest } from "../../../../../assets/icons/Views/candidate/dashboard/code.svg";
import { ReactComponent as ValueFitness } from "../../../../../assets/icons/Views/candidate/dashboard/value.svg";
import { ReactComponent as SJIcon } from "../../../../../assets/icons/Views/dashboard/assessments/situational-mentoring.svg";
import { ReactComponent as PersonalityTest } from "../../../../../assets/icons/Views/candidate/dashboard/smile.svg";
import { ReactComponent as CaseStudyTest } from "../../../../../assets/icons/Views/candidate/dashboard/case.svg";
import { ReactComponent as TextResponse } from "../../../../../assets/icons/Views/candidate/dashboard/text.svg";
import { ReactComponent as VideoResponse } from "../../../../../assets/icons/Views/candidate/dashboard/video.svg";
import { ReactComponent as FileUpload } from "../../../../../assets/icons/Views/candidate/dashboard/upload.svg";
import ProgressBar from "../../../../../components/common/ProgressBar";
import {
  calculateExpiry,
  secondsToDhmsFormat,
} from "../../../../../utils/utilities";

export default function CandidateAssessmentResults(props) {
  const { assessment } = props;
  const [activeTest, setActiveTest] = useState(-1);

  const testIcons = {
    skill_test: <SkillTest />,
    coding_test: <CodingTest />,
    value_test: <ValueFitness />,
    situational_judgment_test: (
      <SJIcon style={{ width: "20px", height: "20px", stroke: "#808191" }} />
    ),
    personality_test: <PersonalityTest />,
    case_study_test: <CaseStudyTest />,
    text_test: <TextResponse />,
    video_test: <VideoResponse />,
    upload_test: <FileUpload />,
  };

  useMemo(() => {
    if (
      assessment?.qualifying_questions &&
      assessment?.qualifying_questions.length > 0 &&
      assessment?.attempted_qq
    ) {
      setActiveTest(
        assessment?.sections?.filter((_sec) => {
          return _sec.completed === false;
        })[0]
      );
    } else if (assessment?.qualifying_questions === null) {
      setActiveTest(
        assessment?.sections?.filter((_sec) => {
          return _sec.completed === false;
        })[0]
      );
    }
  }, [assessment]);

  return (
    <div className="candidate-assessment-results">
      {["DECLINED", "NOT_QUALIFIED", "EXPIRED"].includes(
        assessment?.status
      ) && (
        <div className="decline-tag-card">
          <div>
            <InfoIcon />
          </div>
          <span className="body-2 danger-text text-start">
            {assessment?.status === "EXPIRED"
              ? `Assessment Expired on ${calculateExpiry(
                  assessment.deadline,
                  assessment.created_at
                )}`
              : assessment.status === "NOT_QUALIFIED"
              ? "You are unable to proceed with this test as you did not pass the qualifying questions."
              : `Assessment Declined on ${new Date(
                  assessment?.updated_at
                ).toDateString()}`}
          </span>
        </div>
      )}
      <ProgressBar
        varrient="custom"
        value={parseInt(
          ((assessment?.tests.filter((test, _) => {
            return test?.completed === true;
          }).length +
            (assessment?.qualifying_questions && assessment?.attempted_qq
              ? 1
              : 0)) /
            (assessment?.tests?.length +
              (assessment?.qualifying_questions ? 1 : 0))) *
            100
        )}
      />
      <div className="test-card-container">
        {assessment?.qualifying_questions?.length > 0 && (
          <div className="test-card">
            <div
              className="d-flex flex-row align-items-center"
              style={{ gap: "12px" }}
            >
              <QualifyingQuestions />
              <span className={`subtitle-2 dark-100`}>
                {"Qualifying Questions"}
              </span>
            </div>
            <div
              className="candidate-status-container body-2"
              style={{
                width: "110px",
                marginLeft: "24px",
                border:
                  assessment?.status === "NOT_QUALIFIED" ||
                  !assessment?.attempted_qq
                    ? "1px solid black"
                    : "",
                background:
                  assessment?.status !== "NOT_QUALIFIED" &&
                  assessment?.attempted_qq
                    ? "#7C21FF"
                    : "#FFFFFF",
                color:
                  assessment?.status !== "NOT_QUALIFIED" &&
                  assessment?.attempted_qq
                    ? "#FFF"
                    : "#121216",
              }}
            >
              {assessment.status === "NOT_QUALIFIED"
                ? "Failed"
                : assessment?.attempted_qq
                ? "Completed"
                : "Pending"}
            </div>
          </div>
        )}
        {assessment?.sections.map((_section, _index) => {
          const filteredTests = assessment?.tests.filter(
            (t) => t.test_type === _section.type
          );
          const totalDuration = filteredTests.reduce(
            (acc, t) => acc + t.duration,
            0
          );
          return (
            <div key={_index} className="test-card">
              <div
                className="d-flex flex-row align-items-center"
                style={{ gap: "12px" }}
              >
                {testIcons[_section.type]}
                <div className="d-flex flex-column align-items-start justify-content-center">
                  <span className={`subtitle-2 dark-100`}>
                    {_section?.intro_text}
                  </span>
                  <span className="body-2 dark-50">
                    {totalDuration
                      ? `~ ${secondsToDhmsFormat(totalDuration)}`
                      : ""}
                  </span>
                </div>
              </div>
              {assessment.status !== "NOT_QUALIFIED" && (
                <div
                  className="candidate-status-container body-2"
                  style={{
                    marginLeft: "24px",
                    border: !_section?.completed ? "1px solid black" : "",
                    background: _section?.completed ? "#7C21FF" : "#FFFFFF",
                    color: _section?.completed ? "#FFF" : "#121216",
                  }}
                >
                  {_section?.completed
                    ? "Completed"
                    : assessment.status === "EXPIRED"
                    ? "EXPIRED"
                    : _section?.type === "personality_test"
                    ? "Attempt pending"
                    : "Pending"}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
