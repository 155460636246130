import React, { useContext, useEffect, useMemo, useState } from "react";
import AvtarBadge, {
  GetAvtarImage,
} from "../../../components/dashboard/AvtarBadge";
import { ReactComponent as GreenArrowDownIcon } from "../../../assets/icons/Views/dashboard/test-detail/green-down-arrow.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/Views/dashboard/test-detail/Rounded Action.svg";
import { ReactComponent as WarningIcon } from "../../../assets/icons/Views/dashboard/test-detail/alert-triangle2.svg";
import { ReactComponent as AlertTriangleIcon } from "../../../assets/icons/Views/dashboard/test-detail/alert-triangle.svg";
// import { ReactComponent as ErrorIcon } from '../../../assets/icons/Views/dashboard/test-detail/error.svg';
import { TestType, UserRole } from "../../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  patchAssessment,
  patchPublicLinkState,
} from "../../../redux/thunks/Assessment";
import { setSwitchState } from "../../../redux/slices/AssessmentSlice";
import ProcessingIndicator from "../../../components/common/ProcessingIndicator";
import AssessmentResultCard from "../../../components/dashboard/assessments/AssessmentResultCard";
import AssessmentPersonalityResultCard from "../../../components/dashboard/assessments/AssessmentPersonalityResultCard";
import Select from "../../../components/sub-component/Select";
import AlertBox from "../../../components/common/AlertBox";
import { getAssessSpec } from "../../../redux/thunks/AssessSpec";
import { useParams } from "react-router-dom";
import AssessmentResults from "./AssessmentResults";
import { CommonContext } from "../../../utils/contexts/CommonContext";
import { getFormatedDate } from "../../../utils/utilities";
import { ReactComponent as ShareIcon } from "../../../assets/icons/Views/dashboard/test-detail/share3.svg";
import { ReactComponent as TimerIcon } from "../../../assets/icons/Views/dashboard/test-detail/HourglassMedium(1).svg";
import { ReactComponent as InviteIcon } from "../../../assets/icons/Views/dashboard/test-detail/streamline_mail-incoming.svg";
import { ReactComponent as LastActivity } from "../../../assets/icons/Views/dashboard/test-detail/Vector1.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/Views/dashboard/assessments/info.svg";
import { ReactComponent as SmileIcon } from "../../../assets/icons/Views/dashboard/test-detail/smile.svg";
import { ReactComponent as LockIcon } from "../../../assets/icons/Views/dashboard/test-detail/lock.svg";
import { ReactComponent as BtnArrowIcon } from "../../../assets/icons/Views/dashboard/test-detail/arrow-right.svg";
import { ReactComponent as CrossIcon } from "../../../assets/icons/Views/dashboard/test-detail/x.svg";
import { ReactComponent as SelectIcon } from "../../../assets/icons/Views/dashboard/test-detail/hiring-select.svg";
import Button from "../../../components/sub-component/Button";
import { useMediaQuery } from "@mui/material";
import AssessmentActionBtnComp from "../../../components/dashboard/assessments/AssessmentActionBtnComp";
import { calculateExpiry, calculateScore } from "../../../utils/utilities";
import ProgressBar from "../../../components/common/ProgressBar";
import { ResponsiveRadar } from "@nivo/radar";
import CustomTooltip from "../../../components/common/CustomTooltip";
import CustomModal from "../../../components/common/Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function TestDetailsRightView(props) {
  const isOnWebScreen = useMediaQuery("(min-width:1200px)");
  const isOnTabletScreen = useMediaQuery(
    "(min-width:768px) and (max-width:1199px)"
  );
  const isOnMobileScreen = useMediaQuery("(max-width: 767px)");
  const { onClose, isForCandidate, assessment, isForPublic } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [tests, setTests] = useState([]);
  const [alert, setAlert] = useState(null);
  const [expandedTests, setExpandedTests] = useState([0, 1, 2, 3, 4]);
  const dispatch = useDispatch();

  const [openShareResultModal, setOpenShareResultModal] = useState(false);
  const { commonValues, setCommonValues } = useContext(CommonContext);
  const assess = useSelector((state) => state.assessmentReducer);
  const user = useSelector((state) => state.userReducer);
  const params = useParams();
  const history = useHistory();

  const handleCandidateProfile = (assessment) => {
    if (!isForCandidate) {
      const userId = assessment?.user?.id;
      if (userId) {
        history.push(`/dashboard/candidates/${userId}`);
      }
    }
  };

  useMemo(() => {
    const _tests = [];
    Object.values(TestType).map((testType, _) => {
      const filteredTests = assessment?.tests.filter((_test) => {
        return _test.test_type === testType;
      });
      if (filteredTests?.length > 0) {
        _tests.push(filteredTests);
      }
    });
    setTests([..._tests]);
  }, [assessment?.tests]);

  const isAttempted = tests && tests.some((test) => test[0].completed);

  let skillScore = 0;
  let sjScore = 0;
  let codingScore = 0;
  const _tests =
    tests &&
    tests?.filter(
      (t) =>
        (t[0]?.test_type === "skill_test" ||
          t[0]?.test_type === "situational_judgment_test" ||
          t[0]?.test_type === "coding_test") &&
        (t[0]?.completed || t[0]?.prev_test_results)
    );
  _tests
    ?.filter((t) => t[0]?.test_type === "skill_test")
    ?.map((test, _) => {
      skillScore += parseFloat(calculateScore(test));
    });
  _tests
    ?.filter((t) => t[0]?.test_type === "situational_judgment_test")
    ?.map((test, _) => {
      sjScore += parseFloat(calculateScore(test));
    });
  _tests
    ?.filter((t) => t[0]?.test_type === "coding_test")
    ?.map((test, _) => {
      codingScore += parseFloat(calculateScore(test));
    });
  const totalSkillScore = (skillScore + sjScore + codingScore) / _tests.length;

  let valueScore = null;
  tests &&
    tests
      ?.find((t) => t[0]?.test_type === "value_test" && t[0]?.completed)
      ?.forEach((test, _) => {
        valueScore += parseFloat(calculateScore(test.test_results));
      });

  const onExpandTest = (testIndex) => {
    if (
      expandedTests.filter((_testIndex, _) => {
        return _testIndex === testIndex;
      }).length > 0
    ) {
      setExpandedTests([
        ...expandedTests.filter((_testIndex, _) => {
          return _testIndex !== testIndex;
        }),
      ]);
    } else {
      setExpandedTests([...expandedTests, testIndex]);
    }
  };

  if (!assessment) {
    return <ProcessingIndicator className="medium-indicator" />;
  }
  const nameHandler = (assessment) =>
    assessment.user?.first_name || assessment.user?.last_name
      ? `${assessment.user?.first_name || ""} ${
          assessment.user?.last_name || ""
        }`
      : `${assessment?.email || "User name"}`;

  const handlePublicLinkState = async () => {
    const newState = !assessment?.shared_publicly;
    dispatch(setSwitchState(newState));
    const dispatched = await dispatch(
      patchPublicLinkState({
        assess_id: assessment?.id,
        data: { shared_publicly: newState },
      })
    );
    if (!dispatched.error) {
      setAlert(
        <AlertBox
          color="success"
          description={`${
            !dispatched?.payload?.shared_publicly
              ? "Assessment Result is private."
              : "Assessment Result is public."
          }`}
          title="Success"
          isOpen={true}
          onCloseAlert={() => {
            setAlert(null);
          }}
        />
      );
      props.onUpdateSharedPublicly(dispatched?.payload?.shared_publicly);
    } else {
      setAlert(
        <AlertBox
          color="danger"
          description={`Failed to change Assessment Result status.'}`}
          title="Error"
          isOpen={true}
          onCloseAlert={() => {
            setAlert(null);
          }}
        />
      );
    }
  };

  const handleUpdateHiringStage = async (option) => {
    // if (assessment?.hiring_stage !== option?.value) {
    const dispatched = await dispatch(
      patchAssessment({
        id: assessment.id,
        data: {
          hiring_stage:
            option.value === "SHORTLISTED" ? "SHORTLISTED" : option.value,
        },
      })
    );

    if (!dispatched.error) {
      setAlert(
        <AlertBox
          color="success"
          description={`${
            isForCandidate ? props?.userName || "-" : nameHandler(assessment)
          } is in ${
            dispatched?.payload?.hiring_stage?.charAt(0)?.toUpperCase() +
            dispatched?.payload?.hiring_stage?.slice(1)?.toLowerCase()
          } Stage`}
          title="Success"
          isOpen={true}
          onCloseAlert={() => {
            setAlert(null);
          }}
        />
      );
      props.onHiringStageUpdate(dispatched?.payload?.hiring_stage);
    }
    // }
  };

  const Pagination = ({ currentPage, totalPages, setCurrentPage }) => {
    const handleNext = () => {
      if (props.selectedIndex < props.assessmentsLength - 1) {
        props.onSwitchAssessment(true);
      }
    };

    const handlePrev = () => {
      if (props.selectedIndex >= 1) {
        props.onSwitchAssessment();
      }
    };

    const handleKeyDown = (event) => {
      switch (event.key) {
        case "ArrowLeft":
          handlePrev();
          break;
        case "ArrowRight":
          handleNext();
          break;
        default:
          // Do nothing for other keys
          break;
      }
    };
    React.useEffect(() => {
      // Attach the event listener when the component mounts
      window.addEventListener("keydown", handleKeyDown);

      // Detach the event listener when the component unmounts
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, [window]); // Empty dependency array means this effect runs once on mount and cleans up on unmount
    return (
      <div
        className="pagination"
        style={{ gap: isOnWebScreen ? "24px" : "12px" }}
      >
        <div
          className="h-100 d-flex flex-row align-items-center pointer"
          style={{ gap: isOnWebScreen ? "24px" : "12px" }}
          onClick={handlePrev}
        >
          <ArrowIcon
            className={`pointer ${
              props.selectedIndex === 0 && "disable-arrow-icon"
            }`}
            style={{
              width: isOnMobileScreen ? "34px" : "44px",
              height: isOnMobileScreen ? "34px" : "44px",
            }}
          />
          <span className={`body-2 dark-50`}>Previous</span>
        </div>
        <div
          className="h-100 d-flex flex-row align-items-center pointer"
          style={{ gap: isOnWebScreen ? "24px" : "12px" }}
          onClick={handleNext}
        >
          <span className={`body-2 dark-50`}>Next</span>
          <ArrowIcon
            className={`pointer ${
              props.selectedIndex === props.assessmentsLength - 1 &&
              "disable-arrow-icon"
            }`}
            style={{
              width: isOnMobileScreen ? "34px" : "44px",
              height: isOnMobileScreen ? "34px" : "44px",
              transform: "rotate(180deg)",
            }}
          />
        </div>
      </div>
    );
  };

  const customLabel = (props) => {
    const anchor = props.x > 2 ? "start" : props.x < -2 ? "end" : "middle";
    return (
      <g transform={`translate(${props.x}, ${props.y})`}>
        <text alignmentBaseline={"middle"} textAnchor={anchor} fontSize={12}>
          {data.category}
        </text>
      </g>
    );
  };

  const MyResponsiveRadar = ({ data }) => (
    <ResponsiveRadar
      data={data}
      maxValue={10}
      keys={["Score"]}
      indexBy="category"
      valueFormat=">-.2f"
      margin={{ top: 10, right: 0, bottom: -10, left: 0 }}
      borderColor={{ from: "color" }}
      gridLabel={customLabel}
      gridShape="linear"
      gridLevels={4}
      gridLabelOffset={36}
      dotSize={10}
      dotColor={{ theme: "background" }}
      dotBorderWidth={2}
      colors={["#6F2DBD", "#6F2DBD", "#6F2DBD"]}
      blendMode="multiply"
      motionConfig="wobbly"
    />
  );

  const AntiCheatIcon = () => {
    // if (!assessment?.anti_cheat_data?.same_location && !assessment?.anti_cheat_data?.same_device && assessment?.anti_cheat_data?.is_exited_full_screen && assessment?.anti_cheat_data?.is_left_screen) {
    // 	return <ErrorIcon />
    // } else
    if (
      !assessment?.anti_cheat_data?.same_location ||
      !assessment?.anti_cheat_data?.same_device ||
      assessment?.anti_cheat_data?.is_exited_full_screen ||
      assessment?.anti_cheat_data?.is_left_screen
    ) {
      return (
        <CustomTooltip text="Some candidate activity might need your attention">
          <WarningIcon />
        </CustomTooltip>
      );
    } else {
      return (
        <CustomTooltip text="No suspicious activity detected">
          <AlertTriangleIcon />
        </CustomTooltip>
      );
    }
  };
  const data = [
    {
      category: "Personality Match",
      Score: assessment?.personality_test_result?.score
        ? parseFloat(
            (10 / 1) * assessment?.personality_test_result?.score
          ).toFixed(1)
        : 0,
    },
    {
      category: "Skills and Ability",
      Score: totalSkillScore,
    },
    {
      category: "Value Alignment",
      Score: valueScore,
    },
  ];

  return (
    <>
      {alert}
      <>
        <CustomModal
          open={openShareResultModal}
          varrient="copy-result-link-modal"
          inviteLink={`${window.location.origin}/assessment-result/${assessment.id}`}
          checked={
            assess?.processing
              ? assess?.shared_publicly
              : assessment?.shared_publicly
          }
          handlePublicLinkState={() => {
            handlePublicLinkState();
          }}
          handleCloseModal={() => {
            setOpenShareResultModal(false);
          }}
        />
      </>
      <div className="test-detail-right-view-container">
        <div className="w-100 h-100 d-flex flex-column" style={{ gap: "24px" }}>
          {!isForPublic && (
            <div
              className="w-100 d-flex align-items-start justify-content-between"
              style={{ minHeight: "52px", gap: "12px" }}
            >
              <div>
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={2}
                />
              </div>
              <div
                className="d-flex flex-row align-items-center justify-content-end"
                style={{
                  width: isOnMobileScreen
                    ? "60px"
                    : isOnTabletScreen
                    ? "250px"
                    : "280px",
                  gap: isOnWebScreen ? "24px" : "12px",
                }}
              >
                <Select
                  readOnly={true}
                  varrient={"select-with-mbl-icon"}
                  style={{
                    maxWidth: isOnTabletScreen ? "200px" : "230px",
                    height: "30px",
                  }}
                  options={[
                    { name: "Not Yet Evaluated", value: "PENDING" },
                    { name: "Rejected", value: "REJECTED" },
                    { name: "Shortlisted", value: "SHORTLISTED" },
                  ]}
                  onSelection={(option) => handleUpdateHiringStage(option)}
                  selected={assessment?.hiring_stage}
                  title={""}
                  className={"body-3"}
                  placeholder={"Hiring Stage"}
                  titleStyle={{ color: "#7A869A" }}
                  selectIcon={
                    <SelectIcon style={{ width: "34px", height: "34px" }} />
                  }
                />
                <div className="pointer" onClick={onClose}>
                  <CrossIcon
                    style={{
                      width: isOnMobileScreen ? "34px" : "44px",
                      height: isOnMobileScreen ? "34px" : "44px",
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="w-100 d-flex flex-row align-items-start justify-content-between">
            <div
              className="w-100 d-flex flex-column"
              style={{ marginLeft: "8px", gap: "8px" }}
            >
              <div className="d-flex flex-row" style={{ gap: "12px" }}>
                <span
                  className="headline-medium semibold"
                  style={{
                    textAlign: "left",
                    maxWidth: isOnMobileScreen
                      ? "80px"
                      : isOnTabletScreen
                      ? "200px"
                      : "250px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: !isForCandidate ? "pointer" : "default",
                    transition: "color 0.2s ease-in-out",
                  }}
                  onMouseEnter={(e) => {
                    if (!isForCandidate) e.target.style.color = "#6F2DBD";
                  }}
                  onMouseLeave={(e) => {
                    if (!isForCandidate) e.target.style.color = "inherit";
                  }}
                  onClick={() => handleCandidateProfile(assessment)}
                >
                  {isForCandidate ? assessment.title : nameHandler(assessment)}
                </span>
                <div
                  key="1"
                  className="candidate-status-container"
                  style={{
                    border:
                      assessment.status !== "FINISHED" ? "1px solid black" : "",
                    background:
                      assessment.status === "FINISHED" ? "#7C21FF" : "#FFFFFF",
                    color:
                      assessment.status === "FINISHED" ? "#FFF" : "#121216",
                  }}
                >
                  {assessment.status === "PENDING"
                    ? "Pending"
                    : assessment.status === "IN_PROGRESS"
                    ? "In Progress"
                    : assessment.status === "NOT_QUALIFIED"
                    ? "Not Qualified"
                    : assessment.status === "DECLINED"
                    ? "Declined"
                    : assessment.status === "EXPIRED"
                    ? "EXPIRED"
                    : assessment.status === "INVITE_BOUNCED"
                    ? "Invite Bounced"
                    : "Completed"}
                </div>
              </div>
              {!isForCandidate && (
                <span
                  className="body-2 medium dark-100"
                  style={{
                    textAlign: "left",
                    maxWidth: "250px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {assessment?.email || assessment?.user?.email}
                </span>
              )}
              <div
                className="d-flex flex-row align-items-center"
                style={{ gap: "6px" }}
              >
                <InviteIcon />
                <span
                  className={`${
                    isOnMobileScreen ? "body-3" : "body-2"
                  } black-500`}
                >
                  {"Invited on "}
                  <span className="dark-100">
                    {getFormatedDate(assessment.created_at).replace("at", "/")}
                  </span>
                </span>
              </div>
              {assessment.deadline && (
                <div
                  className="d-flex flex-row align-items-center"
                  style={{ gap: "6px" }}
                >
                  <InfoIcon />
                  <span
                    className={`${
                      isOnMobileScreen ? "body-3" : "body-2"
                    } black-500`}
                  >
                    {"Deadline "}
                    <span className="dark-100">
                      {calculateExpiry(
                        assessment.deadline,
                        assessment.created_at
                      )}
                    </span>
                  </span>
                </div>
              )}
              {(assessment.status === "IN_PROGRESS" ||
                assessment.status === "FINISHED") && (
                <div
                  className="d-flex flex-row align-items-center"
                  style={{ gap: "6px" }}
                >
                  <LastActivity style={{ width: "18px", height: "18px" }} />
                  <span
                    className={`${
                      isOnMobileScreen ? "body-3" : "body-2"
                    } black-500`}
                  >
                    {"Last Activity "}
                    <span className="dark-100">
                      {getFormatedDate(assessment.updated_at).replace(
                        "at",
                        "/"
                      )}
                    </span>
                  </span>
                </div>
              )}
            </div>
            <div className="d-flex flex-row" style={{ gap: "12px" }}>
              {assessment.status === "FINISHED" &&
                !isForCandidate &&
                !isForPublic && (
                  <ShareIcon
                    style={{ height: "44px", width: "44px", cursor: "pointer" }}
                    onClick={() => {
                      setOpenShareResultModal(true);
                    }}
                  />
                )}
              {isForCandidate && isOnMobileScreen ? (
                <></>
              ) : (
                <GetAvtarImage
                  className="assessment-upload-icon pointer"
                  src={
                    isForCandidate
                      ? {
                          ...assessment,
                          image_id: assessment?.avatar_id,
                          name: assessment.title,
                        }
                      : {
                          ...assessment?.user,
                          name: nameHandler(assessment),
                        }
                  }
                  isForPublic={isForPublic}
                  onClick={() => handleCandidateProfile(assessment)}
                  assessID={assessment?.id}
                  turnOnBGColors={!isForCandidate}
                  nameInitals={
                    isForCandidate
                      ? ""
                      : assessment.user?.first_name ||
                        assessment.user?.last_name
                      ? `${
                          assessment.user?.first_name?.charAt(0) +
                          assessment.user?.last_name?.charAt(0)
                        }`
                      : `${
                          assessment?.email
                            ? assessment?.email?.substring(0, 2)
                            : "U"
                        }`
                  }
                  size="custom"
                  width={isOnMobileScreen ? 40 : 60}
                  height={isOnMobileScreen ? 40 : 60}
                />
              )}
            </div>
          </div>
          {(assessment.status === "IN_PROGRESS" ||
            assessment.status === "FINISHED" ||
            (assessment.status === "EXPIRED" && isAttempted)) && (
            <>
              <div className="anti-cheat-card">
                <div
                  className="h-100 d-flex flex-row align-items-center"
                  style={{ gap: "12px" }}
                >
                  {AntiCheatIcon()}
                  <span className="subtitle-2 dark-100">
                    Anti-Cheat Monitor
                  </span>
                </div>
                <span
                  className="body-3 dark-100 pointer"
                  style={{ width: "", textDecorationLine: "underline" }}
                  onClick={() => {
                    setCommonValues({
                      ...commonValues,
                      detailedTestView: {
                        isExpanded: true,
                        testType: "anti_cheat_monitor",
                        data: null,
                      },
                    });
                  }}
                >
                  View Details
                </span>
              </div>
              <div className="graph-card-main-container">
                <div className="graph-card-left-container">
                  {assessment?.cum_score_obj ? (
                    <span className="w-100 text-start">
                      <span className="subtitle-2 dark-100">{`Job Fitness Score: ${
                        parseFloat(
                          (10 / 1) * assessment?.cum_score_obj?.score
                        ).toFixed(1) + `/`
                      }`}</span>
                      <span className="subtitle-2 dark-50">10</span>
                    </span>
                  ) : assessment.status === "IN_PROGRESS" &&
                    tests?.filter(
                      (t) =>
                        t[0]?.test_type === "personality_test" ||
                        t[0]?.test_type === "skill_test" ||
                        t[0]?.test_type === "situational_judgment_test" ||
                        t[0]?.test_type === "coding_test" ||
                        t[0]?.test_type === "value_test"
                    ) ? (
                    <span className="w-100 text-start">
                      <span className="subtitle-2 dark-100">
                        Job Fitness Score: --/
                      </span>
                      <span className="subtitle-2 dark-50">10</span>
                    </span>
                  ) : (
                    <span className="w-100 subtitle-2 dark-100 text-start">{`Job Fitness Score: N/A`}</span>
                  )}
                  <div className={`graph-card-left-inner-container`}>
                    <span className="body-1 medium black-700">
                      Personality Match
                    </span>
                    <div
                      className="d-flex align-items-start"
                      style={{ width: "100px" }}
                    >
                      {assessment?.personality_test_result ? (
                        <ProgressBar
                          varrient="progress-with-number-value"
                          value={
                            assessment?.personality_test_result?.score
                              ? parseFloat(
                                  (10 / 1) *
                                    assessment?.personality_test_result?.score
                                ).toFixed(1)
                              : 0
                          }
                        />
                      ) : assessment.status === "IN_PROGRESS" &&
                        tests?.filter(
                          (t) => t[0]?.test_type === "personality_test"
                        ) ? (
                        <span className="body-2 dark-100">--/10</span>
                      ) : (
                        <span className="body-2 dark-100">N/A</span>
                      )}
                    </div>
                  </div>
                  <div className={`graph-card-left-inner-container`}>
                    <span className="body-1 medium black-700">
                      Value Alignment
                    </span>
                    <div
                      className="d-flex align-items-start"
                      style={{ width: "100px" }}
                    >
                      {valueScore || valueScore === 0 ? (
                        <ProgressBar
                          varrient="progress-with-number-value"
                          value={valueScore ? valueScore.toFixed(1) : 0}
                        />
                      ) : assessment.status === "IN_PROGRESS" &&
                        tests?.filter(
                          (t) => t[0]?.test_type === "value_test"
                        ) ? (
                        <span className="body-2 dark-100">--/10</span>
                      ) : (
                        <span className="body-2 dark-100">N/A</span>
                      )}
                    </div>
                  </div>
                  <div className={`graph-card-left-inner-container`}>
                    <span className="body-1 medium black-700">
                      Skills and Ability
                    </span>
                    <div
                      className="d-flex align-items-start"
                      style={{ width: "100px" }}
                    >
                      {totalSkillScore || totalSkillScore === 0 ? (
                        <ProgressBar
                          varrient="progress-with-number-value"
                          value={
                            totalSkillScore ? totalSkillScore.toFixed(1) : 0
                          }
                        />
                      ) : assessment.status === "IN_PROGRESS" &&
                        tests?.filter(
                          (t) =>
                            t[0]?.test_type === "skill_test" ||
                            t[0]?.test_type === "situational_judgment_test" ||
                            t[0]?.test_type === "coding_test"
                        ) ? (
                        <span className="body-2 dark-100">--/10</span>
                      ) : (
                        <span className="body-2 dark-100">N/A</span>
                      )}
                    </div>
                  </div>
                </div>
                {isOnWebScreen || isOnTabletScreen ? (
                  <div
                    className="graph-card-right-container"
                    style={{
                      position: isOnMobileScreen ? "relative" : "sticky",
                      right: isOnMobileScreen ? "" : "50px",
                    }}
                  >
                    <span className="text-center" style={{ fontSize: "12px" }}>
                      Personality Match
                    </span>
                    <div
                      style={{
                        width: isOnMobileScreen ? "100%" : "100%",
                        height: isOnMobileScreen ? "100%" : "100%",
                        marginTop: "-6px",
                      }}
                    >
                      <MyResponsiveRadar data={data} />
                    </div>
                    <span
                      style={{
                        fontSize: "12px",
                        position: "absolute",
                        bottom: "-7px",
                        left: "-30px",
                      }}
                    >
                      Value Alignment
                    </span>
                    <span
                      style={{
                        fontSize: "12px",
                        position: "absolute",
                        bottom: "-7px",
                        right: "-20px",
                      }}
                    >
                      Skills and Ability
                    </span>
                  </div>
                ) : (
                  <div
                    className="graph-card-right-container"
                    style={{
                      position: isOnMobileScreen ? "relative" : "sticky",
                      right: isOnMobileScreen ? "" : "50px",
                    }}
                  >
                    <span className="text-center">Personality Match</span>
                    <div
                      style={{
                        width: isOnMobileScreen ? "100%" : "100%",
                        height: isOnMobileScreen ? "100%" : "100%",
                        marginTop: "-6px",
                      }}
                    >
                      <MyResponsiveRadar data={data} />
                    </div>
                    <span
                      style={{
                        position: "relative",
                        bottom: "50px",
                        left: "-50px",
                      }}
                    >
                      Value Alignment
                    </span>
                    <span
                      style={{
                        position: "relative",
                        bottom: "50px",
                        right: "-50px",
                      }}
                    >
                      Skills and Ability
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
          <AssessmentResults
            assessment={assessment}
            isForPublic={isForPublic}
            handleUpdateHiringStage={handleUpdateHiringStage}
          />
        </div>
      </div>
    </>
  );
}
