import React, { useMemo } from "react";
import Input from "./Input";
import ReactQuillBubble from "./ReactQuillBubble";

const Checkbox = (props) => {
  const {
    primary,
    primary_light,
    varrient,
    onClick,
    checked,
    defaultValue,
    optionValue,
    placeholder,
    readonly,
    onBlur,
    onChangeCheckboxOption,
    onChange,
    id,
    disabled,
    processing,
    value,
    style,
    className,
    title,
    titleStyle,
    description,
    descriptionStyle,
  } = props;

  if (primary) {
    return (
      <div className="primary-checkbox" onClick={onClick}>
        <div className="primary-checkbox-container">
          <input
            type="checkbox"
            readOnly={true}
            checked={checked}
            onChange={onChange}
            id={id}
            style={{ ...style }}
            disabled={disabled || processing}
            value={value}
            className={`${className} primary-checkbox-input pointer`}
          />
        </div>
        <div className="w-100 d-flex flex-column" style={{ gap: "4px" }}>
          <span className="ms-2 body-3" style={titleStyle}>
            {title}
          </span>
          {description && (
            <span className="ms-2 body-3" style={descriptionStyle}>
              {description}
            </span>
          )}
        </div>
      </div>
    );
  } else if (primary_light) {
    return (
      <div className="primary-light-checkbox" onClick={onClick}>
        <div className="primary-light-checkbox-container">
          <input
            type="checkbox"
            readOnly={true}
            checked={checked}
            onChange={onChange}
            id={id}
            style={{ ...style }}
            disabled={disabled || processing}
            value={value}
            className={`${className} primary-light-checkbox-input pointer`}
          />
        </div>
        <span
          className="primary-light-checkbox-title ms-2 description"
          style={titleStyle}
        >
          {title}
        </span>
      </div>
    );
  } else if (varrient === "checkbox-with-input") {
    const ChoiceTextMemo = useMemo(() => {
      return (
        <ReactQuillBubble
          value={value}
          className="ql-editor-skill-candidate-side option-read-only"
        />
      );
    }, []);

    return (
      <div
        className={`${
          checked && "selected-radio"
        } radio-with-input-container pointer ${
          readonly ? "readonly-radio-with-input-container" : ""
        } ${className}`}
        style={{ ...style, marginBottom: "16px" }}
        onClick={!disabled ? onChangeCheckboxOption : () => {}}
      >
        <label className="custom-radio">
          <div className="checkbox-with-input-container">
            <input
              type="checkbox"
              readOnly={true}
              id={id}
              checked={checked}
              value={value}
              // onChange={!disabled ? onChangeCheckboxOption : () => {}}
              disabled={disabled}
              style={{ ...style, marginRight: "12px" }}
              className={`${className} checkbox-with-input pointer`}
            />
          </div>
          {readonly ? (
            <div
              className="read-only-div h-100 d-flex align-items-center"
              style={{ textAlign: "left" }}
            >
              {optionValue && (
                <div style={{ marginRight: "4px", marginTop: "-3px" }}>
                  <span className="body-2 dark-100 disable-text-selection">
                    {optionValue}:
                  </span>
                </div>
              )}
              <div onClick={!disabled ? onChangeCheckboxOption : () => {}}>
                <span className="body-2 dark-100 disable-text-selection">
                  {ChoiceTextMemo}
                </span>
              </div>
            </div>
          ) : (
            <Input
              className={"radio-label-input"}
              varrient={"primary"}
              placeholder={placeholder}
              value={value}
              name={name}
              onChange={onChange}
              defaultValue={defaultValue}
              onBlur={onBlur}
            />
          )}
        </label>
      </div>
    );
  } else {
    return (
      <div className="primary-checkbox" onClick={onClick}>
        <div className="primary-checkbox-container">
          <input
            type="checkbox"
            readOnly={true}
            checked={checked}
            onChange={onChange}
            id={id}
            style={{ ...style }}
            disabled={disabled || processing}
            value={value}
            className={`${className} primary-checkbox-input pointer`}
          />
        </div>
        <div className="w-100 d-flex flex-column" style={{ gap: "4px" }}>
          <span className="ms-2 body-3" style={titleStyle}>
            {title}
          </span>
          {description && (
            <span className="ms-2 body-3" style={descriptionStyle}>
              {description}
            </span>
          )}
        </div>
      </div>
    );
  }
};

export default Checkbox;
