import React, { useContext, useEffect, useMemo, useState } from "react";
import { ReactComponent as TestFuseWatermarkIcon } from "../../assets/icons/candidate-assessment/Watermark.svg";
import { ReactComponent as NotQQIcon } from "../../assets/icons/candidate-assessment/not-qq.svg";
import OnTestStartCard from "../../components/candidate-assessment-flow/OnTestStartCard";
import QualificationTest from "./flow-steps/QualificationTest";
import TestContentWrapper from "./flow-steps/tests/TestContentWrapper";
import TestInstructionsAndInfoCard from "./flow-steps/TestInstructionsAndInfoCard";
import WelcomeVideo from "./flow-steps/WelcomeVideo";
import SkillBasedTest from "./flow-steps/tests/SkillBasedTest";
import SituationalJudgmentTestCS from "./flow-steps/tests/SituationalJudgmentTestCS";
import { ReactComponent as InfoIcon } from "../../assets/icons/Views/dashboard/test-types/test-info.svg";
import { ReactComponent as AngryIcon } from "../../assets/icons/candidate-assessment/1.svg";
import { ReactComponent as SadIcon } from "../../assets/icons/candidate-assessment/2.svg";
import { ReactComponent as MehIcon } from "../../assets/icons/candidate-assessment/3.svg";
import { ReactComponent as SmileIcon } from "../../assets/icons/candidate-assessment/4.svg";
import { ReactComponent as HappyIcon } from "../../assets/icons/candidate-assessment/5.svg";
import AssessmentInfoModalCard from "../../components/candidate-assessment-flow/AssessmentInfoModalCard";
import Button from "../../components/sub-component/Button";
import CustomModal from "../../components/common/Modal";
import OnTestCompleteCard from "../../components/candidate-assessment-flow/OnTestCompleteCard";
import CodingTestCS from "./flow-steps/tests/CodingTestCS";
import TextResponsesCS from "./flow-steps/tests/TextResponsesCS";
import UploadFileCS from "./flow-steps/tests/UploadFileCS";
import VideoResponsesCS from "./flow-steps/tests/VideoResposnses/VideoResponsesCS";
import VideoTestingView from "./flow-steps/tests/VideoResposnses/VideoTestingView";
import { useDispatch, useSelector } from "react-redux";
import {
  answerTestQuestion,
  createSession,
  createTest,
  getNextActiveTest,
  patchTestWithPrevResult,
  createTestQuestion,
  getAssessment,
  getTest,
  patchAssessment,
  resetAssessmentReducerState,
  sessionHeartBeat,
  takeOverSession,
} from "../../redux/thunks/Assessment";
import { Redirect, useParams } from "react-router-dom";
import { PathName } from "../../utils/routes";
// import CodingTest from './flow-steps/tests/CodingTest';
import { getResource, resetResourceState } from "../../redux/thunks/Resource";
import {
  AssessmentStatus,
  SectionType,
  TestType,
} from "./../../utils/Constants";
import { handleMenuVisibility } from "../../redux/slices/AuthSlice";
import utilities, {
  addVideoStreamToVideoTag,
  browserInfo,
  uuid,
} from "../../utils/utilities";
import ProcessingIndicator from "../../components/common/ProcessingIndicator";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import CaseStudyTestCS from "./flow-steps/tests/CaseStudyTestCS";
import { getTanentProfile } from "../../redux/thunks/UserProfile";
import { useMediaQuery } from "@mui/material";
import AssessmentProgressInfoHeader from "../../components/candidate-assessment-flow/AssessmentProgressInfoHeader";
import TabId from "is-tab-duplicated";
import { AntiCheatContext } from "../../utils/contexts/candidate-flow-contexts/AntiCheatContext";
import PersonalityTestCS from "./flow-steps/tests/PersonalityTestCS";
import TextArea from "../../components/sub-component/TextArea";
import posthog from "posthog-js";
import AssessmentConcluded from "./Assessment-concluded/AssessmentConcluded";
import AssessmentTestInfoWrapper from "../../components/candidate-assessment-flow/AssessmentTestInfoWrapper";

export default function CandidateAssessFlow() {
  const [openSkipQuestionModal, setOpenSkipQuestionModal] = useState(false);

  const { antiCheatStates, setAntiCheatStates } = useContext(AntiCheatContext);

  // const [hasTestStarted, setHasTestStarted] = useState(false);

  const [hasReadInstructions, setHasReadInstructions] = useState(null);
  const [hasWaitedForStartTest, setHasWaitedForStartTest] = useState(-1);
  const [afterTestCompletionScreens, setAfterTestCompletionScreens] = useState({
    showAssessmentProgressCard: false,
    showPauseAssessmentCard: false,
  });
  const [retakeTest, setRetakeTest] = useState(null);

  const [isCamTested, setIsCamTested] = useState(false);

  const params = useParams();

  const [isReviewSubmit, setIsReviewSubmit] = useState(false);

  const ratingData = [
    { icon: <AngryIcon />, value: 1 },
    { icon: <SadIcon />, value: 2 },
    { icon: <MehIcon />, value: 3 },
    { icon: <SmileIcon />, value: 4 },
    { icon: <HappyIcon />, value: 5 },
  ];

  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState("");
  const [assessmentSubmitted, setAssessmentSubmitted] = useState(false);

  useEffect(() => {
    localStorage.setItem("isReviewSubmit", JSON.stringify(isReviewSubmit));
  }, [isReviewSubmit]);

  const handleRatingClick = (selectedRating) => {
    setRating(selectedRating);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const dispatch = useDispatch();
  const assessment = useSelector((state) => state.assessmentReducer);
  const resource = useSelector((state) => state.resourceReducer);

  const history = useHistory();

  const handleSubmitAssessFeedback = () => {
    if (rating || comment) {
      posthog.capture("survey sent", {
        $survey_id: window._env_?.REACT_APP_POSTHOG_SURVEY_ID,
        $survey_response: rating,
        $survey_response_1: comment,
        $survey_response_2: params.id,
        $survey_response_3: assessment?.current_assessment?.spec_id,
      });
    }
    setRating(null);
    setComment("");
    setAssessmentSubmitted(true);
    setIsReviewSubmit(true);
  };

  const getNextTestQuestion = async () => {
    await dispatch(createTestQuestion({ assess_id: params.id }));
  };

  useEffect(() => {
    if (assessment.current_assessment === null) {
      return;
    }

    if (assessment.current_assessment.status === AssessmentStatus.FINISHED) {
      return;
    }

    if (
      assessment.current_assessment.active_test === null &&
      assessment.current_assessment?.qualifying_questions !== null &&
      !assessment.current_assessment?.attempted_qq
    ) {
      dispatch(createTest({ assess_id: params.id }));
      return;
    }

    if (assessment.current_assessment.active_test === null) {
      dispatch(getNextActiveTest({ assess_id: params.id }));
      return;
    }

    if (assessment.current_assessment.active_test?.section === null) {
      return;
    }

    // const { attempted_ques, total_ques } =
    // 	assessment.current_assessment.active_test.test_progress;

    if (
      !assessment.current_assessment?.active_test?.active_question &&
      assessment.current_assessment?.active_test?.test_progress
        ?.attempted_ques <
        assessment.current_assessment?.active_test?.test_progress?.total_ques
    ) {
      // if (assessment.current_assessment.active_test.section?.type === SectionType.SKILL_TEST && attempted_ques === 0) {
      // if (assessment.current_assessment?.active_test?.test_progress?.attempted_ques === 0) {
      // 	return;
      // }
      getNextTestQuestion();
    }
  }, [assessment.current_assessment]);

  const handleSubmitAnswer = async (data) => {
    const res = await dispatch(answerTestQuestion(data));

    if (res.payload === "No active question to respond!") {
      getNextTestQuestion();
    }
    if (res.payload === "No active test!") {
      dispatch(getNextActiveTest({ assess_id: params.id }));
    }

    if (
      assessment?.current_assessment?.active_test?.test_progress
        ?.attempted_ques +
        1 ===
      assessment?.current_assessment?.active_test?.test_progress?.total_ques
    ) {
      if (
        (assessment?.current_assessment?.active_test?.section?.type ===
          "skill_test" &&
          assessment?.current_assessment?.tests?.filter((_t, _) => {
            return _t.test_type === "skill_test" && _t.completed === false;
          })?.length > 1) ||
        (assessment?.current_assessment?.active_test?.section?.type ===
          "situational_judgment_test" &&
          assessment?.current_assessment?.tests?.filter((_t, _) => {
            return (
              _t.test_type === "situational_judgment_test" &&
              _t.completed === false
            );
          })?.length > 1) ||
        (assessment?.current_assessment?.active_test?.section?.type ===
          "coding_test" &&
          assessment?.current_assessment?.tests?.filter((_t, _) => {
            return _t.test_type === "coding_test" && _t.completed === false;
          }).length > 1)
      ) {
        setAfterTestCompletionScreens({
          showAssessmentProgressCard: false,
          showPauseAssessmentCard: false,
        });
        localStorage.setItem("showAssessmentProgressCard", false);
        localStorage.setItem("showPauseAssessmentCard", false);
      } else {
        setAfterTestCompletionScreens({
          showAssessmentProgressCard: true,
          showPauseAssessmentCard: false,
        });
        localStorage.setItem("showAssessmentProgressCard", true);
        localStorage.setItem("showPauseAssessmentCard", false);
      }
    }
  };

  const handleSubmitVideoRetry = async (data) => {
    const res = await dispatch(answerTestQuestion(data));
  };

  const handlePatchAssessment = (data) => {
    dispatch(patchAssessment({ id: params.id, data }));
  };

  useMemo(() => {
    if (assessment?.current_assessment?.tenant_id) {
      dispatch(
        getTanentProfile({
          id: assessment?.current_assessment?.tenant_id,
          isCallForCurrAssessment: true,
        })
      );
    }
  }, [assessment?.current_assessment?.tenant_id]);

  useMemo(() => {
    if (assessment?.current_assessment) {
      const { current_assessment } = assessment;
      const {
        welcome_video,
        qualifying_questions,
        active_test,
        tests,
        has_watched_ww,
        attempted_qq,
        sections,
      } = current_assessment;

      if (
        ((welcome_video ? has_watched_ww : true) && qualifying_questions
          ? attempted_qq
          : true) &&
        sections.filter((sec, _) => {
          return sec.completed === true;
        })?.length === 0 &&
        tests.filter((sec, _) => {
          return sec.completed === true;
        })?.length === 0 &&
        (active_test?.test?.title === tests[0]?.title ||
          !tests[0]?.title ||
          tests[0]?.prev_test_results ||
          tests[0]?.test_results) &&
        !active_test?.active_question &&
        !active_test?.test_progress
      ) {
        setHasReadInstructions(false);
      } else {
        setHasReadInstructions(true);
      }

      if (!active_test?.active_question && !active_test?.test_progress) {
        if (
          (active_test?.section?.type === "skill_test" &&
            tests?.filter((_t, _) => {
              return _t.test_type === "skill_test" && _t.completed === true;
            })?.length > 0) ||
          (active_test?.section?.type === "situational_judgment_test" &&
            tests?.filter((_t, _) => {
              return (
                _t.test_type === "situational_judgment_test" &&
                _t.completed === true
              );
            })?.length > 0) ||
          (active_test?.section?.type === "coding_test" &&
            tests?.filter((_t, _) => {
              return _t.test_type === "coding_test" && _t.completed === true;
            }).length > 0) ||
          ((active_test?.section?.type === "skill_test" ||
            active_test?.section?.type === "situational_judgment_test" ||
            active_test?.section?.type === "coding_test") &&
            tests[0]?.completed === false &&
            active_test?.test?.title !== tests[0]?.title)
        ) {
          setHasWaitedForStartTest(true);
        } else {
          setHasWaitedForStartTest(false);
        }
      }
    }
  }, [assessment?.current_assessment]);

  useEffect(() => {
    if (
      localStorage.getItem("showAssessmentProgressCard") !== undefined &&
      localStorage.getItem("showPauseAssessmentCard") !== undefined
    ) {
      setAfterTestCompletionScreens({
        showAssessmentProgressCard:
          localStorage.getItem("showAssessmentProgressCard") === "true"
            ? true
            : false,
        showPauseAssessmentCard:
          localStorage.getItem("showPauseAssessmentCard") === "true"
            ? true
            : false,
      });
    }
  }, []);

  const enterFullScreen = () => {
    const element = document.body;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    }
  };

  const onSkipQuestion = () => {
    setOpenSkipQuestionModal(false);
  };

  if (
    assessment.loading_current_assess ||
    assessment.creating_session_loading
  ) {
    return <ProcessingIndicator className="medium-indicator" />;
  }

  // if (
  // 	// assessment.current_assessment === null ||
  // 	assessment.current_assessment?.status === AssessmentStatus.FINISHED
  // ) {
  // 	history.push('/dashboard/assessments');
  // 	window.location.reload();
  // 	return <ProcessingIndicator className='medium-indicator' />;
  // }

  if (
    assessment.current_assessment?.status === AssessmentStatus.NOT_QUALIFIED
  ) {
    return (
      <div className="d-flex flex-column h-100 w-100 justify-content-center align-items-center">
        <AssessmentTestInfoWrapper
          title={<></>}
          primaryBtnTitle={"Go back to assessment(s)"}
          btnWidth={"max-content"}
          disableSecondaryBtn={true}
          btnSize={"lg"}
          onPrimaryBtnClick={() => {
            window.location.replace(PathName.ASSESSMENTS);
          }}
        >
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ gap: "24px" }}
          >
            <NotQQIcon />
            <span className="headline-4 dark-100">{`Unfortunately, You are not qualified for this assessment!`}</span>
            <span className="body-1 dark-50">{`You can review this assessment in the Not Qualified assessment(s) tab`}</span>
          </div>
        </AssessmentTestInfoWrapper>
      </div>
    );
  }

  return (
    <>
      <div className="candidate-assessment-flow-container">
        <div className="candidate-assment-flow-wrapper flex-column">
          {assessment.current_assessment?.status ===
          AssessmentStatus.FINISHED ? (
            <div>
              {!assessmentSubmitted ? (
                <div
                  className="w-100 d-flex align-items-center justify-content-center"
                  style={{ height: "80vh" }}
                >
                  <div className="candidate-assessment-feedack-modal">
                    <span className="headline-4 dark-100 text-center">
                      {" "}
                      Thank You!{" "}
                    </span>
                    <span className="body-2 dark-100 text-start">
                      Your assessment has been submitted successfully. Please
                      share your experience with us.
                    </span>
                    <div className="emoji-container">
                      {ratingData?.map((r, i) => {
                        return (
                          <span
                            key={i}
                            className={`emoji-icon ${
                              rating == r.value && "selected"
                            }`}
                            onClick={() => handleRatingClick(r?.value)}
                          >
                            {r?.icon}
                          </span>
                        );
                      })}
                    </div>
                    <div style={{ width: "100%", maxHeight: "130px" }}>
                      <TextArea
                        placeholder="Please Explain..."
                        name="message"
                        inputStyle={{ height: "105px", maxHeight: "115px" }}
                        value={comment}
                        onChange={handleCommentChange}
                      />
                    </div>
                    <div className="candidate-assessment-feedack-modal-text">
                      <span className="body-2 dark-100 text-start">
                        Some of the assessment results will be available to you
                        right away on your dashboard. Others might take several
                        minutes to show up.
                      </span>
                      <span className="body-2 dark-100 text-start">
                        Thank you for using Testfuse!
                      </span>
                    </div>
                    <div className="d-flex justify-content-center">
                      <Button
                        btn="ragular-btn"
                        varrient="primary"
                        title={"Next"}
                        style={{
                          width: "fit-content",
                          padding: "0.875em 2em 0.875em 2em",
                        }}
                        onClick={() => {
                          handleSubmitAssessFeedback();
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <AssessmentConcluded />
              )}
            </div>
          ) : (
            (() => {
              if (assessment.current_assessment?.active_test === null) {
                return <></>;
              }

              if (
                assessment.current_assessment?.qualifying_questions !== null &&
                !assessment.current_assessment?.attempted_qq
              ) {
                if (
                  assessment.current_assessment?.active_test?.test
                    ?.test_type === TestType.QUALIFYING_TEST
                ) {
                  if (
                    assessment.current_assessment?.active_test
                      ?.active_question !== null
                  ) {
                    return (
                      <QualificationTest
                        result=""
                        assessmentId={params.id}
                        questions={
                          assessment.current_assessment?.active_test
                            ?.active_question
                        }
                        onSubmit={(data) => {
                          handleSubmitAnswer(data);
                        }}
                        processing={assessment.processing_answer_question}
                      />
                    );
                  }

                  return (
                    <div
                      className="w-100 d-flex align-items-center justify-content-center"
                      style={{ height: "80vh" }}
                    >
                      <TestInstructionsAndInfoCard
                        varrient={"startQualificatioTest"}
                        processing={assessment.processing_current_question}
                        onSecondaryBtnClick={() => {
                          window.location.replace("/dashboard/assessments");
                        }}
                        onPrimaryBtnClick={() => {
                          dispatch(
                            createTestQuestion({ assess_id: params.id })
                          );
                          if (!document.fullscreenElement) {
                            enterFullScreen();
                          }
                        }}
                      />
                    </div>
                  );
                }
              }

              if (
                assessment.current_assessment?.welcome_video !== null &&
                !assessment.current_assessment?.has_watched_ww
              ) {
                const welcome_video =
                  assessment.current_assessment?.welcome_video;

                if (welcome_video?.type === "id") {
                  if (resource.loading) {
                    return (
                      <div className="processing-indicator-wrapper medium-indicator">
                        <div
                          className="processing-indicator primary"
                          style={{ width: "40px", height: "40px" }}
                        />
                      </div>
                    );
                  }

                  if (
                    resource.data === null ||
                    !resource?.data?.type.includes("video")
                  ) {
                    dispatch(getResource({ id: welcome_video.video_id }));
                    return;
                  }

                  return (
                    <WelcomeVideo
                      src={resource.data?.url}
                      processing={assessment.processing_patch_assess}
                      title={assessment?.current_assessment?.title}
                      onPrimaryButtonClick={() => {
                        dispatch(resetResourceState());
                        handlePatchAssessment({ has_watched_ww: true });
                      }}
                    />
                  );
                } else if (welcome_video.type === "url") {
                  return (
                    <WelcomeVideo
                      src={welcome_video?.video_url}
                      title={assessment?.current_assessment?.title}
                      youtubeURL
                      // processing={assessment.processing_patch_assess}
                      onPrimaryButtonClick={() => {
                        dispatch(resetResourceState());
                        handlePatchAssessment({ has_watched_ww: true });
                        if (!document.fullscreenElement) {
                          enterFullScreen();
                        }
                      }}
                    />
                  );
                }
              }

              if (
                assessment?.current_assessment &&
                hasReadInstructions === false
              ) {
                return (
                  <TestInstructionsAndInfoCard
                    varrient="assessmentInstructions"
                    onSecondaryBtnClick={() => {
                      window.location.replace("/dashboard/assessments");
                    }}
                    onPrimaryBtnClick={() => {
                      setHasReadInstructions(true);
                      if (!document.fullscreenElement) {
                        enterFullScreen();
                      }
                    }}
                  />
                );
              }

              if (afterTestCompletionScreens.showAssessmentProgressCard) {
                const completedSections =
                  assessment?.current_assessment?.sections?.filter((sec, _) => {
                    return sec.completed === true;
                  });
                return (
                  <div
                    className="w-100 d-flex align-items-center justify-content-center"
                    style={{ height: "80vh" }}
                  >
                    <OnTestCompleteCard
                      assessment={assessment}
                      testName={
                        completedSections[completedSections?.length - 1]
                          ?.intro_text
                      }
                      onSecondaryBtnClick={() => {
                        setAfterTestCompletionScreens({
                          showAssessmentProgressCard: false,
                          showPauseAssessmentCard: true,
                        });
                        localStorage.setItem(
                          "showAssessmentProgressCard",
                          false
                        );
                        localStorage.setItem("showPauseAssessmentCard", true);
                      }}
                      onPrimaryBtnClick={async () => {
                        // if (assessment.current_assessment?.active_test?.test?.test_type !== TestType.CASE_STUDY_TEST) {
                        // 	await dispatch(createTest({ assess_id: params.id }));
                        // 	getNextTestQuestion();
                        // }
                        setAfterTestCompletionScreens({
                          showAssessmentProgressCard: false,
                          showPauseAssessmentCard: false,
                        });
                        localStorage.setItem(
                          "showAssessmentProgressCard",
                          false
                        );
                        localStorage.setItem("showPauseAssessmentCard", false);
                      }}
                    />
                  </div>
                );
              }

              if (afterTestCompletionScreens.showPauseAssessmentCard) {
                return (
                  <div
                    className="w-100 d-flex align-items-center justify-content-center"
                    style={{ height: "80vh" }}
                  >
                    <TestInstructionsAndInfoCard
                      varrient={"pauseTest"}
                      testName={utilities.toTitleCase(
                        assessment.current_assessment?.active_test?.section
                          ?.type
                      )}
                      processing={assessment.processing_current_question}
                      onSecondaryBtnClick={() => {
                        window.location.replace("/dashboard/assessments");
                      }}
                      onPrimaryBtnClick={() => {
                        setAfterTestCompletionScreens({
                          showAssessmentProgressCard: false,
                          showPauseAssessmentCard: false,
                        });
                        localStorage.setItem(
                          "showAssessmentProgressCard",
                          false
                        );
                        localStorage.setItem("showPauseAssessmentCard", false);
                      }}
                    />
                  </div>
                );
              }

              if (
                hasWaitedForStartTest === false &&
                !afterTestCompletionScreens.showAssessmentProgressCard &&
                !afterTestCompletionScreens.showPauseAssessmentCard
              ) {
                return (
                  <div
                    className="w-100 d-flex align-items-center justify-content-center"
                    style={{ height: "80vh" }}
                  >
                    <OnTestStartCard
                      varrient={
                        assessment.current_assessment?.active_test?.section
                          ?.type
                      }
                      testStartsInDuration={5}
                      // testDuration={30}
                      onWaitComplete={async () => {
                        // if (
                        // 	assessment.current_assessment?.active_test?.section
                        // 		?.type !== TestType.SKILL_TEST &&
                        // 	assessment.current_assessment?.active_test
                        // 		?.active_question === null
                        // ) {
                        // 	getNextTestQuestion();
                        // }
                        setHasWaitedForStartTest(true);
                      }}
                    />
                  </div>
                );
              }

              if (
                (assessment.current_assessment?.active_test?.test
                  ?.has_video_response ||
                  assessment.current_assessment?.active_test?.section?.type ===
                    "video_test" ||
                  assessment.current_assessment?.active_test?.section?.type ===
                    "personality_test") &&
                sessionStorage.getItem("cam_and_mic_permission_for_ss") !==
                  "allowed" &&
                sessionStorage.getItem("cam_and_mic_permission_for_test") !==
                  "allowed"
              ) {
                return (
                  <VideoTestingView
                    isRequired={true}
                    content={[
                      "This assessment requires you to submit video responses. To do this, you will be asked to record a video answer using your webcam or built-in camera. Hence, it's important that we make sure your camera and microphone work perfectly.",
                      "Utilizing the video recorder on your right, you can quickly record	and watch a trial video. Make sure that you're facing towards the camera to ensure both audio and video are captured correctly. No need to fret; these practice videos won't be stored nor shown anywhere else!",
                    ]}
                    handleCamTested={(status) => {
                      sessionStorage.setItem(
                        "cam_and_mic_permission_for_test",
                        `allowed`
                      );
                      if (
                        assessment.current_assessment?.anti_cheat_settings
                          ?.snapshot_settings?.active &&
                        sessionStorage.getItem(
                          "cam_and_mic_permission_for_ss"
                        ) !== "blocked"
                      ) {
                        sessionStorage.setItem(
                          "cam_and_mic_permission_for_ss",
                          `${status}`
                        );
                        addVideoStreamToVideoTag("capture-user-snapshots");
                      }
                      if (
                        assessment.current_assessment?.anti_cheat_settings
                          ?.snapshot_settings?.required
                      ) {
                        sessionStorage.setItem(
                          "check_cam_and_mic_permission",
                          `job_started`
                        );
                      }
                      setAntiCheatStates({
                        ...antiCheatStates,
                        media_devices: { isEnabled: true, message: "" },
                      });
                      if (browserInfo("Firefox", null)) {
                        enterFullScreen();
                      }
                    }}
                  />
                );
              }

              if (
                !assessment.current_assessment?.active_test?.test_progress &&
                !assessment.current_assessment?.active_test?.active_question &&
                assessment.current_assessment?.active_test?.test?.test_type ===
                  TestType.CASE_STUDY_TEST
              ) {
                return (
                  <div
                    className="w-100 d-flex align-items-center justify-content-center"
                    style={{ height: "80vh" }}
                  >
                    <TestInstructionsAndInfoCard
                      varrient="caseStudyIntroText"
                      testName={utilities.toTitleCase(
                        assessment.current_assessment?.title
                      )}
                      onPrimaryBtnClick={async () => {
                        await dispatch(createTest({ assess_id: params.id }));
                        // getNextTestQuestion();
                        if (
                          assessment?.current_assessment?.active_test?.test
                            ?.has_video_response ||
                          assessment?.current_assessment?.active_test?.test
                            ?.test_type === TestType.PERSONALITY_TEST ||
                          assessment?.current_assessment?.active_test?.test
                            ?.test_type === TestType.VIDEO_TEST
                        ) {
                          sessionStorage.setItem(
                            "cam_and_mic_permission_for_test",
                            `allowed`
                          );
                        }
                      }}
                      introText={
                        assessment.current_assessment?.active_test?.test
                          ?.intro_text
                      }
                      processing={assessment.processing_current_question}
                    />
                  </div>
                );
              }

              if (
                !assessment.current_assessment?.active_test?.test_progress &&
                !assessment.current_assessment?.active_test?.active_question
              ) {
                return (
                  <div
                    className="w-100 d-flex align-items-center justify-content-center"
                    style={{ height: "80vh" }}
                  >
                    <TestInstructionsAndInfoCard
                      varrient="startTest"
                      activeSection={
                        assessment.current_assessment?.active_test?.section
                          ?.intro_text
                      }
                      test={
                        assessment.current_assessment?.active_test?.section
                          ?.type !== "video_test" &&
                        assessment.current_assessment?.active_test?.section
                          ?.type !== "case_study_test" &&
                        assessment.current_assessment?.active_test?.section
                          ?.type !== "upload_test" &&
                        assessment.current_assessment?.active_test?.section
                          ?.type !== "personality_test"
                          ? assessment.current_assessment?.active_test
                          : null
                      }
                      testName={utilities.toTitleCase(
                        assessment.current_assessment?.active_test?.section
                          ?.type === "skill_test" ||
                          assessment.current_assessment?.active_test?.section
                            ?.type === "situational_judgment_test" ||
                          assessment.current_assessment?.active_test?.section
                            ?.type === "coding_test"
                          ? assessment.current_assessment?.active_test?.test
                              ?.title
                          : assessment.current_assessment?.active_test?.section
                              ?.type
                      )}
                      testType={
                        assessment.current_assessment?.active_test?.section
                          ?.type
                      }
                      testResultPrev={
                        assessment.current_assessment?.test_result_prev
                      }
                      retakeTest={retakeTest}
                      setRetakeTest={setRetakeTest}
                      onPrimaryBtnClick={async () => {
                        if (retakeTest === false) {
                          await dispatch(
                            patchTestWithPrevResult({
                              assess_id: params.id,
                              data: {
                                test_result_id:
                                  assessment.current_assessment
                                    ?.test_result_prev?._id,
                                test_id:
                                  assessment.current_assessment?.active_test
                                    ?.test?.id,
                              },
                            })
                          );
                          setRetakeTest(null);
                        } else {
                          await dispatch(createTest({ assess_id: params.id }));
                          // getNextTestQuestion();
                          if (
                            assessment?.current_assessment?.active_test?.test
                              ?.has_video_response ||
                            assessment?.current_assessment?.active_test?.test
                              ?.test_type === TestType.PERSONALITY_TEST ||
                            assessment?.current_assessment?.active_test?.test
                              ?.test_type === TestType.VIDEO_TEST
                          ) {
                            sessionStorage.setItem(
                              "cam_and_mic_permission_for_test",
                              `allowed`
                            );
                          }
                        }
                      }}
                      processing={assessment.processing_current_question}
                      loading={assessment.processing_test_result_prev}
                    />
                  </div>
                );
              }

              if (
                assessment.current_assessment?.active_test?.section !== null
              ) {
                const active_test = assessment.current_assessment?.active_test;

                if (
                  active_test?.section?.type === SectionType.SKILL_TEST ||
                  active_test?.section?.type === SectionType.VALUE_TEST
                ) {
                  return (
                    <div className="candidate-assment-flow-test-container">
                      <AssessmentProgressInfoHeader />
                      <SkillBasedTest
                        title={
                          active_test?.section?.type ===
                            SectionType.VALUE_TEST && "Value Fitness Test"
                        }
                        test={active_test}
                        onQuestionSkip={() => {
                          setOpenSkipQuestionModal(true);
                        }}
                        totalQuestions={active_test?.test_progress?.total_ques}
                        currentQuestionNo={
                          active_test?.test_progress?.attempted_ques + 1
                        }
                        assessmentId={params.id}
                        onConfirm={handleSubmitAnswer}
                        processing={assessment.processing_answer_question}
                      />
                    </div>
                  );
                }

                if (
                  active_test?.section?.type ===
                  SectionType.SITUATIONAL_JUDGMENT_TEST
                ) {
                  return (
                    <div className="candidate-assment-flow-test-container">
                      <AssessmentProgressInfoHeader />
                      <SituationalJudgmentTestCS
                        test={active_test}
                        onQuestionSkip={() => {
                          setOpenSkipQuestionModal(true);
                        }}
                        totalQuestions={active_test?.test_progress?.total_ques}
                        currentQuestionNo={
                          active_test?.test_progress?.attempted_ques + 1
                        }
                        assessmentId={params.id}
                        onConfirm={handleSubmitAnswer}
                        processing={assessment.processing_answer_question}
                      />
                    </div>
                  );
                }

                if (active_test?.section?.type === SectionType.CODING_TEST) {
                  return (
                    <div className="candidate-assment-flow-test-container">
                      <AssessmentProgressInfoHeader />
                      <CodingTestCS
                        question={active_test?.active_question}
                        testId={active_test?.test?.id}
                        assessmentId={params.id}
                        testProgress={active_test?.test_progress}
                        onQuestionSkip={() => {
                          setOpenSkipQuestionModal(true);
                        }}
                        onQuestionSubmit={handleSubmitAnswer}
                        processing={assessment.processing_answer_question}
                      />
                    </div>
                  );
                }

                if (
                  active_test?.section?.type === SectionType.PERSONALITY_TEST
                ) {
                  return (
                    <div className="candidate-assment-flow-test-container">
                      <AssessmentProgressInfoHeader />
                      <PersonalityTestCS
                        question={active_test?.active_question}
                        totalQuestions={active_test?.test_progress?.total_ques}
                        currentQuestionNo={
                          active_test?.test_progress?.attempted_ques + 1
                        }
                        assessmentId={params.id}
                        onQuestionSkip={() => {
                          setOpenSkipQuestionModal(true);
                        }}
                        onQuestionSubmit={handleSubmitAnswer}
                        onVideoRetry={handleSubmitVideoRetry}
                        processing={assessment.processing_answer_question}
                        testProgress={active_test?.test_progress}
                      />
                    </div>
                  );
                }

                if (active_test?.section?.type === SectionType.UPLOAD_TEST) {
                  return (
                    <div className="candidate-assment-flow-test-container">
                      <AssessmentProgressInfoHeader />
                      <UploadFileCS
                        question={active_test?.active_question}
                        totalQuestions={active_test?.test_progress?.total_ques}
                        currentQuestionNo={
                          active_test?.test_progress?.attempted_ques + 1
                        }
                        assessmentId={params.id}
                        onQuestionSkip={() => {
                          setOpenSkipQuestionModal(true);
                        }}
                        onQuestionSubmit={handleSubmitAnswer}
                        processing={assessment.processing_answer_question}
                        is_mandatory={
                          active_test?.active_question?.is_mandatory
                        }
                      />
                    </div>
                  );
                }

                if (active_test?.section?.type === SectionType.TEXT_TEST) {
                  return (
                    <div className="candidate-assment-flow-test-container">
                      <AssessmentProgressInfoHeader />
                      <TextResponsesCS
                        question={active_test?.active_question}
                        totalQuestions={active_test?.test_progress?.total_ques}
                        currentQuestionNo={
                          active_test?.test_progress?.attempted_ques + 1
                        }
                        assessmentId={params.id}
                        onQuestionSkip={() => {
                          setOpenSkipQuestionModal(true);
                        }}
                        onQuestionSubmit={handleSubmitAnswer}
                        processing={assessment.processing_answer_question}
                        canUploadFile={active_test.test?.allow_file_upload}
                        is_mandatory={
                          active_test?.active_question?.is_mandatory
                        }
                      />
                    </div>
                  );
                }

                if (active_test?.section?.type === SectionType.VIDEO_TEST) {
                  return (
                    <div className="candidate-assment-flow-test-container">
                      <AssessmentProgressInfoHeader />
                      <VideoResponsesCS
                        question={active_test?.active_question}
                        totalQuestions={active_test?.test_progress?.total_ques}
                        currentQuestionNo={
                          active_test?.test_progress?.attempted_ques + 1
                        }
                        assessmentId={params.id}
                        onQuestionSkip={() => {
                          setOpenSkipQuestionModal(true);
                        }}
                        onQuestionSubmit={handleSubmitAnswer}
                        onVideoRetry={handleSubmitVideoRetry}
                        processing={assessment.processing_answer_question}
                        is_mandatory={
                          active_test?.active_question?.is_mandatory
                        }
                      />
                    </div>
                  );
                }

                if (
                  active_test?.section?.type === SectionType.CASE_STUDY_TEST
                ) {
                  const serveTime = new Date(active_test?.test.serve_time);
                  const duration = active_test?.test.duration * 1000;

                  const remainingTime =
                    (serveTime.getTime() + duration - Date.now()) / 1000;
                  const timeDuration = Number(remainingTime.toFixed(0));

                  return (
                    <div className="candidate-assment-flow-test-container">
                      <AssessmentProgressInfoHeader
                        showTimer
                        duration={timeDuration}
                        getUpdatedTime={(duration) => {}}
                      />
                      <CaseStudyTestCS
                        question={active_test?.active_question}
                        totalQuestions={active_test?.test_progress?.total_ques}
                        currentQuestionNo={
                          active_test?.test_progress?.attempted_ques + 1
                        }
                        test={active_test?.test}
                        assessmentId={params.id}
                        onQuestionSkip={() => {
                          setOpenSkipQuestionModal(true);
                        }}
                        onQuestionSubmit={handleSubmitAnswer}
                        onVideoRetry={handleSubmitVideoRetry}
                        processing={assessment.processing_answer_question}
                        testProgress={active_test?.test_progress}
                        is_mandatory={
                          active_test?.active_question?.is_mandatory
                        }
                      />
                    </div>
                  );
                }
              }
            })()
          )}
          <div className="watermark mt-3">
            <TestFuseWatermarkIcon style={{ height: "50px" }} />
          </div>
        </div>
      </div>
    </>
  );
}
