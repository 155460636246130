import React, { useState, useEffect } from "react";
import { ReactComponent as AssessmentUploadImageIcon } from "../../assets/icons/Views/dashboard/assessments/Entity avatar.svg";
import { ReactComponent as UnVistIcon } from "../../assets/icons/candidate-assessment/untick.svg";
import { ReactComponent as VistedIcon } from "../../assets/icons/candidate-assessment/SCheckCircle.svg";
import { ReactComponent as QualifyingQuestions } from "../../assets/icons/Views/candidate/dashboard/qq.svg";
import { ReactComponent as SkillTest } from "../../assets/icons/Views/candidate/dashboard/skill.svg";
import { ReactComponent as CodingTest } from "../../assets/icons/Views/candidate/dashboard/code.svg";
import { ReactComponent as ValueFitness } from "../../assets/icons/Views/candidate/dashboard/value.svg";
import { ReactComponent as SJIcon } from "../../assets/icons/Views/dashboard/assessments/situational-mentoring.svg";
import { ReactComponent as PersonalityTest } from "../../assets/icons/Views/candidate/dashboard/smile.svg";
import { ReactComponent as CaseStudyTest } from "../../assets/icons/Views/candidate/dashboard/case.svg";
import { ReactComponent as TextResponse } from "../../assets/icons/Views/candidate/dashboard/text.svg";
import { ReactComponent as VideoResponse } from "../../assets/icons/Views/candidate/dashboard/video.svg";
import { ReactComponent as FileUpload } from "../../assets/icons/Views/candidate/dashboard/upload.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/Views/dashboard/test-detail/x.svg";
import { calculateExpiry, secondsToDhmsFormat } from "../../utils/utilities";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GetAvtarImage } from "../dashboard/AvtarBadge";
import ProgressBar from "../common/ProgressBar";
import { useSelector } from "react-redux";

export default function AssessmentInfoModalCard(props) {
  const testIcons = {
    skill_test: <SkillTest />,
    coding_test: <CodingTest />,
    value_test: <ValueFitness />,
    situational_judgment_test: (
      <SJIcon style={{ width: "20px", height: "20px", stroke: "#808191" }} />
    ),
    personality_test: <PersonalityTest />,
    case_study_test: <CaseStudyTest />,
    text_test: <TextResponse />,
    video_test: <VideoResponse />,
    upload_test: <FileUpload />,
  };

  const isOnMobileScreen = useMediaQuery("(max-width: 767px)");
  const { image, assessment, showWarningSign } = props;
  const { curr_assess_tenant_profile } = useSelector(
    (state) => state.userProfileReducer
  );

  return (
    <div className="test-assessment-info-container hide-scrollbar">
      <div
        className="w-100 d-flex flex-column align-items-start"
        style={{ gap: "24px" }}
      >
        {/* {assessment?.current_assessment?.avatar_id ?
					<GetAvtarImage
						turnOnBGColors={false}
						src={{ image_id: assessment?.current_assessment?.avatar_id, name: curr_assess_tenant_profile?.company_name }}
						size='custom' width={40} height={40} />
					:
					<AssessmentUploadImageIcon style={{ height: '40px', width: '40px' }} />
				} */}
        <div className="w-100 d-flex flex-column align-items-start">
          <div
            className="w-100 d-flex flex-row align-items-center justify-content-between"
            style={{ gap: "24px" }}
          >
            <span className="headline-3 dark-100 text-start">
              {assessment.current_assessment.title}
            </span>
            {isOnMobileScreen && (
              <div
                className="d-flex justify-content-end pointer"
                onClick={props.handleClose}
              >
                <CrossIcon style={{ width: "34px", height: "34px" }} />
              </div>
            )}
          </div>
          <span className="subtitle-2 dark-100 text-start">
            {curr_assess_tenant_profile?.company_name}
          </span>
        </div>
        {assessment?.current_assessment?.deadline ? (
          <span
            className={`body-3 ${
              props.showWarningSign ? "danger-text" : "black-700"
            }`}
          >
            <span
              className={`${
                props.showWarningSign ? "danger-text" : "black-400"
              }`}
            >
              Deadline:{" "}
            </span>
            {calculateExpiry(
              assessment?.current_assessment?.deadline,
              assessment?.current_assessment?.created_at
            )}
          </span>
        ) : (
          <span className="body-3 black-700">
            <span className="black-400">Deadline: </span>None
          </span>
        )}
      </div>
      <div className="test-assessment-progress-container">
        <ProgressBar
          varrient="custom"
          value={parseInt(
            ((assessment.current_assessment.tests.filter((test, _) => {
              return test.completed === true;
            }).length +
              (assessment?.current_assessment?.qualifying_questions &&
              assessment?.current_assessment?.attempted_qq
                ? 1
                : 0)) /
              (assessment.current_assessment.tests.length +
                (assessment?.current_assessment?.qualifying_questions
                  ? 1
                  : 0))) *
              100
          )}
          title={{
            className: "subtitle-2 dark-100",
          }}
          valueText={{
            className: "body-2 dark-100",
          }}
          progressBar={{
            style: { height: "8px", marginTop: "4px" },
          }}
        />
        <div className="progress-test-card-container">
          {assessment?.current_assessment?.qualifying_questions && (
            <div className="progress-test-card">
              <div className="progress-test-card-inner">
                <div
                  className="d-flex flex-row align-items-center"
                  style={{ gap: "12px" }}
                >
                  <QualifyingQuestions />
                  <span className={`subtitle-2 dark-100`}>
                    {"Qualifying Questions"}
                  </span>
                </div>
                <div
                  className="candidate-status-container body-2"
                  style={{
                    width: "110px",
                    marginLeft: "24px",
                    border:
                      assessment?.current_assessment?.status ===
                        "NOT_QUALIFIED" ||
                      !assessment?.current_assessment?.attempted_qq
                        ? "1px solid black"
                        : "",
                    background:
                      assessment?.current_assessment?.status !==
                        "NOT_QUALIFIED" &&
                      assessment?.current_assessment?.attempted_qq
                        ? "#7C21FF"
                        : "#FFFFFF",
                    color:
                      assessment?.current_assessment?.status !==
                        "NOT_QUALIFIED" &&
                      assessment?.current_assessment?.attempted_qq
                        ? "#FFF"
                        : "#121216",
                  }}
                >
                  {assessment?.current_assessment?.status === "NOT_QUALIFIED"
                    ? "Failed"
                    : assessment?.current_assessment?.attempted_qq
                    ? "Completed"
                    : "Pending"}
                </div>
              </div>
            </div>
          )}
          {assessment?.current_assessment?.sections.map((_section, _index) => {
            const filteredTests = assessment?.current_assessment?.tests.filter(
              (t) => t.test_type === _section.type
            );
            const totalDuration = filteredTests.reduce(
              (acc, t) => acc + t.duration,
              0
            );
            return (
              <div key={_index} className="progress-test-card">
                <div
                  className="w-100 d-flex flex-column align-items-start"
                  style={{ gap: "12px" }}
                >
                  <div className="progress-test-card-inner">
                    <div
                      className="d-flex flex-row align-items-center"
                      style={{ gap: "12px" }}
                    >
                      {testIcons[_section.type]}
                      <div className="d-flex flex-column align-items-start justify-content-center">
                        <span className={`subtitle-2 dark-100`}>
                          {_section?.intro_text}
                        </span>
                        <span className="body-2 dark-50">
                          {totalDuration
                            ? `~ ${secondsToDhmsFormat(totalDuration)}`
                            : ""}
                        </span>
                      </div>
                    </div>
                    {assessment.current_assessment?.status !==
                      "NOT_QUALIFIED" && (
                      <div
                        className="candidate-status-container body-2"
                        style={{
                          width: "110px",
                          marginLeft: "24px",
                          border: !_section?.completed ? "1px solid black" : "",
                          background: _section?.completed
                            ? "#7C21FF"
                            : "#FFFFFF",
                          color: _section?.completed ? "#FFF" : "#121216",
                        }}
                      >
                        {_section?.completed
                          ? "Completed"
                          : assessment.current_assessment?.status === "EXPIRED"
                          ? "EXPIRED"
                          : "Pending"}
                      </div>
                    )}
                  </div>
                  <ol
                    className="w-100 d-flex flex-column align-items-start"
                    style={{ paddingLeft: "28px", gap: "4px" }}
                  >
                    {assessment.current_assessment?.tests
                      ?.filter((_t, _) => {
                        return _t?.test_type === _section?.type;
                      })
                      ?.map((_subSections, _i) => {
                        return (
                          <>
                            {_subSections?.title && (
                              <li key={_i} className={`body-2 dark-50`}>
                                {_subSections?.title || "Unknown"}
                              </li>
                            )}
                          </>
                        );
                      })}
                  </ol>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
