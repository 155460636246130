import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ProcessingIndicator from "../../../components/common/ProcessingIndicator";
import AssessmentPersonalityResultCard from "../../../components/dashboard/assessments/AssessmentPersonalityResultCard";
import AssessmentResultCard from "../../../components/dashboard/assessments/AssessmentResultCard";
import { TestType, UserRole } from "../../../utils/Constants";
import { calculateScore } from "../../../utils/utilities";
import Button from "../../../components/sub-component/Button";
import { CommonContext } from "../../../utils/contexts/CommonContext";
import Select from "../../../components/sub-component/Select";
import { useMediaQuery } from "@mui/material";
import bg from "../../../assets/images/dashboard/Group 13806.svg";
import { ReactComponent as BackgroundIcon } from "../../../assets/images/dashboard/Group 13806.svg";

export default function AssessmentResults(props) {
  const { isForPublic } = props;
  const isOnWebScreen = useMediaQuery("(min-width:1280px)");
  const isAttempted = props?.assessment.tests.some((test) => test.completed);
  const auth = useSelector((state) => state.authReducer);

  const [tests, setTests] = useState([]);
  const [expandedTests, setExpandedTests] = useState(
    auth.data?.role && auth.data.role.name === UserRole.CANDIDATE ? [] : "all"
  );
  const [expandedSkillTests, setExpandedSkillTests] = useState(
    auth.data?.role && auth.data.role.name === UserRole.CANDIDATE ? [] : "all"
  );
  const [expandedValueTests, setExpandedValueTests] = useState(
    auth.data?.role && auth.data.role.name === UserRole.CANDIDATE ? [] : "all"
  );
  const [expandedPersonalityTests, setExpandedPersonalityTests] = useState(
    auth.data?.role && auth.data.role.name === UserRole.CANDIDATE ? [] : "all"
  );
  const [assessment, setAssessment] = useState(props.assessment);
  const [loading, setLoading] = useState(props.loading);

  useEffect(() => {
    setAssessment(props?.assessment);
    setLoading(props?.loading);
  }, [props]);

  useEffect(() => {
    if (tests) {
      setExpandedTests(
        tests
          .filter(
            (test) =>
              test[0].test_type === "case_study_test" ||
              test[0].test_type === "text_test" ||
              test[0].test_type === "video_test" ||
              test[0].test_type === "upload_test"
          )
          .map((t, i) => t[0].completed && i)
      );
      setExpandedSkillTests(
        tests
          .filter(
            (test) =>
              test[0].test_type === "skill_test" ||
              test[0].test_type === "situational_judgment_test" ||
              test[0].test_type === "coding_test"
          )
          .map((t, i) => (t[0].completed || t[0].prev_test_results) && i)
      );
      setExpandedValueTests(
        tests
          .filter((test) => test[0].test_type === "value_test")
          .map((t, i) => t[0].completed && i)
      );
      setExpandedPersonalityTests(
        tests
          .filter((test) => test[0].test_type === "personality_test")
          .map((t, i) => t[0].completed && i)
      );
    }
  }, [tests]);

  useMemo(() => {
    if (assessment) {
      const _tests = [];
      Object.values(TestType).map((testType, _) => {
        const filteredTests = assessment?.tests.filter((_test) => {
          return _test.test_type === testType;
        });
        if (filteredTests?.length > 0) {
          _tests.push(filteredTests);
        }
      });
      setTests(
        assessment.status === "IN_PROGRESS"
          ? [
              ..._tests.sort((test) => {
                return test[0].completed ? -1 : 1;
              }),
            ]
          : [..._tests]
      );
    }
  }, [assessment]);

  let skillScore = 0;
  let sjScore = 0;
  let codingScore = null;
  const _tests =
    tests &&
    tests?.filter(
      (t) =>
        (t[0]?.test_type === "skill_test" ||
          t[0]?.test_type === "situational_judgment_test" ||
          t[0]?.test_type === "coding_test") &&
        (t[0]?.completed || t[0]?.prev_test_results)
    );
  _tests
    ?.filter((t) => t[0]?.test_type === "skill_test")
    ?.map((test, _) => {
      skillScore += parseFloat(calculateScore(test));
    });
  _tests
    ?.filter((t) => t[0]?.test_type === "situational_judgment_test")
    ?.map((test, _) => {
      sjScore += parseFloat(calculateScore(test));
    });
  _tests
    ?.filter((t) => t[0]?.test_type === "coding_test")
    ?.map((test, _) => {
      codingScore += parseFloat(calculateScore(test));
    });
  const totalSkillScore = (skillScore + sjScore + codingScore) / _tests.length;

  let valueScore = null;
  tests &&
    tests
      ?.find((t) => t[0]?.test_type === "value_test" && t[0]?.completed)
      ?.forEach((test, _) => {
        valueScore += parseFloat(calculateScore(test.test_results));
      });

  const onExpandTest = (testIndex) => {
    const _expandedTests = expandedTests === "all" ? [] : expandedTests;
    if (expandedTests === "all") {
      tests?.forEach((_, _index) => {
        _expandedTests.push(_index);
      });
    }
    if (
      _expandedTests.filter((_testIndex, _) => {
        return _testIndex === testIndex;
      }).length > 0
    ) {
      setExpandedTests([
        ..._expandedTests.filter((_testIndex, _) => {
          return _testIndex !== testIndex;
        }),
      ]);
    } else {
      setExpandedTests([..._expandedTests, testIndex]);
    }
  };

  const onExpandSkillTest = (testIndex) => {
    const _expandedSkillTests =
      expandedSkillTests === "all" ? [] : expandedSkillTests;
    if (expandedSkillTests === "all") {
      tests?.forEach((_, _index) => {
        _expandedSkillTests.push(_index);
      });
    }
    if (
      _expandedSkillTests.filter((_testIndex, _) => {
        return _testIndex === testIndex;
      }).length > 0
    ) {
      setExpandedSkillTests([
        ..._expandedSkillTests.filter((_testIndex, _) => {
          return _testIndex !== testIndex;
        }),
      ]);
    } else {
      setExpandedSkillTests([..._expandedSkillTests, testIndex]);
    }
  };

  const onExpandValueTest = (testIndex) => {
    const _expandedValueTests =
      expandedValueTests === "all" ? [] : expandedValueTests;
    if (expandedValueTests === "all") {
      tests?.forEach((_, _index) => {
        _expandedValueTests.push(_index);
      });
    }
    if (
      _expandedValueTests.filter((_testIndex, _) => {
        return _testIndex === testIndex;
      }).length > 0
    ) {
      setExpandedValueTests([
        ..._expandedValueTests.filter((_testIndex, _) => {
          return _testIndex !== testIndex;
        }),
      ]);
    } else {
      setExpandedValueTests([..._expandedValueTests, testIndex]);
    }
  };

  const onExpandPersonalityTest = (testIndex) => {
    const _expandedPersonalityTests =
      expandedPersonalityTests === "all" ? [] : expandedPersonalityTests;
    if (expandedPersonalityTests === "all") {
      tests?.forEach((_, _index) => {
        _expandedPersonalityTests.push(_index);
      });
    }
    if (
      _expandedPersonalityTests.filter((_testIndex, _) => {
        return _testIndex === testIndex;
      }).length > 0
    ) {
      setExpandedPersonalityTests([
        ..._expandedPersonalityTests.filter((_testIndex, _) => {
          return _testIndex !== testIndex;
        }),
      ]);
    } else {
      setExpandedPersonalityTests([..._expandedPersonalityTests, testIndex]);
    }
  };

  const { commonValues, setCommonValues } = useContext(CommonContext);

  return (
    <div className="d-flex flex-column h-100 w-100 align-items-start">
      <div className="assessment-result-container">
        {assessment.status === "PENDING" ? (
          // <div className='w-100 h-100 flex-column d-flex align-items-center justify-content-center' style={{ position: 'relative' }}>
          // 	<BackgroundIcon width='436px' height='363px' className='no-result-section  ' />
          // 	<span className='headline-medium semibold black-700 overlap-div no-result-text'>This candidate has not started their assessment yet. Therefore there are no test results to show!</span>
          // </div>
          <span className="body-2 dark-100 no-result-text">
            This candidate has not started their assessment yet. Therefore there
            are no test results to show!
          </span>
        ) : assessment.status === "DECLINED" ? (
          // <div className='w-100 h-100 d-flex align-items-center justify-content-center' style={{ position: 'relative' }}>
          // 	<BackgroundIcon width='436px' height='363px' className='no-result-section' />
          // 	<span className='headline-medium semibold black-700 overlap-div no-result-text'>This candidate has declined their assessment. Therefore there are no test results to show!</span>
          // </div>
          <span className="body-2 dark-100 no-result-text">
            This candidate has declined their assessment. Therefore there are no
            test results to show!
          </span>
        ) : assessment.status === "NOT_QUALIFIED" ? (
          // <div className='w-100 h-100 d-flex align-items-center justify-content-center' style={{ position: 'relative' }}>
          // 	<BackgroundIcon width='436px' height='363px' className='no-result-section' />
          // 	<span className='headline-medium semibold black-700 overlap-div no-result-text'>This candidate didn’t qualify for this assessment. Therefore there are no test results to show!</span>
          // </div>
          <span className="body-2 dark-100 no-result-text">
            This candidate didn’t qualify for this assessment. Therefore there
            are no test results to show!
          </span>
        ) : assessment.status === "EXPIRED" && !isAttempted ? (
          // <div className='w-100 h-100 d-flex align-items-center justify-content-center' style={{ position: 'relative' }}>
          // 	<BackgroundIcon width='436px' height='363px' className='no-result-section' />
          // 	<span className='headline-medium semibold black-700 overlap-div no-result-text'>This assessment is expired. Therefore there are no test results to display!</span>
          // </div>
          <span className="body-2 dark-100 no-result-text">
            This assessment is expired. Therefore there are no test results to
            display!
          </span>
        ) : (
          <>
            {loading || assessment === null ? (
              <ProcessingIndicator className="medium-indicator" />
            ) : (
              <div
                className="w-100 h-100 d-flex flex-column"
                style={{ gap: "32px" }}
              >
                {tests?.some((t) => t[0]?.test_type === "personality_test") && (
                  <div className="assessment-result-card-main-container">
                    <div
                      className="w-100 d-flex flex-row justify-content-between"
                      style={{ gap: "8px" }}
                    >
                      <span className={`subtitle-2 dark-100`}>
                        Personality Compatibility Score
                      </span>
                      {assessment?.personality_test_result ? (
                        <span className={`subtitle-2 primary-text`}>
                          {`${parseFloat(
                            (10 / 1) *
                              assessment?.personality_test_result?.score
                          ).toFixed(1)}`}
                          <span className="dark-50">/10</span>
                        </span>
                      ) : assessment.status === "IN_PROGRESS" ? (
                        <span className={`subtitle-2 primary-text`}>
                          --<span className="dark-50">/10</span>
                        </span>
                      ) : (
                        <span className={`subtitle-2 primary-text`}>N/A</span>
                      )}
                    </div>
                    {tests &&
                      tests
                        ?.filter((t) => t[0]?.test_type === "personality_test")
                        ?.map((test, index) => {
                          return (
                            <div key={index}>
                              <AssessmentResultCard
                                assessment={assessment}
                                isForPublic={isForPublic}
                                test={test}
                                onExpandTest={() =>
                                  assessment.status === "FINISHED" &&
                                  assessment?.personality_test_result
                                    ? onExpandPersonalityTest(index)
                                    : {}
                                }
                                isExpand={
                                  assessment.status === "FINISHED" &&
                                  assessment?.personality_test_result
                                    ? expandedPersonalityTests === "all"
                                      ? true
                                      : expandedPersonalityTests.filter(
                                          (_testIndex, _) => {
                                            return _testIndex === index;
                                          }
                                        ).length > 0
                                    : false
                                }
                                responses={
                                  assessment?.responses?.filter((response) => {
                                    return (
                                      response?.test_type === test[0].test_type
                                    );
                                  }) || []
                                }
                              />
                            </div>
                          );
                        })}
                  </div>
                )}
                {tests?.some(
                  (t) =>
                    t[0]?.test_type === "coding_test" ||
                    t[0]?.test_type === "skill_test" ||
                    t[0]?.test_type === "situational_judgment_test"
                ) && (
                  <div className="assessment-result-card-main-container">
                    <div
                      className="w-100 d-flex flex-row justify-content-between"
                      style={{ gap: "8px" }}
                    >
                      <span className={`subtitle-2 dark-100`}>
                        Total Skills and Ability Score
                      </span>
                      {totalSkillScore || totalSkillScore === 0 ? (
                        <span className={`subtitle-2 primary-text`}>
                          {`${totalSkillScore.toFixed(1)}`}
                          <span className="dark-50">/10</span>
                        </span>
                      ) : assessment.status === "IN_PROGRESS" ? (
                        <span className={`subtitle-2 primary-text`}>
                          --<span className="dark-50">/10</span>
                        </span>
                      ) : (
                        <span className={`subtitle-2 primary-text`}>N/A</span>
                      )}
                    </div>
                    {tests &&
                      tests
                        ?.filter(
                          (t) =>
                            t[0]?.test_type === "coding_test" ||
                            t[0]?.test_type === "skill_test" ||
                            t[0]?.test_type === "situational_judgment_test"
                        )
                        ?.map((test, index) => {
                          return (
                            <div key={index}>
                              <AssessmentResultCard
                                assessment={assessment}
                                isForPublic={isForPublic}
                                test={test}
                                onExpandTest={() => {
                                  onExpandSkillTest(index);
                                }}
                                isExpand={
                                  expandedSkillTests === "all"
                                    ? true
                                    : expandedSkillTests.filter(
                                        (_testIndex, _) => {
                                          return _testIndex === index;
                                        }
                                      ).length > 0
                                }
                                responses={
                                  assessment?.responses?.filter((response) => {
                                    return (
                                      response?.test_type === test[0].test_type
                                    );
                                  }) || []
                                }
                                codingScore={codingScore}
                              />
                            </div>
                          );
                        })}
                  </div>
                )}
                {tests?.some((t) => t[0]?.test_type === "value_test") && (
                  <div className="assessment-result-card-main-container">
                    <div
                      className="w-100 d-flex flex-row justify-content-between"
                      style={{ gap: "8px" }}
                    >
                      <span className={`subtitle-2 dark-100`}>
                        Total Value Alignment Score
                      </span>
                      {valueScore || valueScore === 0 ? (
                        <span className={`subtitle-2 primary-text`}>
                          {`${valueScore.toFixed(1)}`}
                          <span className="dark-50">/10</span>
                        </span>
                      ) : assessment.status === "IN_PROGRESS" ? (
                        <span className={`subtitle-2 primary-text`}>
                          --<span className="dark-50">/10</span>
                        </span>
                      ) : (
                        <span className={`subtitle-2 primary-text`}>N/A</span>
                      )}
                    </div>
                    {tests &&
                      tests
                        ?.filter((t) => t[0]?.test_type === "value_test")
                        ?.map((test, index) => {
                          return (
                            <div key={index}>
                              <AssessmentResultCard
                                assessment={assessment}
                                isForPublic={isForPublic}
                                test={test}
                                onExpandTest={() => {
                                  onExpandValueTest(index);
                                }}
                                isExpand={
                                  expandedValueTests === "all"
                                    ? true
                                    : expandedValueTests.filter(
                                        (_testIndex, _) => {
                                          return _testIndex === index;
                                        }
                                      ).length > 0
                                }
                                responses={
                                  assessment?.responses?.filter((response) => {
                                    return (
                                      response?.test_type === test[0].test_type
                                    );
                                  }) || []
                                }
                              />
                            </div>
                          );
                        })}
                  </div>
                )}
                {tests?.some(
                  (t) =>
                    t[0]?.test_type === "case_study_test" ||
                    t[0]?.test_type === "text_test" ||
                    t[0]?.test_type === "video_test" ||
                    t[0]?.test_type === "upload_test"
                ) && (
                  <div className="assessment-result-card-main-container">
                    {tests &&
                      tests
                        ?.filter(
                          (t) =>
                            t[0]?.test_type === "case_study_test" ||
                            t[0]?.test_type === "text_test" ||
                            t[0]?.test_type === "video_test" ||
                            t[0]?.test_type === "upload_test"
                        )
                        ?.map((test, index) => {
                          return (
                            <div key={index}>
                              <AssessmentResultCard
                                assessment={assessment}
                                isForPublic={isForPublic}
                                test={test}
                                onExpandTest={() => {
                                  onExpandTest(index);
                                }}
                                isExpand={
                                  expandedTests === "all"
                                    ? true
                                    : expandedTests.filter((_testIndex, _) => {
                                        return _testIndex === index;
                                      }).length > 0
                                }
                                responses={
                                  assessment?.responses?.filter((response) => {
                                    return (
                                      response?.test_type === test[0].test_type
                                    );
                                  }) || []
                                }
                              />
                            </div>
                          );
                        })}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
