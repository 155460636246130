import React, { useState, useEffect, useContext } from "react";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPublicResult } from "../../redux/thunks/PublicResult";
import { CommonContext } from "../../utils/contexts/CommonContext";
import { GetAvtarImage } from "../../components/dashboard/AvtarBadge";
import PublicTestDetailedView from "./test-details/PublicTestDetailedView";
import TestDetailsRightView from "../dashboard/test-details/TestDetailsRightView";
import { ReactComponent as MicroWatermarkIcon } from "../../assets/icons/Views/dashboard/test-detail/microsoft.svg";
import { ReactComponent as TestFuseWatermarkIcon } from "../../assets/icons/Views/dashboard/test-detail/Watermark.svg";

export default function PublicAssessmentResult() {
  const params = useParams();
  const dispatch = useDispatch();
  const isOnWebScreen = useMediaQuery("(min-width:1200px)");
  const isOnMobileScreen = useMediaQuery("(max-width: 767px)");

  const publicResult = useSelector((state) => state.publicResultReducer);
  const resultData =
    publicResult?.public_result_data && publicResult?.public_result_data;
  const { commonValues, setCommonValues } = useContext(CommonContext);

  useEffect(() => {
    dispatch(getPublicResult({ id: params?.id }));
  }, [params?.id]);

  return (
    <div className="public-result-main-container">
      {resultData?.shared_publicly ? (
        <div
          className={`public-result-container ${
            commonValues.detailedTestView.isExpanded
              ? "public-result-container-expended"
              : "public-result-container-not-expended"
          }`}
        >
          <div className="public-result-header">
            <div className="w-100 d-flex flex-row align-items-center justify-content-between">
              <div
                className="d-flex flex-column align-items-start"
                style={{ width: "calc(100% - 100px)", gap: "16px" }}
              >
                <TestFuseWatermarkIcon />
                <span className="headline-3 dark-100 text-start assessment-name-truncate">
                  {resultData?.title}
                </span>
              </div>
              <GetAvtarImage
                className="assessment-upload-icon pointer"
                src={{
                  ...resultData?.tenant,
                  image_id: resultData?.tenant?.image_id,
                  name: resultData?.tenant.company_name,
                }}
                isForPublic={true}
                assessID={resultData?.id}
                nameInitals={`${
                  resultData?.tenant.company_name
                    ? resultData?.tenant.company_name?.substring(0, 2)
                    : "U"
                }`}
                size="custom"
                width={isOnMobileScreen ? 40 : 60}
                height={isOnMobileScreen ? 40 : 60}
              />
            </div>
          </div>
          <div className="public-result-bottom-container">
            {isOnWebScreen ? (
              <>
                <div
                  className={`public-result-view-container hide-scrollbar ${
                    commonValues.detailedTestView.isExpanded &&
                    "public-result-view-expanded"
                  }`}
                >
                  <TestDetailsRightView
                    assessment={resultData}
                    isForPublic={true}
                  />
                </div>
                {commonValues.detailedTestView.isExpanded && (
                  <PublicTestDetailedView
                    assessment={resultData}
                    isForPublic={true}
                  />
                )}
              </>
            ) : (
              <>
                {commonValues.detailedTestView.isExpanded ? (
                  <PublicTestDetailedView
                    assessment={resultData}
                    isForPublic={true}
                  />
                ) : (
                  <div
                    className={`public-result-view-container hide-scrollbar ${
                      commonValues.detailedTestView.isExpanded &&
                      "public-result-view-expanded"
                    }`}
                  >
                    <TestDetailsRightView
                      assessment={resultData}
                      isForPublic={true}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ) : publicResult?.loading ? (
        <div
          className="w-100 d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <div
            className="processing-indicator primary"
            style={{ width: "40px", height: "40px" }}
          />
        </div>
      ) : (
        <div className="default-public-result-card-container">
          <div
            className="default-public-result-alert"
            style={{ marginTop: "24px" }}
          >
            <span className="body-1 dark-100 text-center">
              {publicResult?.error_message === "No Record Found"
                ? "Result is not public."
                : publicResult?.error_message === "Not Found"
                ? "Invalid Assessment Id"
                : publicResult?.error_message}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
