export const assessmentStatus = {
	INITIALIZED: 'Initialized',
	IN_PROGRESS: 'In Progress',
	UNDER_EVALUATION: 'Under Evaluation',
	FINISHED: 'Finished',
	NOT_CONSIDERED: 'Not Considered',
	SHORTLISTED: 'Shortlisted',
};

//////// For Personality Test ///////
export const PersonalityJobRole = [
	{ 'name': 'Software Engineer', 'value': 'Software Engineer' },
	{ 'name': 'Data Scientist', 'value': 'Data Scientist' },
	{ 'name': 'Product Manager', 'value': 'Product Manager' },
	{ 'name': 'Project Manager', 'value': 'Project Manager' },
	{ 'name': 'Sales Representative', 'value': 'Sales Representative' },
	{ 'name': 'Marketing Coordinator', 'value': 'Marketing Coordinator' },
	{ 'name': 'Customer Support Specialist', 'value': 'Customer Support Specialist' },
	{ 'name': 'Financial Analyst', 'value': 'Financial Analyst' },
	{ 'name': 'Human Resources Manager', 'value': 'Human Resources Manager' },
	{ 'name': 'Graphic Designer', 'value': 'Graphic Designer' },
	{ 'name': 'Operations Manager', 'value': 'Operations Manager' },
	{ 'name': 'Accountant', 'value': 'Accountant' },
	{ 'name': 'Business Analyst', 'value': 'Business Analyst' },
	{ 'name': 'Executive Assistant', 'value': 'Executive Assistant' },
	{ 'name': 'Content Writer', 'value': 'Content Writer' },
	{ 'name': 'Web Developer', 'value': 'Web Developer' },
	{ 'name': 'UX/UI Designer', 'value': 'UX/UI Designer' },
	{ 'name': 'Network Administrator', 'value': 'Network Administrator' },
	{ 'name': 'Legal Assistant', 'value': 'Legal Assistant' },
	{ 'name': 'IT Manager', 'value': 'IT Manager' },
	{ 'name': 'Customer Success Manager', 'value': 'Customer Success Manager' },
	{ 'name': 'Healthcare Administrator', 'value': 'Healthcare Administrator' },
	{ 'name': 'Quality Assurance Analyst', 'value': 'Quality Assurance Analyst' },
	{ 'name': 'Supply Chain Manager', 'value': 'Supply Chain Manager' },
	{ 'name': 'Social Media Specialist', 'value': 'Social Media Specialist' },
	{ 'name': 'Mechanical Engineer', 'value': 'Mechanical Engineer' },
	{ 'name': 'Electrical Engineer', 'value': 'Electrical Engineer' },
	{ 'name': 'Administrative Assistant', 'value': 'Administrative Assistant' },
	{ 'name': 'DevOps Engineer', 'value': 'DevOps Engineer' },
	{ 'name': 'Mobile App Developer', 'value': 'Mobile App Developer' },
	{ 'name': 'Technical Support Specialist', 'value': 'Technical Support Specialist' },
	{ 'name': 'Customer Service Manager', 'value': 'Customer Service Manager' },
	{ 'name': 'Retail Sales Associate', 'value': 'Retail Sales Associate' },
	{ 'name': 'Pharmacist', 'value': 'Pharmacist' },
	{ 'name': 'Civil Engineer', 'value': 'Civil Engineer' },
	{ 'name': 'Nurse Practitioner', 'value': 'Nurse Practitioner' },
	{ 'name': 'Physical Therapist', 'value': 'Physical Therapist' },
	{ 'name': 'Teacher', 'value': 'Teacher' },
	{ 'name': 'Interior Designer', 'value': 'Interior Designer' },
	{ 'name': 'Construction Manager', 'value': 'Construction Manager' },
	{ 'name': 'Business Development Manager', 'value': 'Business Development Manager' },
	{ 'name': 'Account Manager', 'value': 'Account Manager' },
	{ 'name': 'UX Researcher', 'value': 'UX Researcher' },
	{ 'name': 'Mechanical Designer', 'value': 'Mechanical Designer' },
	{ 'name': 'Operations Coordinator', 'value': 'Operations Coordinator' },
	{ 'name': 'Financial Planner', 'value': 'Financial Planner' },
	{ 'name': 'IT Support Specialist', 'value': 'IT Support Specialist' },
	{ 'name': 'Software Developer', 'value': 'Software Developer' },
	{ 'name': 'Systems Analyst', 'value': 'Systems Analyst' },
	{ 'name': 'Event Coordinator', 'value': 'Event Coordinator' },
	{ 'name': 'Logistics Coordinator', 'value': 'Logistics Coordinator' },
	{ 'name': 'Executive Chef', 'value': 'Executive Chef' },
	{ 'name': 'Accounting Clerk', 'value': 'Accounting Clerk' },
	{ 'name': 'Legal Secretary', 'value': 'Legal Secretary' },
	{ 'name': 'Digital Marketing Manager', 'value': 'Digital Marketing Manager' },
	{ 'name': 'IT Project Manager', 'value': 'IT Project Manager' },
	{ 'name': 'Healthcare Analyst', 'value': 'Healthcare Analyst' },
	{ 'name': 'Research Scientist', 'value': 'Research Scientist' },
	{ 'name': 'UI Designer', 'value': 'UI Designer' },
	{ 'name': 'Database Administrator', 'value': 'Database Administrator' },
	{ 'name': 'Customer Service Representative', 'value': 'Customer Service Representative' },
	{ 'name': 'Retail Manager', 'value': 'Retail Manager' },
	{ 'name': 'Clinical Research Coordinator', 'value': 'Clinical Research Coordinator' },
	{ 'name': 'Front End Developer', 'value': 'Front End Developer' },
	{ 'name': 'UX Designer', 'value': 'UX Designer' },
	{ 'name': 'Technical Writer', 'value': 'Technical Writer' },
	{ 'name': 'Business Intelligence Analyst', 'value': 'Business Intelligence Analyst' },
	{ 'name': 'Network Engineer', 'value': 'Network Engineer' },
	{ 'name': 'Legal Counsel', 'value': 'Legal Counsel' },
	{ 'name': 'Sales Manager', 'value': 'Sales Manager' },
	{ 'name': 'Operations Analyst', 'value': 'Operations Analyst' },
	{ 'name': 'Financial Controller', 'value': 'Financial Controller' },
	{ 'name': 'HR Coordinator', 'value': 'HR Coordinator' },
	{ 'name': 'Content Manager', 'value': 'Content Manager' },
	{ 'name': 'Data Analyst', 'value': 'Data Analyst' },
	{ 'name': 'Systems Engineer', 'value': 'Systems Engineer' },
	{ 'name': 'UX/UI Developer', 'value': 'UX/UI Developer' },
	{ 'name': 'Customer Success Specialist', 'value': 'Customer Success Specialist' },
	{ 'name': 'Machine Learning Engineer', 'value': 'Machine Learning Engineer' }
];

export const PersonalitySeniority = [
	{ 'name': 'Entry Level', 'value': 'Entry Level' },
	{ 'name': 'Junior', 'value': 'Junior' },
	{ 'name': 'Intermediate', 'value': 'Intermediate' },
	{ 'name': 'Mid-Level', 'value': 'Mid-Level' },
	{ 'name': 'Senior', 'value': 'Senior' },
	{ 'name': 'Lead', 'value': 'Lead' },
	{ 'name': 'Principal', 'value': 'Principal' },
	{ 'name': 'Director', 'value': 'Director' },
	{ 'name': 'Manager', 'value': 'Manager' },
	{ 'name': 'Executive', 'value': 'Executive' }
];

export const PersonalityIndustry = [
	{
		'name': 'Information Technology',
		'value': 'Information Technology'
	},
	{
		'name': 'Healthcare',
		'value': 'Healthcare'
	},
	{
		'name': 'Finance',
		'value': 'Finance'
	},
	{
		'name': 'Retail',
		'value': 'Retail'
	},
	{
		'name': 'Manufacturing',
		'value': 'Manufacturing'
	},
	{
		'name': 'Education',
		'value': 'Education'
	},
	{
		'name': 'Construction',
		'value': 'Construction'
	},
	{
		'name': 'Automotive',
		'value': 'Automotive'
	},
	{
		'name': 'Telecommunications',
		'value': 'Telecommunications'
	},
	{
		'name': 'Hospitality',
		'value': 'Hospitality'
	},
	{
		'name': 'Energy',
		'value': 'Energy'
	},
	{
		'name': 'Media and Entertainment',
		'value': 'Media and Entertainment'
	},
	{
		'name': 'Biotechnology',
		'value': 'Biotechnology'
	},
	{
		'name': 'Pharmaceuticals',
		'value': 'Pharmaceuticals'
	},
	{
		'name': 'Aerospace',
		'value': 'Aerospace'
	},
	{
		'name': 'Real Estate',
		'value': 'Real Estate'
	},
	{
		'name': 'Transportation',
		'value': 'Transportation'
	},
	{
		'name': 'Marketing and Advertising',
		'value': 'Marketing and Advertising'
	},
	{
		'name': 'Food and Beverage',
		'value': 'Food and Beverage'
	},
	{
		'name': 'Consulting',
		'value': 'Consulting'
	},
	{
		'name': 'Legal',
		'value': 'Legal'
	},
	{
		'name': 'Nonprofit',
		'value': 'Nonprofit'
	},
	{
		'name': 'Government',
		'value': 'Government'
	},
	{
		'name': 'Insurance',
		'value': 'Insurance'
	},
	{
		'name': 'E-commerce',
		'value': 'E-commerce'
	},
	{
		'name': 'Engineering',
		'value': 'Engineering'
	},
	{
		'name': 'Logistics',
		'value': 'Logistics'
	},
	{
		'name': 'Utilities',
		'value': 'Utilities'
	},
	{
		'name': 'Fashion',
		'value': 'Fashion'
	},
	{
		'name': 'Sports and Recreation',
		'value': 'Sports and Recreation'
	},
	{
		'name': 'Travel and Tourism',
		'value': 'Travel and Tourism'
	},
	{
		'name': 'Art and Design',
		'value': 'Art and Design'
	},
	{
		'name': 'Computer Hardware',
		'value': 'Computer Hardware'
	},
	{
		'name': 'Software Development',
		'value': 'Software Development'
	},
	{
		'name': 'Internet',
		'value': 'Internet'
	},
	{
		'name': 'Architecture',
		'value': 'Architecture'
	},
	{
		'name': 'Gaming',
		'value': 'Gaming'
	},
	{
		'name': 'Human Resources',
		'value': 'Human Resources'
	},
	{
		'name': 'Security and Investigations',
		'value': 'Security and Investigations'
	},
	{
		'name': 'Environmental Services',
		'value': 'Environmental Services'
	},
	{
		'name': 'Renewables and Environment',
		'value': 'Renewables and Environment'
	},
	{
		'name': 'Social Services',
		'value': 'Social Services'
	},
	{
		'name': 'Telecom Services',
		'value': 'Telecom Services'
	},
	{
		'name': 'Printing',
		'value': 'Printing'
	},
	{
		'name': 'Maritime',
		'value': 'Maritime'
	},
	{
		'name': 'Public Relations',
		'value': 'Public Relations'
	},
	{
		'name': 'Chemicals',
		'value': 'Chemicals'
	},
	{
		'name': 'Textiles',
		'value': 'Textiles'
	},
	{
		'name': 'Publishing',
		'value': 'Publishing'
	},
	{
		'name': 'Civic and Social Organization',
		'value': 'Civic and Social Organization'
	},
	{
		'name': 'Veterinary',
		'value': 'Veterinary'
	},
	{
		'name': 'Mental Health Care',
		'value': 'Mental Health Care'
	},
	{
		'name': 'Fitness',
		'value': 'Fitness'
	},
	{
		'name': 'Cosmetics',
		'value': 'Cosmetics'
	},
	{
		'name': 'Investment Banking',
		'value': 'Investment Banking'
	},
	{
		'name': 'Mining and Metals',
		'value': 'Mining and Metals'
	},
	{
		'name': 'Renewable Energy and Environment',
		'value': 'Renewable Energy and Environment'
	},
	{
		'name': 'Consumer Electronics',
		'value': 'Consumer Electronics'
	},
	{
		'name': 'Warehousing',
		'value': 'Warehousing'
	},
	{
		'name': 'Biomedical',
		'value': 'Biomedical'
	},
	{
		'name': 'Accounting',
		'value': 'Accounting'
	},
	{
		'name': 'Venture Capital and Private Equity',
		'value': 'Venture Capital and Private Equity'
	},
	{
		'name': 'Water and Wastewater',
		'value': 'Water and Wastewater'
	},
	{
		'name': 'Gambling and Casinos',
		'value': 'Gambling and Casinos'
	},
	{
		'name': 'Nanotechnology',
		'value': 'Nanotechnology'
	},
	{
		'name': 'Package/Freight Delivery',
		'value': 'Package/Freight Delivery'
	},
	{
		'name': 'International Trade and Development',
		'value': 'International Trade and Development'
	},
	{
		'name': 'Investment Management',
		'value': 'Investment Management'
	},
	{
		'name': 'Public Safety',
		'value': 'Public Safety'
	},
	{
		'name': 'Philanthropy',
		'value': 'Philanthropy'
	},
	{
		'name': 'Religious Institutions',
		'value': 'Religious Institutions'
	},
	{
		'name': 'Import and Export',
		'value': 'Import and Export'
	},
	{
		'name': 'Package/Freight Delivery',
		'value': 'Package/Freight Delivery'
	},
	{
		'name': 'Mechanical or Industrial Engineering',
		'value': 'Mechanical or Industrial Engineering'
	}
];

// stripe subscription statuses
export const inviteBlock = ['incomplete', 'past_due', 'pending'];
export const fullBlock = ['paused', 'incomplete_expired', 'unpaid', 'canceled'];

export const stripePublicKey = window._env_?.REACT_APP_STRIPE_PUBLIC_KEY;

// if (!stripePublicKey) {
// 	stripePublicKey = 'pk_test_HerFpGS9a4wwWEa3emfBETfv';
// }

// export {stripePublicKey};

export const PathType = Object.freeze({
	AUTHENTICATION: 'authentication',
	ACTIVATION: 'activation',
	PROTECTED: 'protected',
	UNPROTECTED: 'unprotected',
});

export const AppState = Object.freeze({
	LOADING: 'loading',
	READY: 'ready',
});

export const ActivationState = Object.freeze({
	PENDING: 'pending',
	SUCCESS: 'success',
	FAILED: 'failed',
});

export const UserRole = Object.freeze({
	SUPER_ADMIN: 'Super Admin',
	OWNER: 'Owner',
	ADMIN: 'Admin',
	RECRUITER: 'Recruiter',
	HIRING_MANAGER: 'Hiring Manager',
	CANDIDATE: 'Candidate',
	GUEST: 'GUEST',
	PUBLIC: 'Public',
	MEMBER: 'Member',
});

export const RequestStatus = Object.freeze({
	FULFILLED: 'fulfilled',
	REJECTED: 'rejected',
	PENDING: 'pending',
});
export const tokenCookieName = 'rftk';

export const MAX_QUALIFYING_QUESTIONS = 5;

export const SectionType = Object.freeze({
	SKILL_TEST: 'skill_test',
	CODING_TEST: 'coding_test',
	VALUE_TEST: 'value_test',
	SITUATIONAL_JUDGMENT_TEST: 'situational_judgment_test',
	PERSONALITY_TEST: 'personality_test',
	UPLOAD_TEST: 'upload_test',
	TEXT_TEST: 'text_test',
	VIDEO_TEST: 'video_test',
	CASE_STUDY_TEST: 'case_study_test',
});
export const TestType = Object.freeze({
	QUALIFYING_TEST: 'qualifying_test',
	SKILL_TEST: 'skill_test',
	CODING_TEST: 'coding_test',
	VALUE_TEST: 'value_test',
	SITUATIONAL_JUDGMENT_TEST: 'situational_judgment_test',
	PERSONALITY_TEST: 'personality_test',
	UPLOAD_TEST: 'upload_test',
	TEXT_TEST: 'text_test',
	VIDEO_TEST: 'video_test',
	CASE_STUDY_TEST: 'case_study_test',
});

export const AssessmentSpecStatus = Object.freeze({
	DRAFT: 'DRAFT',
	ACTIVE: 'ACTIVE',
	DISABLED: 'DISABLED',
	ARCHIVED: 'ARCHIVED',
});

export const AssessmentStatus = Object.freeze({
	PENDING: 'PENDING',
	IN_PROGRESS: 'IN_PROGRESS',
	FINISHED: 'FINISHED',
	NOT_QUALIFIED: 'NOT_QUALIFIED',
	DECLINED: 'DECLINED',
});

export const CaseStudyQuestionTypes = Object.freeze({
	TEXT_RESPONSE: 'text_response',
	UPLOAD_RESPONSE: 'upload_response',
	VIDEO_RESPONSE: 'video_response',
});

export const PersonalityTestQuestionTypes = Object.freeze({
	TEXT_RESPONSE: 'text_response',
	VIDEO_RESPONSE: 'video_response',
});

export const Judge0Status = Object.freeze({
	1: {
		id: 1,
		description: 'In Queue',
		is_error: false,
	},
	2: {
		id: 2,
		description: 'Processing',
		is_error: false,
	},
	3: {
		id: 3,
		description: 'Accepted',
		is_error: false,
	},
	4: {
		id: 4,
		description: 'Wrong Answer',
		is_error: true,
	},
	5: {
		id: 5,
		description: 'Time Limit Exceeded',
		is_error: true,
	},
	6: {
		id: 6,
		description: 'Compilation Error',
		is_error: true,
	},
	7: {
		id: 7,
		description: 'Runtime Error (SIGSEGV)',
		is_error: true,
	},
	8: {
		id: 8,
		description: 'Runtime Error (SIGXFSZ)',
		is_error: true,
	},
	9: {
		id: 9,
		description: 'Runtime Error (SIGFPE)',
		is_error: true,
	},
	10: {
		id: 10,
		description: 'Runtime Error (SIGABRT)',
		is_error: true,
	},
	11: {
		id: 11,
		description: 'Runtime Error (NZEC)',
		is_error: true,
	},
	12: {
		id: 12,
		description: 'Runtime Error (Other)',
		is_error: true,
	},
	13: {
		id: 13,
		description: 'Internal Error',
		is_error: true,
	},
	14: {
		id: 14,
		description: 'Exec Format Error',
		is_error: true,
	},
});
