import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import AssessmentCreationHeader from "../../../../components/dashboard/assessments/AssessmentCreationHeader";
import {
  getAssessSpec,
  getAssessSpecAI,
  updateAssessSpec,
} from "../../../../redux/thunks/AssessSpec";
import { listTestPacks } from "../../../../redux/thunks/TestPack";
import CreateAssessment from "./create-assessment/CreateAssessment";

import { ReactComponent as PlusIcon } from "../../../../assets/icons/Views/dashboard/assessments/plus1.svg";
import { ReactComponent as TickIcon } from "../../../../assets/icons/Views/dashboard/assessments/tick.svg";
import { ReactComponent as BackArrowIcon } from "../../../../assets/icons/Views/dashboard/test-detail/left-arrow.svg";
import { ReactComponent as NextArrowIcon } from "../../../../assets/icons/Views/dashboard/create-assessment/Rounded Action (1).svg";
import { ReactComponent as LeftArrowIcon } from "../../../../assets/icons/Views/dashboard/assessments/arrow-left.svg";

import { ReactComponent as PublishAssessmentIcon } from "../../../../assets/icons/assessment-creation/upload.svg";
import { ReactComponent as OpenAIIcon } from "../../../../assets/icons/Views/dashboard/assessments/chat gpt.svg";

import WelcomeVideo from "./WelcomeVideo";
import CustomModal from "../../../../components/common/Modal";
import { PathName } from "../../../../utils/routes";
import {
  CaseStudyQuestionTypes,
  RequestStatus,
  TestType,
} from "../../../../utils/Constants";
import { calculateTimeDifferenceInDays } from "../../../../utils/utilities";
import { CommonContext } from "../../../../utils/contexts/CommonContext";
import CreateQualifyingQuestions from "./qualifying-questions/CreateQualifyingQuestions";
import {
  AssessmentTestValidation,
  QualifyingQuestionValidator,
  WelcomeVideoValidator,
} from "./AssessmentCreationValidators";
import AlertBox from "../../../../components/common/AlertBox";
import { deleteResource } from "../../../../redux/thunks/Resource";
import useMediaQuery from "@mui/material/useMediaQuery";
import TestpackDetails from "../../../../components/dashboard/assessments/create-assessment/TestpackDetails";
import CreateAssessmentAdditionalSections from "./CreateAssessmentAdditionalSections";
import Button from "../../../../components/sub-component/Button";
import { map } from "free-email-domains";

export default function AssessmentSpecsView(props) {
  const isOnWebScreen = useMediaQuery("(min-width:1200px)");
  const isOnTabletScreen = useMediaQuery(
    "(min-width:768px) and (max-width:1199px)"
  );
  const isOnMobileScreen = useMediaQuery("(max-width: 767px)");
  const dispatch = useDispatch();
  const [openSectionType, setOpenSectionType] = useState({
    type: null,
    isOpen: false,
  });
  const [selectedTestInfo, setSelectedTestInfo] = useState({
    test: null,
    openModal: false,
    isItemAdded: null,
  });
  const { ref, inView } = useInView({
    threshold: 0,
    // delay: 1000
  });
  const [showAdditionalOptions, setShowAdditionalOptions] = useState(false);
  const [isReverted, setIsReverted] = useState(false);
  const toggleAdditionalOptions = () => {
    setShowAdditionalOptions(!showAdditionalOptions);
  };
  const handleRevert = () => {
    setShowAdditionalOptions(false);
    setIsReverted(true);
  };

  const handlePublishClick = () => {
    console.log("HIT");
    props.getActiveSection;
    setShowPublishModal(true);
  };
  const handleAiAssessmentClick = () => {
    setActiveSection({
      type: "default",
      section: "default",
    });
    setJdModal(true);
  };

  const showPreviewPage = (id) => {
    const url = `${PathName.TESTPACK_PREVIEW_QUESTIONS}?test_id=${id}`;
    window.open(url, "_blank");
  };

  const auth = useSelector((state) => state.authReducer);
  const subscription = useSelector((state) => state.subscriptionReducer);
  const expireAt =
    subscription?.user_subscription.length > 0
      ? subscription?.user_subscription[0]?.expires_at
      : null;
  const daysLeft = calculateTimeDifferenceInDays(expireAt);
  const openLeftDaysDisclaimer = sessionStorage.getItem(
    "openLeftDaysDisclaimer"
  );

  const { commonValues, setCommonValues } = useContext(CommonContext);

  const welcomeVideoRef = useRef();
  const qualifyingQuestionRef = useRef();
  const assessmentSectionRef = useRef();

  const [alerts, setAlerts] = useState([]);
  const [alertForJD, setAlertForJD] = useState(false);

  const [activeSection, setActiveSection] = useState({
    type: null,
    section: null,
  });

  const welcomeVideoInView = useInView({
    threshold: 1,
    root: document.body,
    rootMargin: "-100px 0px -200px 0px",
  });
  const qualifyingQuestionInView = useInView({
    threshold: 1,
    root: document.body,
    rootMargin: "-100px 0px -200px 0px",
  });
  const assessmentSectionInView = useInView({
    threshold: 0.4,
    root: document.body,
    rootMargin: "-100px 0px 0px 0px",
  });

  const setWelcomeVideoRef = useCallback(
    (node) => {
      welcomeVideoRef.current = node;
      welcomeVideoInView.ref(node);
    },
    [welcomeVideoInView.ref]
  );

  const setQualifyingTestRef = useCallback(
    (node) => {
      qualifyingQuestionRef.current = node;
      qualifyingQuestionInView.ref(node);
    },
    [welcomeVideoInView.ref]
  );

  const setAssessmentSectionRef = useCallback(
    (node) => {
      assessmentSectionRef.current = node;
      assessmentSectionInView.ref(node);
    },
    [welcomeVideoInView.ref]
  );

  const [WVDataBeforePublish, setWVDataBeforePublish] = useState(undefined);
  const [QQDataBeforePublish, setQQDataBeforePublish] = useState(undefined);
  const [assessmentDataDataBeforePublish, setAssessmentDataDataBeforePublish] =
    useState(undefined);

  const [assessmentSpecsDetails, setAssessmentSpecsDetails] = useState(null);
  const [isACFBoxExtended, setIsACFBoxExtended] = useState(false);
  const [loading, setloading] = useState(false);

  const [jdModal, setJdModal] = useState(false);
  const [jdConfermationModal, setjdConfermationModal] = useState(false);

  const [extendDeadlineBox, setExtendDeadlineBox] = useState(false);
  const [deadlineError, setDeadlineError] = useState("");
  const [customDeadline, setCustomDeadline] = useState(false);

  const [jdContent, setJdContent] = useState("");
  const [loadingMessages, setLoadingMessages] = useState([
    "Extracting job requirements...",
    "Fetching relevant skills...",
    "Filtering coding languages...",
    "Identifying company values...",
    "Preparing Assessment...",
  ]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  const [rightSideMenu, setRightSideMenu] = useState({
    welcome_video: undefined,
    qualifying_questions: undefined,
    assessSections: [],
  });
  const [selectedOnRightSideMenuIndex, setSelectedOnRightSideMenuIndex] =
    useState(-1);
  const [checkValidation, setCheckValidation] = useState(false);
  const [resetQQ, setResetQQ] = useState(false);
  const [resetAssessmentCreationSection, setResetAssessmentCreationSection] =
    useState(false);
  const [missingTests, setMissingTests] = useState({
    missing_skill_tests: [],
    missing_situational_judgment_tests: [],
    missing_coding_tests: [],
    missing_value_tests: [],
  });

  const [sectionToDelete, setSectionToDelete] = useState({
    index: -1,
    showModal: false,
  });

  const [currentStep, setCurrentStep] = useState("creation");

  const [showPublishModal, setShowPublishModal] = useState(false);
  const [isWelcomeVideoHighlighted, setIsWelcomeVideoHighlighted] =
    useState(false);
  const [
    isQualifyingQuestionHightlighted,
    setIsQualifyingQuestionHightlighted,
  ] = useState(false);

  //testypes states
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(-1);
  const [selectedSectionType, setSelectedSectionType] = useState(null);
  const [errorsInSections, setErrorsInSections] = useState([]);
  const [fromDragIndex, setFromDragIndex] = useState(-1);

  //..................................................

  const params = useParams();
  const history = useHistory();

  // const isOnTabletScreen = useMediaQuery('(max-width:1439px)');
  /**
   * @type {TAssessSpecState}
   */
  const assessSpec = useSelector((state) => state.assessSpecReducer);

  const testPacks = useSelector((state) => state.testPackReducer);

  // const quesPrompts = useSelector((state) => state.quesPromptReducer);

  const updateAssessmentSpecTitle = async (title) => {
    const updatedAssessSpec = { ...assessSpec?.curr_assessment_spec };
    updatedAssessSpec.title = title;

    handleUpdateAssessment(updatedAssessSpec);
  };

  const handleUpdateDeadline = async (deadline) => {
    const updatedAssessSpec = { ...assessSpec?.curr_assessment_spec };
    updatedAssessSpec.deadline = deadline.value;
    handleUpdateAssessment(updatedAssessSpec);
  };

  const updateAssessmentSpecAvtarPic = async (avatar_id) => {
    const updatedAssessSpec = { ...assessSpec?.curr_assessment_spec };
    updatedAssessSpec.avatar_id = avatar_id;

    handleUpdateAssessment(updatedAssessSpec);
  };

  const getAssessObjectiveTime = (assessment) => {
    var _totalDuration = 0;
    var _objective_duration = 0;
    assessment.tests?.forEach((_test, _) => {
      if (
        _test.test_type === TestType.SKILL_TEST ||
        _test.test_type === TestType.CODING_TEST ||
        _test.test_type === TestType.SITUATIONAL_JUDGMENT_TEST ||
        _test.test_type === TestType.PERSONALITY_TEST
      ) {
        _objective_duration += _test?.duration;
        _totalDuration += _test?.duration;
      } else if (_test.test_type === TestType.CASE_STUDY_TEST) {
        _totalDuration += _test?.duration;
      } else if (_test.test_type === TestType.VALUE_TEST) {
        _test?.values?.forEach((_value, _) => {
          _objective_duration += _value?.duration;
          _totalDuration += _value?.duration;
        });
      } else if (
        _test.test_type === TestType.TEXT_TEST ||
        _test.test_type === TestType.VIDEO_TEST
      ) {
        _test?.questions?.forEach((_ques, _) => {
          _totalDuration += _ques?.duration;
        });
      }
    });
    return {
      _duration: _totalDuration,
      _objective_test_duration: _objective_duration,
    };
  };

  const handleConfirmAssessmentSpec = async (currStep) => {
    const validateAssessment = assessmentValidationForPublish(
      WVDataBeforePublish,
      QQDataBeforePublish,
      assessSpec?.curr_assessment_spec?.deadline,
      assessSpec?.curr_assessment_spec?.duration,
      assessmentDataDataBeforePublish
    );
    if (
      !validateAssessment.isValidWelcomeVideo.success ||
      !validateAssessment.isValidQQ.success ||
      !validateAssessment.isValidAssessmentTest.success ||
      !validateAssessment.isValidDeadline.success
    ) {
      const tempAlerts = Object.keys(validateAssessment).map(
        (_validation, _) => {
          if (!validateAssessment[_validation].success) {
            return { title: _validation, ...validateAssessment[_validation] };
          }
        }
      );
      setAlerts([...tempAlerts]);
      setCheckValidation(true);
      return;
    }

    const _assess = {
      ...assessmentSpecsDetails,
      welcome_video: WVDataBeforePublish,
      qualifying_questions: QQDataBeforePublish,
      sections: assessmentDataDataBeforePublish?.sections,
      tests: assessmentDataDataBeforePublish?.tests,
    };

    handleConfirmSection(_assess);
    const updatedAssessSpec = { ...assessSpec?.curr_assessment_spec };
    updatedAssessSpec.status = "ACTIVE";

    const dispatched = await handleUpdateAssessment(updatedAssessSpec);

    if (dispatched.error) {
      return;
    }

    history.push(`${PathName.ASSESSMENTS}/publish/${params.id}`);
  };

  const handleConfirmSection = async (assessmentSpecsDetails) => {
    const welcomeVideo = assessmentSpecsDetails.welcome_video && {
      type: assessmentSpecsDetails.welcome_video.type,
      ...(assessmentSpecsDetails.welcome_video.type === "id"
        ? {
            video_id:
              assessmentSpecsDetails.welcome_video.src === ""
                ? null
                : assessmentSpecsDetails.welcome_video.src,
          }
        : {
            video_url:
              assessmentSpecsDetails.welcome_video.src === ""
                ? null
                : assessmentSpecsDetails.welcome_video.src,
          }),
    };

    const qualifyingQuestions =
      assessmentSpecsDetails.qualifying_questions &&
      assessmentSpecsDetails.qualifying_questions?.map((question) => {
        return {
          statement: question.question,
          type: question.type,
          correct_answer: question.idealAnswer,
          ...(question.type === "mcq" ? { answers: question.options } : null),
        };
      });

    const sections = assessmentSpecsDetails.sections
      ?.filter((section) => section !== null)
      .map((section) => {
        return {
          intro_text: section.name,
          type: section.type,
        };
      });

    const tests = [];

    assessmentSpecsDetails.tests
      ?.filter((test) => test !== null)
      .forEach((test) => {
        if (
          test.test_type === "skill_test" ||
          test.test_type === "situational_judgment_test" ||
          test.test_type === "coding_test"
        ) {
          test.questions.forEach((question) => {
            tests.push({
              test_type: test.test_type,
              tp_id: question.id,
              duration: question.duration,
              title: question.title,
              icon: question.icon,
              category: question.category,
              difficulty: question.difficulty,
            });
          });
        } else if (test.test_type === "value_test") {
          tests.push({
            test_type: test.test_type,
            values: test.questions.map((question) => ({
              value_name: question.title,
              title: question.title,
              duration: question.duration,
              val_id: question.id,
            })),
          });
        } else if (test.test_type === "personality_test") {
          tests.push({
            test_type: test?.test_type,
            duration: test?.duration,
            job_dimensions: {
              job_role: test?.job_dimensions?.job_role,
              seniority: test?.job_dimensions?.seniority,
              industry: test?.job_dimensions?.industry,
              job_location: test?.job_dimensions?.job_location,
              is_customer_facing: test?.job_dimensions?.is_customer_facing,
              is_leadership_position:
                test?.job_dimensions?.is_leadership_position,
            },
          });
        } else if (test.test_type === "upload_test") {
          tests.push({
            test_type: test.test_type,
            questions: test.questions.map((question) => ({
              text: question.question,
              title: question.title,
              is_mandatory: question.is_mandatory ? true : false,
            })),
          });
        } else if (test.test_type === "text_test") {
          tests.push({
            test_type: test.test_type,
            allow_file_upload: test.allow_file_upload ? true : false,
            questions: test.questions.map((question) => ({
              text: question.question,
              duration: question.duration,
              is_mandatory: question.is_mandatory ? true : false,
              ...(question.qp_id ? { qp_id: question.qp_id } : null),
              ...(question.duration ? { duration: question.duration } : null),
            })),
          });
        } else if (test.test_type === "video_test") {
          tests.push({
            test_type: test.test_type,
            questions: test.questions.map((question) => ({
              text: question.question,
              title: question.title,
              is_mandatory: question.is_mandatory ? true : false,
              recording_type: question.recording_type,
              ...(question.qp_id ? { qp_id: question.qp_id } : null),
              ...(question.duration ? { duration: question.duration } : null),
            })),
          });
        } else if (test.test_type === "case_study_test") {
          // recording_type
          tests.push({
            test_type: test.test_type,
            intro_text: test.intro_text,
            duration: test.duration,
            questions: test.questions.map((question) => {
              if (question.question_type === "video_response") {
                return {
                  text: question.question,
                  type: question.question_type,
                  is_mandatory: question.is_mandatory ? true : false,
                  recording_type: question.recording_type,
                  ...(question.duration
                    ? { duration: question.duration }
                    : null),
                };
              } else {
                return {
                  text: question.question,
                  type: question.question_type,
                  is_mandatory: question.is_mandatory ? true : false,
                  ...(question.duration
                    ? { duration: question.duration }
                    : null),
                };
              }
            }),
          });
        }
      });

    const updatedAssessSpec = { ...assessSpec?.curr_assessment_spec };

    if (assessmentSpecsDetails.tests) {
      const totalDuration = assessmentSpecsDetails.tests
        ?.filter((test) => test)
        .map((test) => {
          if (test.test_type === "case_study_test") {
            return [test]?.reduce(
              (preValue, data) => preValue + Number(data?.duration || 0),
              0
            );
          }
          return test.questions?.reduce(
            (preValue, question) => preValue + Number(question?.duration || 0),
            0
          );
        })
        .reduce((preValue, current) => preValue + Math.floor(current / 60), 0);
      updatedAssessSpec.duration = totalDuration * 60;
    }

    updatedAssessSpec.title = assessmentSpecsDetails?.title;
    updatedAssessSpec.qualifying_questions = qualifyingQuestions || null;
    updatedAssessSpec.sections = sections;
    updatedAssessSpec.tests = tests;
    updatedAssessSpec.welcome_video = welcomeVideo || null;
    updatedAssessSpec.anti_cheat_settings =
      assessmentSpecsDetails?.anti_cheat_settings;
    updatedAssessSpec.deadline = assessmentSpecsDetails?.deadline;
    setAssessmentSpecsDetails({
      ...assessmentSpecsDetails,
      sections: assessmentSpecsDetails.sections,
      tests: assessmentSpecsDetails.tests,
    });

    handleUpdateAssessment(updatedAssessSpec);
    setSelectedOnRightSideMenuIndex(-1);
  };

  const handleUpdateAssessment = async (assessment) => {
    if (assessment) {
      const { _duration, _objective_test_duration } =
        getAssessObjectiveTime(assessment);
      assessment.duration = _duration;
      assessment.objective_test_duration = _objective_test_duration;
    }
    return await dispatch(
      updateAssessSpec({ id: params.id, data: assessment })
    );
  };

  const getAssessSpecLocalFunc = async () => {
    const assessSpecRes = await dispatch(getAssessSpec({ id: params.id }));
    if (assessSpecRes?.meta?.requestStatus === "fulfilled") {
      if (assessSpecRes?.payload?.status === "ACTIVE" && !props.viewOnly) {
        history.push(`${PathName.ASSESSMENTS}/publish/${params.id}`);
      }
    }
  };

  const onLoadAssessmentData = (_assessment_spec) => {
    const welcomeVideo = _assessment_spec?.welcome_video
      ? {
          type: _assessment_spec?.welcome_video?.type,
          src:
            _assessment_spec?.welcome_video?.type === "id"
              ? _assessment_spec?.welcome_video?.video_id
              : _assessment_spec?.welcome_video?.video_url,
        }
      : undefined;

    const qualifyingQuestions = _assessment_spec?.qualifying_questions?.map(
      (question) => {
        return {
          candidateAnswer: "",
          idealAnswer: question?.correct_answer,
          question: question?.statement,
          type: question?.type,
          ...(question?.type === "mcq" ? { options: question?.answers } : null),
        };
      }
    );

    const sections =
      _assessment_spec?.sections?.map((section) => {
        return {
          name: section.intro_text,
          type: section.type,
        };
      }) || [];

    const tests =
      _assessment_spec?.sections?.map((section) => ({
        test_type: section.type,
        questions: [],
      })) || [];

    _assessment_spec?.tests?.forEach((test) => {
      const sectionIndex = tests.findIndex(
        (_test) => _test.test_type === test.test_type
      );

      if (test.test_type === "skill_test") {
        const testPack = testPacks.data?.skill_test_packs.find(
          (_testPack) => _testPack.id === test.tp_id
        );
        tests[sectionIndex].questions.push(testPack);
      } else if (test.test_type === "coding_test") {
        const testPack = testPacks.data?.coding_test_packs.find(
          (_testPack) => _testPack.id === test.tp_id
        );
        tests[sectionIndex].questions.push(testPack);
      } else if (test.test_type === "value_test") {
        const testPack = testPacks.data?.value_fitness_test_packs?.filter(
          (_testPack) =>
            test.values.find((_value) => _value.val_id === _testPack.id)
        );

        testPack.forEach((tp) => {
          tests[sectionIndex].questions.push(tp);
        });
      } else if (test.test_type === "situational_judgment_test") {
        const testPack = testPacks.data?.situational_judgment_test_packs.find(
          (_testPack) => _testPack.id === test.tp_id
        );
        tests[sectionIndex].questions.push(testPack);
      } else if (test.test_type === "personality_test") {
        (tests[sectionIndex].duration = test?.duration),
          (tests[sectionIndex].job_dimensions = {
            job_role: test?.job_dimensions?.job_role,
            seniority: test?.job_dimensions?.seniority,
            industry: test?.job_dimensions?.industry,
            job_location: test?.job_dimensions?.job_location,
            is_customer_facing: test?.job_dimensions?.is_customer_facing,
            is_leadership_position:
              test?.job_dimensions?.is_leadership_position,
          });
      } else if (test.test_type === "upload_test") {
        tests[sectionIndex].questions = test.questions.map((question) => ({
          question: question.text,
          is_mandatory: question.is_mandatory,
        }));
      } else if (test.test_type === "text_test") {
        tests[sectionIndex].allow_file_upload = test.allow_file_upload;
        tests[sectionIndex].questions = test.questions.map((question) => ({
          question: question.text,
          question_type: question.qp_id
            ? "add_question"
            : "create_custom_question",
          duration: question.duration,
          qp_id: question.qp_id,
          is_mandatory: question.is_mandatory,
        }));
      } else if (test.test_type === "video_test") {
        tests[sectionIndex].questions = test.questions.map((question) => ({
          question: question.text,
          question_type: question.qp_id
            ? "add_question"
            : "create_custom_question",
          duration: question.duration,
          qp_id: question.qp_id,
          is_mandatory: question.is_mandatory,
          recording_type: question.recording_type,
        }));
      } else if (test.test_type === "case_study_test") {
        tests[sectionIndex].duration = test.duration;
        tests[sectionIndex].intro_text = test.intro_text;
        tests[sectionIndex].questions = test.questions.map((question, _) => {
          if (question.type === "video_response") {
            return {
              question: question.text,
              question_type: question.type,
              is_mandatory: question.is_mandatory,
              recording_type: question.recording_type,
              ...(question.duration ? { duration: question.duration } : null),
            };
          } else {
            return {
              question: question.text,
              question_type: question.type,
              is_mandatory: question.is_mandatory,
              ...(question.duration ? { duration: question.duration } : null),
            };
          }
        });
      }
    });

    if (sections.length < 5) {
      for (let i = 0; i < 5 - tests.length; i++) {
        sections.push(null);
      }
    }

    if (tests.length < 5) {
      const arr_length = tests.length;
      for (let i = 0; i < 5 - arr_length; i++) {
        tests.push(null);
      }
    }

    if (_assessment_spec?.anti_cheat_settings?.snapshot_settings?.active) {
      setIsACFBoxExtended(true);
    }

    if (_assessment_spec?.deadline) {
      setExtendDeadlineBox(true);
    }

    setAssessmentSpecsDetails({
      ..._assessment_spec,
      welcome_video: welcomeVideo,
      qualifying_questions: qualifyingQuestions,
      sections: [...sections],
      tests: tests,
      anti_cheat_settings: _assessment_spec?.anti_cheat_settings,
    });

    setRightSideMenu({
      ...rightSideMenu,
      welcome_video: _assessment_spec?.welcome_video ? "Welcome Video" : null,
      qualifying_questions: _assessment_spec?.qualifying_questions
        ? "Qualifying Questions"
        : null,
    });

    return {
      ..._assessment_spec,
      welcome_video: welcomeVideo,
      qualifying_questions: qualifyingQuestions,
      sections: [...sections],
      tests: tests,
      anti_cheat_settings: _assessment_spec?.anti_cheat_settings,
    };
  };

  const handleSubmitJD = async (job_description) => {
    setJdContent("");
    const jd_res = await dispatch(
      getAssessSpecAI({ data: { job_description } })
    );
    if (jd_res?.meta?.requestStatus === RequestStatus.FULFILLED) {
      const updatedAssessSpec = {
        ...assessmentSpecsDetails,
        title: jd_res?.payload?.title,
        qualifying_questions: jd_res?.payload?.qualifying_questions,
        tests: jd_res?.payload?.tests,
        sections: jd_res?.payload?.sections,
      };

      const updatedLocalAssessSpec = onLoadAssessmentData({
        ...updatedAssessSpec,
      });

      // if (updatedLocalAssessSpec.tests) {
      // 	const totalDuration = updatedLocalAssessSpec.tests?.filter(test => test).map((test) => {
      // 		if (test.test_type === 'case_study_test') {
      // 			return [test]?.reduce((preValue, data) => preValue + Number(data?.duration || 0), 0);
      // 		}
      // 		return test.questions?.reduce((preValue, question) => preValue + Number(question?.duration || 0), 0);
      // 	}).reduce((preValue, current) => preValue + Math.floor(current / 60), 0);
      // 	updatedLocalAssessSpec.duration = totalDuration * 60;
      // }

      handleUpdateAssessment(updatedAssessSpec);
      setMissingTests(jd_res?.payload?.missing_tests);
      setJdModal(false);

      if (updatedLocalAssessSpec?.qualifying_questions) {
        setQQDataBeforePublish([
          ...updatedLocalAssessSpec.qualifying_questions,
        ]);
      } else {
        setQQDataBeforePublish(updatedLocalAssessSpec?.qualifying_questions);
      }
      setAssessmentDataDataBeforePublish({ ...updatedLocalAssessSpec });

      setResetQQ(true);
      setResetAssessmentCreationSection(true);
      setSelectedOnRightSideMenuIndex(-1);

      setAlertForJD(
        <AlertBox
          color="success"
          description={"Assessment Generation Successful"}
          title={"Success"}
          isOpen={true}
          onCloseAlert={() => {}}
        />
      );
      console.log("==>", assessmentDataDataBeforePublish);
    } else {
      setAlertForJD(
        <AlertBox
          color="danger"
          description={"Assessment Generation Failed"}
          title={"Error"}
          isOpen={true}
          onCloseAlert={() => {}}
        />
      );
      setJdModal(false);
    }
  };

  useEffect(() => {
    setCommonValues({ ...commonValues, isSideBarExpanded: false });

    if (!testPacks.data) {
      dispatch(listTestPacks());
    }

    getAssessSpecLocalFunc();

    return () => {
      setCommonValues({ ...commonValues, isSideBarExpanded: true });
    };
  }, []);

  useEffect(() => {
    if (
      (assessSpec?.curr_assessment_spec === null || testPacks?.data === null) &&
      !assessmentSpecsDetails
    )
      return;
    onLoadAssessmentData(assessSpec?.curr_assessment_spec);
  }, [testPacks.data, assessSpec?.curr_assessment_spec]);

  const [onTabAddSectionStepNo, setOnTabAddSectionStepNo] = useState(0);

  const assessmentValidationForPublish = (
    WV,
    QQ,
    deadline,
    duration,
    assessmentData
  ) => {
    let isValidForPublish = {
      isValidWelcomeVideo: { success: true, message: "" },
      isValidQQ: { success: true, message: "" },
      isValidAssessmentTest: { success: true, message: "" },
      isValidDeadline: { success: true, message: "" },
    };

    if (WV) {
      const WelcomeVideovalidation = WelcomeVideoValidator(WV);
      if (WelcomeVideovalidation?.isValidate === false) {
        welcomeVideoRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        isValidForPublish = {
          ...isValidForPublish,
          isValidWelcomeVideo: {
            success: false,
            message: "Please fix all issues and try again",
          },
        };
      }
    }

    if (QQ !== undefined) {
      if (QQ.length > 0) {
        const errors = QQ?.map((_, questionIndex) => {
          const QQvalidator = QualifyingQuestionValidator(QQ, questionIndex);
          if (QQvalidator.isValidate === false) {
            return { questionIndex: questionIndex, ...QQvalidator };
          }
        });
        if (
          errors.filter((e, _) => {
            return e !== undefined;
          }).length > 0
        ) {
          if (isValidForPublish.isValidWelcomeVideo.success) {
            qualifyingQuestionRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
          }
          isValidForPublish = {
            ...isValidForPublish,
            isValidQQ: {
              success: false,
              message: "Please fix all issues and try again",
            },
          };
        }
      } else {
        if (isValidForPublish.isValidWelcomeVideo.success) {
          qualifyingQuestionRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
        isValidForPublish = {
          ...isValidForPublish,
          isValidQQ: {
            success: false,
            message: "Please fix all issues and try again",
          },
        };
      }
    }

    if (deadline) {
      if (deadline < duration) {
        isValidForPublish = {
          ...isValidForPublish,
          isValidDeadline: {
            success: false,
            message:
              "Deadline is less than estimated assessment attempt time! ",
          },
        };
        setDeadlineError(
          "Deadline is less than estimated assessment attempt time! "
        );
      } else {
        isValidForPublish = {
          ...isValidForPublish,
          isValidDeadline: { success: true, message: "" },
        };
        setDeadlineError("");
      }
    } else {
      if (customDeadline) {
        isValidForPublish = {
          ...isValidForPublish,
          isValidDeadline: {
            success: false,
            message: "Please specify a Custom deadline",
          },
        };
        setDeadlineError("Please specify a Custom deadline");
      } else {
        isValidForPublish = {
          ...isValidForPublish,
          isValidDeadline: { success: true, message: "" },
        };
        setDeadlineError("");
      }
    }

    if (
      assessmentData?.sections?.filter((e, _) => {
        return e;
      }).length > 0
    ) {
      const _errors = assessmentData?.sections?.map((_section, _) => {
        const _test =
          assessmentData?.tests?.filter((test, _) => {
            return test?.test_type === _section?.type;
          })[0] || {};

        const validator = AssessmentTestValidation(_test);
        if (validator?.isValidate === false) {
          return { ...validator };
        }
      });
      if (
        _errors.filter((e, _) => {
          return e !== undefined;
        }).length > 0
      ) {
        if (
          isValidForPublish.isValidWelcomeVideo.success &&
          isValidForPublish.isValidQQ.success
        ) {
          assessmentSectionRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
        isValidForPublish = {
          ...isValidForPublish,
          isValidAssessmentTest: {
            success: false,
            message: "Please fix all issues and try again",
          },
        };
      } else {
        isValidForPublish = {
          ...isValidForPublish,
          isValidAssessmentTest: { success: true, message: "" },
        };
      }
    } else {
      if (
        isValidForPublish.isValidWelcomeVideo.success &&
        isValidForPublish.isValidQQ.success
      ) {
        assessmentSectionRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
      isValidForPublish = {
        ...isValidForPublish,
        isValidAssessmentTest: {
          success: false,
          message: "Please fix all issues and try again",
        },
      };
    }

    return isValidForPublish;
  };

  const AlertsComp = useMemo(() => {
    return alerts
      .filter((alrt, _) => {
        return alrt !== undefined;
      })
      .map((_alert, _) => {
        if (_alert !== undefined) {
          return (
            <AlertBox
              key={_}
              top={_ !== 0 ? _ * 90 : null}
              color="danger"
              description={_alert.message}
              title={
                _alert.title === "isValidWelcomeVideo"
                  ? "Welcome Video"
                  : _alert.title === "isValidQQ"
                  ? "Qualifying Questions"
                  : _alert.title === "isValidAssessmentTest"
                  ? "Assessment Creation"
                  : "Error"
              }
              isOpen={true}
              onCloseAlert={() => {}}
            />
          );
        }
      });
  }, [alerts]);

  useMemo(() => {
    if (WVDataBeforePublish !== undefined) {
      const _assess = {
        ...assessmentSpecsDetails,
        welcome_video: WVDataBeforePublish,
      };
      handleConfirmSection(_assess);
    }
  }, [WVDataBeforePublish]);

  useEffect(() => {
    if (assessSpec.jdConversion) {
      const interval = setInterval(() => {
        setCurrentMessageIndex((prevIndex) =>
          Math.min(prevIndex + 1, loadingMessages.length - 1)
        );
      }, 3000);

      return () => {
        setCurrentMessageIndex(0);
        clearInterval(interval);
      };
    }
  }, [loadingMessages, assessSpec.jdConversion]);

  const [previouVideoState, setPreviouVideoState] = useState(null);
  const [previouQualifyingQuestion, setPreviouQualifyingQuestion] =
    useState(null);

  useEffect(() => {
    if (assessmentSpecsDetails?.welcome_video && previouVideoState && !inView) {
      if (welcomeVideoRef.current) {
        welcomeVideoRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
    setPreviouVideoState(false);
  }, [assessmentSpecsDetails?.welcome_video]);

  useEffect(() => {
    if (
      assessmentSpecsDetails?.qualifying_questions &&
      previouQualifyingQuestion &&
      !inView
    ) {
      if (qualifyingQuestionRef.current) {
        qualifyingQuestionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
    setPreviouQualifyingQuestion(false);
  }, [assessmentSpecsDetails?.qualifying_questions]);

  if (assessSpec.loading || testPacks.loading || !assessmentSpecsDetails) {
    return (
      <div
        style={{
          position: "fixed",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          height: "25px",
          width: "25px",
          margin: "auto",
        }}
      >
        <div
          className="processing-indicator primary"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
  }

  return (
    <>
      <CustomModal
        open={showPublishModal}
        varrient="alert-warning-modal"
        title="Publish Assessment"
        description={
          "Your assessment is now complete. Are you ready to proceed and share this assessment? "
        }
        successAlert={true}
        warningText={`Warning: No changes can be done to the assessment once you press 'yes, continue'`}
        handleCloseModal={() => {
          setShowPublishModal(false);
        }}
        confirmBtnTitle={"Yes, Continue"}
        btnWidth="max-content"
        onConfirm={() => {
          handleConfirmAssessmentSpec();
          setShowPublishModal(false);
        }}
      />
      <CustomModal
        open={sectionToDelete.showModal}
        varrient="alert-warning-modal"
        title="Delete Section?"
        description={"Are you sure you want to delete this section?"}
        dangerAlert={true}
        handleCloseModal={() => {
          setSectionToDelete({ index: -1, showModal: false });
        }}
        confirmBtnTitle={"Yes, Delete"}
        btnWidth="max-content"
        onConfirm={() => {
          // handleConfirmSection(sectionToDelete.assessSpec);

          const rsm = { ...rightSideMenu };
          let _assessmentDetails = assessmentSpecsDetails;

          if (sectionToDelete.index === 0) {
            delete rsm.welcome_video;
            if (rsm.welcome_video?.src) {
              dispatch(
                deleteResource({ id: _assessmentDetails?.welcome_video?.src })
              );
            }
            _assessmentDetails = {
              ..._assessmentDetails,
              welcome_video: undefined,
            };
            setWVDataBeforePublish(undefined);
          } else {
            delete rsm.qualifying_questions;
            _assessmentDetails = {
              ..._assessmentDetails,
              qualifying_questions: undefined,
            };
            setQQDataBeforePublish(undefined);
          }
          setRightSideMenu(rsm);
          setSectionToDelete({ index: -1, showModal: false });
          setAssessmentSpecsDetails({ ..._assessmentDetails });
          handleConfirmSection({ ..._assessmentDetails });
        }}
      />
      <CustomModal
        open={jdModal}
        varrient="admin-jd-text-box"
        title="Paste your Job Description"
        subTitle={
          "Please input your job description below to automatically convert it into a Testfuse Assessment"
        }
        handleCloseModal={() => {
          setJdModal(false);
          setJdContent("");
        }}
        onConfirm={() => {
          setjdConfermationModal(true);
          setJdModal(false);
        }}
        content={jdContent}
        onChange={setJdContent}
        confirmBtnTitle={"Submit"}
        btnWidth="max-content"
        loading={assessSpec.jdConversion}
        onConfirmBtnDisable={jdContent === ""}
      />
      <CustomModal
        open={jdConfermationModal}
        varrient="alert-warning-modal"
        title={"Replace Assessment!"}
        description={
          "Your existing assessment will be replaced with a new AI generated assessment."
        }
        warningAlert={true}
        handleCloseModal={() => {
          setJdModal(false);
          setJdContent("");
          setjdConfermationModal(false);
        }}
        confirmBtnTitle={"Yes, Continue"}
        btnWidth="max-content"
        onConfirm={async () => {
          setJdModal(true);
          setjdConfermationModal(false);
          handleSubmitJD(jdContent);
        }}
      />

      <>
        {isOnWebScreen ? (
          <div className="assessment-creation-dashboard-page">
            {AlertsComp}
            {alertForJD}
            <div className="assessment-creation-page-content hide-scrollbar">
              <AssessmentCreationHeader
                onTabAddSectionStepNo={onTabAddSectionStepNo}
                getOnTabAddSectionStepNo={(onTabAddSectionStepNo) => {
                  setOnTabAddSectionStepNo(onTabAddSectionStepNo);
                }}
                handlePublishClick={handlePublishClick}
                handleAiAssessmentClick={handleAiAssessmentClick}
                variant="primary"
                assessment={{
                  title: assessSpec?.curr_assessment_spec?.title,
                  updated_at: assessSpec?.curr_assessment_spec?.updated_at,
                }}
                onConfirmTitle={updateAssessmentSpecTitle}
                onPicUpload={updateAssessmentSpecAvtarPic}
                totalDuration={Math.floor(
                  assessSpec?.curr_assessment_spec?.objective_test_duration
                )}
                currentStep={currentStep}
                getCurrentSetp={handleConfirmAssessmentSpec}
                getActiveSection={() => {
                  setActiveSection({
                    type: "default",
                    section: "default",
                  });
                }}
                isObjectiveTime={
                  assessSpec?.curr_assessment_spec?.objective_test_duration !==
                    undefined ||
                  assessSpec?.curr_assessment_spec?.objective_test_duration !==
                    null
                    ? true
                    : false
                }
              />
              {assessmentSpecsDetails?.welcome_video !== undefined && (
                <div className="position-relative" ref={ref}>
                  {loading && (
                    <div
                      style={{
                        position: "absolute",
                        background: "rgb(150, 153, 156, 0.2)",
                        zIndex: 10,
                      }}
                      className="w-100 h-100 d-flex align-item-center justify-content-center"
                    >
                      <div
                        style={{
                          top: "0",
                          bottom: "0",
                          left: "0",
                          right: "0",
                          height: "25px",
                          width: "25px",
                          margin: "auto",
                          zIndex: 999,
                        }}
                      >
                        <div
                          className="processing-indicator primary"
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    </div>
                  )}
                  <div ref={setWelcomeVideoRef}>
                    <div
                      ref={welcomeVideoRef}
                      style={{
                        border: isWelcomeVideoHighlighted
                          ? "1px solid #000"
                          : "",
                        boxShadow: isWelcomeVideoHighlighted
                          ? "0 4px 8px rgba(0, 0, 0, 0.3)"
                          : "",
                        transition: "border 0.3s ease, box-shadow 0.3s ease",
                        borderRadius: "24px",
                      }}
                    >
                      <WelcomeVideo
                        viewOnly={props.viewOnly}
                        onSelect={() => {
                          setSelectedOnRightSideMenuIndex(0);
                        }}
                        isSelected={
                          selectedOnRightSideMenuIndex === 0 ? true : false
                        }
                        assessmentSpecsDetails={assessmentSpecsDetails}
                        getCurrentWVComponentData={(data) => {
                          setWVDataBeforePublish({ ...data });
                        }}
                        onConfirm={handleConfirmSection}
                        onDelete={() => {
                          setSectionToDelete({
                            index: 0,
                            showModal: true,
                          });
                          setPreviouVideoState(false);
                        }}
                        onCancel={(action) => {
                          if (action === "delete") {
                            const rsm = { ...rightSideMenu };
                            const _assessmentDetails = assessmentSpecsDetails;
                            delete rsm.welcome_video;
                            delete _assessmentDetails.welcome_video;
                            setRightSideMenu(rsm);
                            setAssessmentSpecsDetails({
                              ..._assessmentDetails,
                            });
                          } else {
                            setSelectedOnRightSideMenuIndex(1);
                          }
                        }}
                        assessSpec={assessSpec}
                        checkValidation={checkValidation}
                        activeSection={activeSection}
                        getActiveSection={(section) => {
                          setActiveSection({
                            type: "welcome_video",
                            section: section,
                          });
                        }}
                        openSectionType={openSectionType}
                        setOpenSectionType={setOpenSectionType}
                      />
                    </div>
                  </div>
                </div>
              )}
              {assessmentSpecsDetails?.qualifying_questions !== undefined && (
                <div ref={setQualifyingTestRef}>
                  <div
                    ref={qualifyingQuestionRef}
                    style={{
                      border: isQualifyingQuestionHightlighted
                        ? "1px solid #000"
                        : "",
                      boxShadow: isQualifyingQuestionHightlighted
                        ? "0 4px 8px rgba(0, 0, 0, 0.3)"
                        : "",
                      transition: "border 0.3s ease, box-shadow 0.3s ease",
                      borderRadius: "24px",
                    }}
                  >
                    <CreateQualifyingQuestions
                      resetQQ={resetQQ}
                      getResetQQ={(_boolean) => {
                        setResetQQ(_boolean);
                      }}
                      getCurrentQQComponentData={(data) => {
                        setQQDataBeforePublish([...data]);
                        if (data !== undefined) {
                          const _assess = {
                            ...assessmentSpecsDetails,
                            qualifying_questions: data,
                          };
                          handleConfirmSection(_assess);
                        }
                      }}
                      assessmentSpecsDetails={assessmentSpecsDetails}
                      section={{
                        type: "qq",
                        title: "Qualifying Questions",
                        subTitle: "Add upto 10 questions",
                      }}
                      onConfirm={(assessSpec) => {
                        handleConfirmSection({
                          ...assessmentSpecsDetails,
                          qualifying_questions: assessSpec.qualifying_questions,
                        });
                      }}
                      onDelete={() => {
                        setSectionToDelete({
                          index: 1,
                          showModal: true,
                        });
                        setPreviouQualifyingQuestion(false);
                      }}
                      checkValidation={checkValidation}
                      setCheckValidation={setCheckValidation}
                      activeSection={activeSection}
                      getActiveSection={(section) => {
                        setActiveSection({
                          type: "qualifying_questions",
                          section: section,
                        });
                      }}
                      openSectionType={openSectionType}
                      setOpenSectionType={setOpenSectionType}
                    />
                  </div>
                </div>
              )}
              <div
                style={{
                  display: showAdditionalOptions ? "none" : "flex",
                  flexDirection: "column",
                }}
              >
                <div className="w-100 h-100" ref={setAssessmentSectionRef}>
                  <div className="w-100 h-100" ref={assessmentSectionRef}>
                    <CreateAssessment
                      missingTests={missingTests}
                      assessSpecTitle={assessSpec?.curr_assessment_spec?.title}
                      resetAssessmentCreationSection={
                        resetAssessmentCreationSection
                      }
                      getResetAssessmentCreationSection={(_boolean) => {
                        setResetAssessmentCreationSection(_boolean);
                      }}
                      getCurrentAssessmentComponentData={(data) => {
                        setAssessmentDataDataBeforePublish({ ...data });
                        if (data !== undefined) {
                          const _assess = {
                            ...assessmentSpecsDetails,
                            sections: data?.sections,
                            tests: data?.tests,
                          };
                          handleConfirmSection(_assess);
                        }
                      }}
                      assessmentSpecsDetails={assessmentSpecsDetails}
                      getSelectedOnRightSideMenuIndex={(index) => {
                        setSelectedOnRightSideMenuIndex(index);
                      }}
                      onUpdateSections={handleConfirmSection}
                      assessmentDataDataBeforePublish={
                        assessmentDataDataBeforePublish
                      }
                      getOnTabAddSectionStepNo={(onTabAddSectionStepNo) => {
                        setOnTabAddSectionStepNo(onTabAddSectionStepNo);
                      }}
                      onTabAddSectionStepNo={onTabAddSectionStepNo}
                      skillTestPacks={testPacks.data?.skill_test_packs}
                      codingTestPacks={testPacks.data?.coding_test_packs}
                      valueFitnessTestPacks={
                        testPacks.data?.value_fitness_test_packs
                      }
                      situationalJudgmentTestPack={
                        testPacks.data?.situational_judgment_test_packs
                      }
                      checkValidation={checkValidation}
                      setCheckValidation={setCheckValidation}
                      activeSection={activeSection}
                      getActiveSection={(section) => {
                        setActiveSection({
                          type: "create_assessment",
                          section: section,
                        });
                      }}
                      openSectionType={openSectionType}
                      setOpenSectionType={setOpenSectionType}
                      showInfoPage={(props) => {
                        setSelectedTestInfo({
                          openModal: props.openModal,
                          test: props.test,
                          isItemAdded: props.isItemAdded,
                        });
                      }}
                      selectedSectionIndex={selectedSectionIndex}
                      setSelectedSectionIndex={setSelectedSectionIndex}
                      selectedSectionType={selectedSectionType}
                      setSelectedSectionType={setSelectedSectionType}
                      errorsInSections={errorsInSections}
                      setErrorsInSections={setErrorsInSections}
                      fromDragIndex={fromDragIndex}
                      setFromDragIndex={setFromDragIndex}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="assessment-creation-page-right-side-content hide-scrollbar">
              <CreateAssessmentAdditionalSections
                setPreviouVideoState={setPreviouVideoState}
                setPreviouQualifyingQuestion={setPreviouQualifyingQuestion}
                rightSideMenu={rightSideMenu}
                welcomeVideoInView={welcomeVideoInView}
                setIsWelcomeVideoHighlighted={setIsWelcomeVideoHighlighted}
                setIsQualifyingQuestionHightlighted={
                  setIsQualifyingQuestionHightlighted
                }
                welcomeVideoRef={welcomeVideoRef}
                setSelectedOnRightSideMenuIndex={(index) => {
                  setSelectedOnRightSideMenuIndex(index);
                }}
                selectedOnRightSideMenuIndex={selectedOnRightSideMenuIndex}
                qualifyingQuestionInView={qualifyingQuestionInView}
                qualifyingQuestionRef={qualifyingQuestionRef}
                assessmentSectionRef={assessmentSectionRef}
                assessmentSpecsDetails={assessmentSpecsDetails}
                setAssessmentSpecsDetails={(data) => {
                  setAssessmentSpecsDetails(data);
                }}
                setRightSideMenu={(data) => {
                  setRightSideMenu(data);
                }}
                setIsACFBoxExtended={(data) => {
                  setIsACFBoxExtended(data);
                }}
                deadline={assessSpec?.curr_assessment_spec?.deadline}
                isACFBoxExtended={isACFBoxExtended}
                handleConfirmSection={(value) => {
                  handleConfirmSection(value);
                }}
                handleUpdateDeadline={handleUpdateDeadline}
                duration={assessSpec?.curr_assessment_spec?.duration}
                assessmentSectionInView={assessmentSectionInView}
                setExtendDeadlineBox={(data) => {
                  setExtendDeadlineBox(data);
                }}
                extendDeadlineBox={extendDeadlineBox}
                setDeadlineError={(data) => {
                  setDeadlineError(data);
                }}
                deadlineError={deadlineError}
                customDeadline={customDeadline}
                setCustomDeadline={(data) => {
                  setCustomDeadline(data);
                }}
                // props for assessment creation test type section
                selectedSectionIndex={selectedSectionIndex}
                setSelectedSectionIndex={setSelectedSectionIndex}
                selectedSectionType={selectedSectionType}
                setSelectedSectionType={setSelectedSectionType}
                errorsInSections={errorsInSections}
                setErrorsInSections={setErrorsInSections}
                getCurrentAssessmentComponentData={(data) => {
                  setAssessmentDataDataBeforePublish({ ...data });
                  if (data !== undefined) {
                    const _assess = {
                      ...assessmentSpecsDetails,
                      sections: data?.sections,
                      tests: data?.tests,
                    };
                    handleConfirmSection(_assess);
                  }
                }}
                getSelectedOnRightSideMenuIndex={(index) => {
                  setSelectedOnRightSideMenuIndex(index);
                }}
                onUpdateSections={handleConfirmSection}
                fromDragIndex={fromDragIndex}
                setFromDragIndex={setFromDragIndex}
              />
            </div>
          </div>
        ) : (
          <div className="assessment-creation-dashboard-page hide-scrollbar">
            <div className="assessment-creation-page-content hide-scrollbar">
              {!selectedTestInfo.openModal ? (
                <div className="w-100 d-flex flex-column align-items-start">
                  <AssessmentCreationHeader
                    onTabAddSectionStepNo={onTabAddSectionStepNo}
                    handlePublishClick={handlePublishClick}
                    handleAiAssessmentClick={handleAiAssessmentClick}
                    getOnTabAddSectionStepNo={(onTabAddSectionStepNo) => {
                      setOnTabAddSectionStepNo(onTabAddSectionStepNo);
                    }}
                    assessment={{
                      title: assessSpec?.curr_assessment_spec?.title,
                      updated_at: assessSpec?.curr_assessment_spec?.updated_at,
                    }}
                    onConfirmTitle={updateAssessmentSpecTitle}
                    onPicUpload={updateAssessmentSpecAvtarPic}
                    totalDuration={Math.floor(
                      assessSpec?.curr_assessment_spec?.objective_test_duration
                    )}
                    currentStep={currentStep}
                    getCurrentSetp={handleConfirmAssessmentSpec}
                    getActiveSection={() => {
                      setActiveSection({
                        type: "default",
                        section: "default",
                      });
                    }}
                    isObjectiveTime={
                      assessSpec?.curr_assessment_spec
                        ?.objective_test_duration !== undefined ||
                      assessSpec?.curr_assessment_spec
                        ?.objective_test_duration !== null
                        ? true
                        : false
                    }
                  />
                  <>
                    {showAdditionalOptions ? (
                      <div
                        className="assessment-creation-page-right-side-content hide-scrollbar"
                        style={{
                          display: showAdditionalOptions ? "block" : "none",
                        }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{ gap: "16px" }}
                        >
                          <div
                            className="d-flex align-items-center justify-content-center pointer"
                            style={{
                              width: "44px",
                              height: "44px",
                              background: "#F6F5F8",
                              borderRadius: "50px",
                            }}
                            onClick={handleRevert}
                          >
                            <LeftArrowIcon />
                          </div>
                          <span className="body-2 dark-50">
                            Create Assessment
                          </span>
                        </div>
                        <CreateAssessmentAdditionalSections
                          rightSideMenu={rightSideMenu}
                          setPreviouVideoState={setPreviouVideoState}
                          setPreviouQualifyingQuestion={
                            setPreviouQualifyingQuestion
                          }
                          welcomeVideoInView={welcomeVideoInView}
                          welcomeVideoRef={welcomeVideoRef}
                          setIsWelcomeVideoHighlighted={
                            setIsWelcomeVideoHighlighted
                          }
                          setIsQualifyingQuestionHightlighted={
                            setIsQualifyingQuestionHightlighted
                          }
                          setSelectedOnRightSideMenuIndex={(index) => {
                            setSelectedOnRightSideMenuIndex(index);
                          }}
                          selectedOnRightSideMenuIndex={
                            selectedOnRightSideMenuIndex
                          }
                          qualifyingQuestionInView={qualifyingQuestionInView}
                          qualifyingQuestionRef={qualifyingQuestionRef}
                          assessmentSectionRef={assessmentSectionRef}
                          assessmentSpecsDetails={assessmentSpecsDetails}
                          setAssessmentSpecsDetails={(data) => {
                            setAssessmentSpecsDetails(data);
                          }}
                          setRightSideMenu={(data) => {
                            setRightSideMenu(data);
                          }}
                          setIsACFBoxExtended={(data) => {
                            setIsACFBoxExtended(data);
                          }}
                          deadline={assessSpec?.curr_assessment_spec?.deadline}
                          isACFBoxExtended={isACFBoxExtended}
                          handleConfirmSection={(value) => {
                            handleConfirmSection(value);
                          }}
                          handleUpdateDeadline={handleUpdateDeadline}
                          duration={assessSpec?.curr_assessment_spec?.duration}
                          assessmentSectionInView={assessmentSectionInView}
                          setExtendDeadlineBox={(data) => {
                            setExtendDeadlineBox(data);
                          }}
                          extendDeadlineBox={extendDeadlineBox}
                          setDeadlineError={(data) => {
                            setDeadlineError(data);
                          }}
                          deadlineError={deadlineError}
                          customDeadline={customDeadline}
                          setCustomDeadline={(data) => {
                            setCustomDeadline(data);
                          }}
                          // props for assessment creation test type section
                          selectedSectionIndex={selectedSectionIndex}
                          setSelectedSectionIndex={setSelectedSectionIndex}
                          selectedSectionType={selectedSectionType}
                          setSelectedSectionType={setSelectedSectionType}
                          errorsInSections={errorsInSections}
                          setErrorsInSections={setErrorsInSections}
                          getCurrentAssessmentComponentData={(data) => {
                            setAssessmentDataDataBeforePublish({ ...data });
                            if (data !== undefined) {
                              const _assess = {
                                ...assessmentSpecsDetails,
                                sections: data?.sections,
                                tests: data?.tests,
                              };
                              handleConfirmSection(_assess);
                            }
                          }}
                          getSelectedOnRightSideMenuIndex={(index) => {
                            setSelectedOnRightSideMenuIndex(index);
                          }}
                          onUpdateSections={handleConfirmSection}
                          fromDragIndex={fromDragIndex}
                          setFromDragIndex={setFromDragIndex}
                        />
                      </div>
                    ) : (
                      <div
                        className="w-100 d-flex "
                        style={{ height: "calc(100% - 144px)" }}
                      >
                        <div className="w-100 h-100 d-flex flex-column">
                          {assessmentSpecsDetails?.welcome_video !==
                            undefined && (
                            <div className="position-relative">
                              {loading && (
                                <div
                                  className="w-100 h-100 d-flex align-item-center justify-content-center"
                                  style={{
                                    position: "absolute",
                                    background: "rgb(150, 153, 156, 0.2)",
                                    zIndex: 10,
                                  }}
                                >
                                  <div
                                    style={{
                                      top: "0",
                                      bottom: "0",
                                      left: "0",
                                      right: "0",
                                      height: "25px",
                                      width: "25px",
                                      margin: "auto",
                                      zIndex: 999,
                                    }}
                                  >
                                    <div
                                      className="processing-indicator primary"
                                      style={{ width: "100%", height: "100%" }}
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="mb-3" ref={setWelcomeVideoRef}>
                                <div ref={welcomeVideoRef}>
                                  <WelcomeVideo
                                    viewOnly={props.viewOnly}
                                    onSelect={() => {
                                      setSelectedOnRightSideMenuIndex(0);
                                    }}
                                    isSelected={
                                      selectedOnRightSideMenuIndex === 0
                                        ? true
                                        : false
                                    }
                                    assessmentSpecsDetails={
                                      assessmentSpecsDetails
                                    }
                                    getCurrentWVComponentData={(data) => {
                                      setWVDataBeforePublish({ ...data });
                                    }}
                                    onConfirm={handleConfirmSection}
                                    onDelete={() => {
                                      setSectionToDelete({
                                        index: 0,
                                        showModal: true,
                                      });
                                    }}
                                    onCancel={(action) => {
                                      if (action === "delete") {
                                        const rsm = { ...rightSideMenu };
                                        const _assessmentDetails =
                                          assessmentSpecsDetails;
                                        delete rsm.welcome_video;
                                        delete _assessmentDetails.welcome_video;
                                        setRightSideMenu(rsm);
                                        setAssessmentSpecsDetails({
                                          ..._assessmentDetails,
                                        });
                                      } else {
                                        setSelectedOnRightSideMenuIndex(1);
                                      }
                                    }}
                                    assessSpec={assessSpec}
                                    checkValidation={checkValidation}
                                    activeSection={activeSection}
                                    getActiveSection={(section) => {
                                      setActiveSection({
                                        type: "welcome_video",
                                        section: section,
                                      });
                                    }}
                                    openSectionType={openSectionType}
                                    setOpenSectionType={setOpenSectionType}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {assessmentSpecsDetails?.qualifying_questions !==
                            undefined && (
                            <div className="mb-3" ref={setQualifyingTestRef}>
                              <div ref={qualifyingQuestionRef}>
                                <CreateQualifyingQuestions
                                  resetQQ={resetQQ}
                                  getResetQQ={(_boolean) => {
                                    setResetQQ(_boolean);
                                  }}
                                  getCurrentQQComponentData={(data) => {
                                    setQQDataBeforePublish([...data]);
                                    if (data !== undefined) {
                                      const _assess = {
                                        ...assessmentSpecsDetails,
                                        qualifying_questions: data,
                                      };
                                      handleConfirmSection(_assess);
                                    }
                                  }}
                                  assessmentSpecsDetails={
                                    assessmentSpecsDetails
                                  }
                                  section={{
                                    type: "qq",
                                    title: "Qualifying Questions",
                                    subTitle: "Add upto 10 questions",
                                  }}
                                  onConfirm={(assessSpec) => {
                                    handleConfirmSection({
                                      ...assessmentSpecsDetails,
                                      qualifying_questions:
                                        assessSpec.qualifying_questions,
                                    });
                                  }}
                                  onDelete={() => {
                                    setSectionToDelete({
                                      index: 1,
                                      showModal: true,
                                    });
                                  }}
                                  checkValidation={checkValidation}
                                  setCheckValidation={setCheckValidation}
                                  activeSection={activeSection}
                                  getActiveSection={(section) => {
                                    setActiveSection({
                                      type: "qualifying_questions",
                                      section: section,
                                    });
                                  }}
                                  openSectionType={openSectionType}
                                  setOpenSectionType={setOpenSectionType}
                                />
                              </div>
                            </div>
                          )}
                          <div
                            style={{
                              display: showAdditionalOptions ? "none" : "flex",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              className="w-100 h-100 mb-3"
                              ref={setAssessmentSectionRef}
                            >
                              <div
                                className="w-100 h-100"
                                ref={assessmentSectionRef}
                              >
                                <CreateAssessment
                                  missingTests={missingTests}
                                  assessSpecTitle={
                                    assessSpec?.curr_assessment_spec?.title
                                  }
                                  resetAssessmentCreationSection={
                                    resetAssessmentCreationSection
                                  }
                                  getResetAssessmentCreationSection={(
                                    _boolean
                                  ) => {
                                    setResetAssessmentCreationSection(_boolean);
                                  }}
                                  getCurrentAssessmentComponentData={(data) => {
                                    setAssessmentDataDataBeforePublish({
                                      ...data,
                                    });
                                    if (data !== undefined) {
                                      const _assess = {
                                        ...assessmentSpecsDetails,
                                        sections: data?.sections,
                                        tests: data?.tests,
                                      };
                                      handleConfirmSection(_assess);
                                    }
                                  }}
                                  assessmentSpecsDetails={
                                    assessmentSpecsDetails
                                  }
                                  getSelectedOnRightSideMenuIndex={(index) => {
                                    setSelectedOnRightSideMenuIndex(index);
                                  }}
                                  onUpdateSections={handleConfirmSection}
                                  assessmentDataDataBeforePublish={
                                    assessmentDataDataBeforePublish
                                  }
                                  getOnTabAddSectionStepNo={(
                                    onTabAddSectionStepNo
                                  ) => {
                                    setOnTabAddSectionStepNo(
                                      onTabAddSectionStepNo
                                    );
                                  }}
                                  onTabAddSectionStepNo={onTabAddSectionStepNo}
                                  skillTestPacks={
                                    testPacks.data?.skill_test_packs
                                  }
                                  codingTestPacks={
                                    testPacks.data?.coding_test_packs
                                  }
                                  valueFitnessTestPacks={
                                    testPacks.data?.value_fitness_test_packs
                                  }
                                  situationalJudgmentTestPack={
                                    testPacks.data
                                      ?.situational_judgment_test_packs
                                  }
                                  checkValidation={checkValidation}
                                  setCheckValidation={setCheckValidation}
                                  activeSection={activeSection}
                                  getActiveSection={(section) => {
                                    setActiveSection({
                                      type: "create_assessment",
                                      section: section,
                                    });
                                  }}
                                  openSectionType={openSectionType}
                                  setOpenSectionType={setOpenSectionType}
                                  showInfoPage={(props) => {
                                    setSelectedTestInfo({
                                      openModal: props.openModal,
                                      test: props.test,
                                      isItemAdded: props.isItemAdded,
                                    });
                                  }}
                                  selectedSectionIndex={selectedSectionIndex}
                                  setSelectedSectionIndex={
                                    setSelectedSectionIndex
                                  }
                                  selectedSectionType={selectedSectionType}
                                  setSelectedSectionType={
                                    setSelectedSectionType
                                  }
                                  errorsInSections={errorsInSections}
                                  setErrorsInSections={setErrorsInSections}
                                  fromDragIndex={fromDragIndex}
                                  setFromDragIndex={setFromDragIndex}
                                />
                              </div>
                            </div>
                            <div
                              className="d-flex justify-content-between align-items-center pointer"
                              style={{
                                background: "white",
                                borderRadius: "24px",
                                height: "76px",
                                padding: "16px 24px 16px 24px",
                              }}
                              onClick={toggleAdditionalOptions}
                            >
                              <span className="body-1 dark-100">
                                Additional Options
                              </span>
                              <NextArrowIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                </div>
              ) : (
                <div className="w-100 d-flex flex-column align-items-start">
                  <TestpackDetails
                    test={selectedTestInfo?.test}
                    isItemAdded={selectedTestInfo?.isItemAdded}
                    preview_questions={
                      selectedTestInfo?.test?.preview_questions
                    }
                    showPreviewPage={() =>
                      showPreviewPage(selectedTestInfo?.test?.id)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </>
    </>
  );
}
