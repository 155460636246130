import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";

import { ReactComponent as AddIcon } from "../../../../../assets/icons/Views/dashboard/assessments/plus.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/Views/dashboard/assessments/AddIcon2.svg";
import { ReactComponent as InfoIcon } from "../../../../../assets/icons/Views/dashboard/assessments/info.svg";
import { ReactComponent as EyeIcon } from "../../../../../assets/icons/Views/dashboard/assessments/eye.svg";
import { ReactComponent as CrossIcon } from "../../../../../assets/icons/Views/dashboard/assessments/x.svg";
import { ReactComponent as ClockIcon } from "../../../../../assets/icons/Views/dashboard/create-assessment/clock.svg";
import { ReactComponent as RightArrowIcon } from "../../../../../assets/icons/Views/dashboard/create-assessment/right-arrow.svg";
import { ReactComponent as LeftArrowIcon } from "../../../../../assets/icons/Views/dashboard/create-assessment/Vector.svg";
import { ReactComponent as PrimaryCrossIcon } from "../../../../../assets/icons/Views/dashboard/assessments/primary-x.svg";
import { ReactComponent as WarningIcon } from "../../../../../assets/icons/Views/dashboard/assessments/alert-circle.svg";

import SectionContainer from "./SectionContainer";
import Search from "../../../../sub-component/Search";
import Button from "../../../../sub-component/Button";
import SearchAndFilterTests from "./SearchAndFilterTests";
import { TestType } from "../../../../../utils/Constants";
import { PathName } from "../../../../../utils/routes";
import CustomModal from "../../../../common/Modal";
import { useMediaQuery } from "@mui/material";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";

export default function SituationalJudgmentTest(props) {
  const isOnWebScreen = useMediaQuery("(min-width:1280px)");
  const isOnTabletScreen = useMediaQuery(
    "(min-width:768px) and (max-width:1279px)"
  );
  const isOnMobileScreen = useMediaQuery("(max-width: 767px)");
  const [selectedData, setSelectedData] = useState(
    props?.sectionDetails?.test?.questions
  );
  const limit = 5;
  const [dataList, setDataList] = useState(props.situationalJudgmentTestPack);
  const [filteredDataList, setFilteredDataList] = useState(
    props.situationalJudgmentTestPack
  );

  const [start, setStart] = useState(0);
  const [Finish, setFinish] = useState(9);
  const [isSkillTestOverFlow, setIsSkillTestOverFlow] = useState(false);
  const [selectedTestInfo, setSelectedTestInfo] = useState({
    test: null,
    openModal: false,
  });

  const overFlowRef = useRef(null);

  useEffect(() => {
    if (overFlowRef?.current) {
      const divElement = overFlowRef.current;

      if (
        divElement.scrollWidth > divElement.clientWidth ||
        divElement.scrollHeight > divElement.clientHeight
      ) {
        setIsSkillTestOverFlow(true);
      } else {
        setIsSkillTestOverFlow(false);
      }
    }
  }, [selectedData]);

  const scrollLeft = () => {
    if (overFlowRef.current) {
      overFlowRef.current.scrollBy({
        left: -260, // Adjust the scroll amount as per your requirement
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (overFlowRef.current) {
      overFlowRef.current.scrollBy({
        left: 260, // Adjust the scroll amount as per your requirement
        behavior: "smooth",
      });
    }
  };

  const showPreviewPage = (id) => {
    const url = `${PathName.TESTPACK_PREVIEW_QUESTIONS}?test_id=${id}`;
    window.open(url, "_blank");
  };

  const filterOptions = [
    {
      type: "check-box",
      title: "Choose Difficulty Level",
      filter_for: "difficulty",
      options: [
        { text: "Entry Level", id: "entry-level" },
        { text: "Intermediate Level", id: "intermediate" },
        { text: "Advanced Level", id: "advanced" },
      ],
    },
  ];

  const SkillButtonComp = (props) => {
    const { checked, preview_questions } = props;
    return (
      <div
        className={`d-flex flex-column justify-content-between${
          props.checked
            ? " selected-skill-based-test-box-container "
            : " unselect-skill-based-test-box-container "
        } skill-based-test-box-container pointer`}
        style={{ ...props.style }}
      >
        <div className="d-flex flex-column w-100 position-relative">
          {!checked && (
            <>
              <div
                className="d-flex justify-content-between"
                style={{ gap: "13.33px" }}
              >
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{
                    padding: "6px 20px",
                    background: "#7C21FF",
                    // backgroundColor:'#7C21FF',
                    borderRadius: "24px",
                    width: "110px",
                  }}
                >
                  <span className="body-3 white">Most Popular</span>
                </div>
                {!isOnTabletScreen && (
                  <div className="d-flex align-items-center justify-content-center">
                    <ClockIcon style={{ width: "16px", height: "16px" }} />
                    <span
                      className="body-2 dark-50"
                      style={{ marginLeft: "4px" }}
                    >{`${Math.floor(props?.duration / 60)} mins`}</span>
                  </div>
                )}
              </div>
              {isOnTabletScreen && (
                <div
                  className="d-flex align-items-center"
                  style={{ marginTop: "6px" }}
                >
                  <ClockIcon style={{ width: "16px", height: "16px" }} />
                  <span
                    className="body-2 dark-50"
                    style={{ marginLeft: "4px" }}
                  >{`${Math.floor(props?.duration / 60)} mins`}</span>
                </div>
              )}
            </>
          )}
          <div
            className="d-flex align-items-start"
            style={{ width: "100%", marginTop: "16px" }}
          >
            <div
              className="d-flex flex-column"
              style={{ width: "75%", maxHeight: "48px" }}
            >
              <span
                className="body-1 dark-100 skill-based-test-box-container-text-overflow text-overflow"
                style={{
                  textAlign: "start",
                  marginRight: checked ? "48px" : "",
                  marginTop: checked ? "-12px" : "",
                }}
              >
                {props.title}
              </span>
              {!checked && (
                <span
                  className="body-1 dark-50 text-start"
                  style={{
                    marginTop: "6px",
                  }}
                >
                  {props.difficulty || "N/A"}
                </span>
              )}
            </div>
            <div className="d-flex ">
              {preview_questions && (
                <EyeIcon
                  className="svg"
                  style={{
                    width: "16px",
                    height: "16px",
                    position: "absolute",
                    right: "22px",
                  }}
                  onClick={props.showPreviewPage}
                />
              )}
              <InfoIcon
                className="svg"
                style={{
                  width: "14px",
                  height: "14px",
                  position: "absolute",
                  right: "0px",
                }}
                onClick={
                  isOnMobileScreen ? props.showInfoPage : props.showInfoModal
                }
              />
            </div>
          </div>
          {checked && (
            <div
              className="d-flex align-items-center"
              style={{ marginTop: "6px" }}
            >
              <ClockIcon style={{ width: "16px", height: "16px" }} />
              <span
                className="body-4 black-500"
                style={{ marginLeft: "4px" }}
              >{`${Math.floor(props?.duration / 60)} mins`}</span>
            </div>
          )}
        </div>
        <Button
          onClick={() => {
            props.onClick();
          }}
          className={`${
            checked
              ? "selected-skill-based-test-box-btn"
              : !checked && selectedData.length === limit
              ? "disabled-skill-based-test-box-btn"
              : "skill-based-test-box-btn"
          }`}
          style={{
            height: "32px",
            marginBottom: checked && "8px",
            background: "#121216",
          }}
          btn="ragular-btn"
          disabled={!checked && selectedData.length === limit}
          varrient="secondary-btn"
          size="sm"
          title={checked ? "Remove" : "Add"}
          preIcon={
            checked ? (
              <CrossIcon style={{ width: "15.4px" }} />
            ) : (
              <PlusIcon style={{ width: "15.4px" }} />
            )
          }
        />
      </div>
    );
  };

  const DataListMemo = useMemo(() => {
    return (
      <>
        {filteredDataList?.map((data, index) => {
          const isSelected = selectedData.find((d) => d.id === data.id);
          const preview_questions = data?.preview_questions;
          return (
            <Fragment key={index}>
              {!isSelected && (
                <SkillButtonComp
                  title={data}
                  style={{ marginTop: "0px" }}
                  {...data}
                  checked={false}
                  onClick={() => {
                    setSelectedData([...selectedData, data]);
                    props.getUpdatedSectionLocalData({
                      ...props.sectionDetails,
                      test: {
                        ...props.sectionDetails.test,
                        questions: [...selectedData, data],
                      },
                    });
                    props.onCheckSectionDataConfermation({
                      ...props.sectionDetails,
                      test: {
                        ...props.sectionDetails.test,
                        questions: [...selectedData, data],
                      },
                    });
                  }}
                  preview_questions={preview_questions}
                  showPreviewPage={() => showPreviewPage(data.id)}
                  showInfoPage={() => {
                    setSelectedTestInfo({
                      openModal: true,
                      test: data,
                      isItemAdded: selectedData.find((d) => d.id === data?.id),
                    });
                    props.showInfoPage({
                      openModal: true,
                      test: data,
                      isItemAdded: selectedData.find((d) => d.id === data?.id),
                    });
                  }}
                  showInfoModal={() => {
                    setSelectedTestInfo({ openModal: true, test: data });
                  }}
                />
              )}
            </Fragment>
          );
        })}
      </>
    );
  }, [selectedData, start, Finish, filteredDataList]);

  const SelectedDataListMemo = useMemo(() => {
    return (
      <div className="d-flex align-items-center position-relative w-100">
        {isSkillTestOverFlow && (
          <div
            className="selected-test-overflow-arrow-left pointer"
            style={{ position: "absolute", left: "0px", zIndex: "1" }}
            onClick={scrollLeft}
          >
            <LeftArrowIcon style={{ width: "32px", height: "32px" }} />
          </div>
        )}
        <div
          ref={overFlowRef}
          className="searched-filtered-ct-container hide-scrollbar"
          style={{ padding: isSkillTestOverFlow ? "0px 55px" : "0px 0px" }}
        >
          {selectedData?.map((data, index) => {
            return (
              <>
                {
                  <div className="searched-filtered-ct">
                    <SkillButtonComp
                      title={data}
                      style={{ marginTop: "8px", height: "100%" }}
                      {...data}
                      checked={true}
                      onClick={() => {
                        const tempArr = selectedData;
                        tempArr.splice(selectedData.indexOf(data), 1);
                        setSelectedData([...tempArr]);
                        props.getUpdatedSectionLocalData({
                          ...props.sectionDetails,
                          test: {
                            ...props.sectionDetails.test,
                            questions: [...tempArr],
                          },
                        });
                        props.onCheckSectionDataConfermation({
                          ...props.sectionDetails,
                          test: {
                            ...props.sectionDetails.test,
                            questions: [...tempArr],
                          },
                        });
                      }}
                      showPreviewPage={() => showPreviewPage(data.id)}
                      showInfoPage={() => {
                        setSelectedTestInfo({
                          openModal: true,
                          test: data,
                          isItemAdded: selectedData.find(
                            (d) => d.id === data?.id
                          ),
                        });
                        props.showInfoPage({
                          openModal: true,
                          test: data,
                          isItemAdded: selectedData.find(
                            (d) => d.id === data?.id
                          ),
                        });
                      }}
                      showInfoModal={() => {
                        setSelectedTestInfo({ openModal: true, test: data });
                      }}
                    />
                  </div>
                }
              </>
            );
          })}
        </div>
        {isSkillTestOverFlow && (
          <div
            className="selected-test-overflow-arrow-left pointer"
            style={{
              position: "absolute",
              right: "-2px",
              zIndex: "1",
              transform: "rotate(180deg)",
            }}
            onClick={scrollRight}
          >
            <LeftArrowIcon style={{ width: "32px", height: "32px" }} />
          </div>
        )}
      </div>
    );
  }, [selectedData, start, Finish, filteredDataList]);

  const onConfirm = () => {
    props.onConfirm({
      ...props.sectionDetails,
      test: { ...props.sectionDetails.test, questions: selectedData },
    });
  };

  const onCancel = () => {
    props.onCancel({
      ...props.sectionDetails,
      test: { ...props.sectionDetails.test, questions: selectedData },
    });
  };

  useEffect(() => {
    if (props.error) {
      props.setCheckValidation(true);
    }
  }, [selectedData]);

  useEffect(() => {
    if (props?.resetAssessmentCreationSection) {
      setSelectedData(props?.sectionDetails?.test?.questions);
      props?.getToKnowIFStateUpdated("situational_judgment_test_updated", true);
    }
  }, [props?.resetAssessmentCreationSection]);

  return (
    <>
      <CustomModal
        open={selectedTestInfo.openModal && !isOnMobileScreen}
        test={selectedTestInfo.test}
        handleCloseModal={() => {
          setSelectedTestInfo({ test: null, openModal: false });
        }}
        isItemAdded={selectedData.find(
          (d) => d.id === selectedTestInfo?.test?.id
        )}
        disabled={
          !selectedData.find((d) => d.id === selectedTestInfo?.test?.id) &&
          selectedData.length === limit
        }
        preview_questions={selectedTestInfo?.test?.preview_questions}
        showPreviewPage={() => showPreviewPage(selectedTestInfo?.test?.id)}
        varrient="view-test-details"
        add_remove_assessment={() => {
          if (selectedData.find((d) => d.id === selectedTestInfo?.test?.id)) {
            const tempArr = selectedData;
            tempArr.splice(selectedData.indexOf(selectedTestInfo?.test), 1);
            setSelectedData([...tempArr]);
            props.getUpdatedSectionLocalData({
              ...props.sectionDetails,
              test: { ...props.sectionDetails.test, questions: [...tempArr] },
            });
            props.onCheckSectionDataConfermation({
              ...props.sectionDetails,
              test: { ...props.sectionDetails.test, questions: [...tempArr] },
            });
          } else {
            setSelectedData([...selectedData, selectedTestInfo?.test]);
            props.getUpdatedSectionLocalData({
              ...props.sectionDetails,
              test: {
                ...props.sectionDetails.test,
                questions: [...selectedData, selectedTestInfo?.test],
              },
            });
            props.onCheckSectionDataConfermation({
              ...props.sectionDetails,
              test: {
                ...props.sectionDetails.test,
                questions: [...selectedData, selectedTestInfo?.test],
              },
            });
          }
        }}
      />
      <SectionContainer
        title={"Situational Judgment Test"}
        enableDuration
        error={props.error}
        description={
          " Your candidate will answer MCQs about the respective situational judgment. "
        }
        onRemoveSection={() => {
          props.onRemoveSection();
        }}
        onConfirm={onConfirm}
        onCancel={onCancel}
        duration={selectedData?.reduce(
          (preValue, data) => preValue + Math.floor(data.duration / 60),
          0
        )}
      >
        <div
          className="w-100 d-flex flex-column align-items-start"
          style={{ marginTop: "8px" }}
        >
          <SearchAndFilterTests
            dataList={dataList
              .map((test, _) => {
                if (
                  selectedData.filter((selctedFilter) => {
                    return selctedFilter.id === test.id;
                  }).length === 0
                ) {
                  return test;
                }
              })
              .filter((_d) => {
                return _d !== undefined;
              })}
            applyFuzzyFilter
            testPackType={TestType.SITUATIONAL_JUDGMENT_TEST}
            onSearchParams={{
              searchOnAttributes: ["title"],
              placeholder: `${
                isOnWebScreen
                  ? "Search language, keyword, problem..."
                  : "Search..."
              }`,
            }}
            filterOptions={[...filterOptions]}
            getResultentData={(data) => {
              setFilteredDataList(data);
            }}
            onSelection={(data) => {
              if (
                selectedData.filter((d) => d.id === data?.id).length === 0 &&
                selectedData.length < limit
              ) {
                setSelectedData([...selectedData, data]);
                props.getUpdatedSectionLocalData({
                  ...props.sectionDetails,
                  test: {
                    ...props.sectionDetails.test,
                    questions: [...selectedData, data],
                  },
                });
                props.onCheckSectionDataConfermation({
                  ...props.sectionDetails,
                  test: {
                    ...props.sectionDetails.test,
                    questions: [...selectedData, data],
                  },
                });
              }
            }}
          >
            {SelectedDataListMemo}
          </SearchAndFilterTests>
          {props.error && (
            <div className="error-box mt-2 w-100 mb-2">
              <div>
                <WarningIcon
                  style={{ width: "24px", height: "24px", marginRight: "12px" }}
                />
                <span className="body-2 text-danger">
                  At least one Situational Judgment Test should be selected!
                </span>
              </div>
            </div>
          )}
          {selectedData.length === limit && (
            <div className="error-box mt-2 w-100 mb-2">
              <div>
                <WarningIcon
                  style={{ width: "24px", height: "24px", marginRight: "12px" }}
                />
                <span className="body-2 text-danger">
                  You can only add 5 Situational Judgment Testpack at a time!
                </span>
              </div>
            </div>
          )}
          <div className="skill-based-test-container">{DataListMemo}</div>
        </div>
      </SectionContainer>
    </>
  );
}
