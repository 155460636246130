import React, { useState, useEffect, useMemo } from "react";
import TestHistoryCard from "./TestHistoryCard";
import Input from "../../../components/sub-component/Input";
import Select from "../../../components/sub-component/Select";
import Checkbox from "../../../components/sub-component/Checkbox";
import TestHistoryRightSideMenu from "./TestHistoryRightSideMenu";
import {
  getTestHistoryResult,
  getTestResultPageNo,
} from "../../../redux/thunks/TestHistory";
import { ReactComponent as BackArrow } from "../../../assets/icons/Arrows/CaretLeft.svg";
import { ReactComponent as ForwardArrow } from "../../../assets/icons/Arrows/CaretRight.svg";
import { ReactComponent as Search } from "../../../assets/icons/Views/candidate/assessments/search.svg";
import { ReactComponent as Filter } from "../../../assets/icons/Views/candidate/dashboard/filter.svg";
import { ReactComponent as Sort } from "../../../assets/icons/Views/candidate/dashboard/sort.svg";
import { useFocusWithin } from "@react-aria/interactions";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PathName } from "../../../utils/routes";
import { useMediaQuery } from "@mui/material";

export default function CandidateTestHistory() {
  const isOnWebScreen = useMediaQuery("(min-width:1340px)");

  const search = useLocation().search;
  const test_id = new URLSearchParams(search).get("test_id");

  const history = useHistory();
  const dispatch = useDispatch();
  const testHistory = useSelector((state) => state.testHistoryReducer);

  const [selectedTest, setSelectedTest] = useState(null);
  const [isRightMenuOpen, setIsRightMenuOpen] = useState(false);
  const [testData, setTestData] = useState([]);

  const [selectedSortByOption, setSelectedSortByOption] = useState({
    option: "expiry_date",
  });
  const [selectedFilterOption, setSelectedFilterOption] = useState({
    option: "null",
  });
  const [selectedsortOption, setSelectedsortOption] = useState({
    option: "ASC",
  });
  const [selectedLatestInstance, setSelectedLatestInstance] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [openFilters, setOpenFilters] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const FilterOptions = [
    { name: "All", value: "null" },
    { name: "Skill Test", value: "skill_test" },
    { name: "Coding Test", value: "coding_test" },
    { name: "Situational Judgment Test", value: "situational_judgment_test" },
  ];

  const sortingByOptions = [
    { name: "Expiry Date", value: "expiry_date" },
    { name: "Attempted Date", value: "attempted_at" },
  ];

  const sortOptions = [
    { name: "Ascending", value: "ASC" },
    { name: "Descending", value: "DESC" },
  ];

  const handleTestSelect = (data) => {
    history.push(`${PathName.CANDIDATES_TEST_HISTORY}`);
    setSelectedTest(data);
    setIsRightMenuOpen(true);
  };

  const handleTestUnSelect = () => {
    history.push(`${PathName.CANDIDATES_TEST_HISTORY}`);
    setSelectedTest(null);
    setIsRightMenuOpen(false);
  };

  const handleRemoveFilters = () => {
    setSelectedFilterOption({ ...selectedFilterOption, option: "null" });
    setSelectedLatestInstance(false);
    handleCloseAll();
  };

  const handleRemoveSorting = () => {
    setSelectedSortByOption({ ...selectedSortByOption, option: "expiry_date" });
    setSelectedsortOption({ ...selectedsortOption, option: "ASC" });
    handleCloseAll();
  };

  const handleCloseAll = () => {
    setOpenFilters(false);
    setOpenSort(false);
  };

  const nextSlide = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevSlide = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setTriggerSearch(true);
    }
  };

  const { focusWithinProps } = useFocusWithin({
    onFocusWithin: (e) => {},
    onBlurWithin: (e) => {
      handleCloseAll();
    },
  });

  useEffect(() => {
    if (test_id) {
      dispatch(
        getTestResultPageNo({
          data: {
            test_id: test_id,
          },
        })
      );
    }
  }, [test_id]);

  useEffect(() => {
    dispatch(
      getTestHistoryResult({
        data: {
          test_type: selectedFilterOption.option,
          latest_instance: selectedLatestInstance,
          search: searchQuery,
          sort: selectedSortByOption.option,
          sort_direction: selectedsortOption.option,
          page: currentPage,
          size: itemsPerPage,
        },
      })
    );
  }, [
    selectedFilterOption,
    selectedLatestInstance,
    searchQuery,
    selectedSortByOption,
    selectedsortOption,
    currentPage,
    testHistory?.test_history_result?.page_no,
  ]);

  useEffect(() => {
    if (testHistory?.test_history_result === null) return;
    setTestData(testHistory?.test_history_result?.results);
    setTotalItems(testHistory?.test_history_result?.count);
    if (testHistory?.test_history_result?.page_no) {
      setCurrentPage(testHistory?.test_history_result?.page_no);
    }
  }, [testHistory?.test_history_result]);

  useEffect(() => {
    let timer;
    const shouldOpenRightMenu = () => {
      return (
        !selectedTest &&
        !isRightMenuOpen &&
        testHistory?.test_history_result?.results?.some(
          (d) => d?.id === test_id
        )
      );
    };

    if (test_id) {
      setLoading(true);
      // timer = setTimeout(() => {
      if (shouldOpenRightMenu()) {
        const test = testHistory?.test_history_result?.results?.find(
          (d) => d?.id === test_id
        );
        setSelectedTest(test);
        setIsRightMenuOpen(true);
      }
      setLoading(false);
      // }, 4000);
    }

    return () => clearTimeout(timer);
  }, [
    test_id,
    testHistory?.test_history_result,
    selectedTest,
    isRightMenuOpen,
  ]);

  const NavBar = () => {
    return (
      <div className="nav-bar">
        <div className="assessments-search-bar">
          <div className="search">
            <Search height={"24px"} width={"24px"} />
            <Input
              inputStyle={{
                background: "transparent",
                border: "none",
              }}
              className={"w-100"}
              placeholder={"Search by assessment title..."}
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value), setTriggerSearch(false);
                setCurrentPage(1);
              }}
              onKeyPress={handleKeyPress}
            />
          </div>
        </div>
        <div
          tabIndex={-1}
          {...focusWithinProps}
          className="d-flex flex-row align-items-center"
          style={{ width: "84px", gap: "16px" }}
        >
          <div style={{ position: "relative" }}>
            <Filter
              height={"44px"}
              width={"44px"}
              cursor={"pointer"}
              onClick={() => setOpenFilters(!openFilters)}
            />
            {openFilters && (
              <div style={{ width: "21.875em" }} className="nav-popup">
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <span className="subtitle-2 dark-100">Advanced Filters</span>
                  <span
                    className="body-2 dark-100 pointer"
                    onClick={handleRemoveFilters}
                  >
                    Remove All
                  </span>
                </div>
                <div className="w-100 d-felx" style={{ padding: "6px 10px" }}>
                  <Checkbox
                    primary
                    title={"Latest Instance"}
                    alue={selectedLatestInstance}
                    checked={selectedLatestInstance}
                    onChange={() => {
                      setSelectedLatestInstance(!selectedLatestInstance);
                      setCurrentPage(1);
                    }}
                  />
                </div>
                <Select
                  readOnly
                  varrient="primary"
                  selected={selectedFilterOption.option}
                  options={FilterOptions}
                  onSelection={(option) => {
                    setSelectedFilterOption({
                      ...selectedFilterOption,
                      option: option.value,
                    });
                    setCurrentPage(1);
                  }}
                  placeholder={"Select filter"}
                />
              </div>
            )}
          </div>
          <div style={{ position: "relative" }}>
            <Sort
              height={"44px"}
              width={"44px"}
              cursor={"pointer"}
              onClick={() => setOpenSort(!openSort)}
            />
            {openSort && (
              <div style={{ width: "21.875em" }} className="nav-popup">
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <span className="subtitle-2 dark-100">Sort</span>
                  <span
                    className="body-2 dark-100 pointer"
                    onClick={handleRemoveSorting}
                  >
                    Remove All
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <span
                    style={{ width: "120px" }}
                    className="body-3 dark-100 text-start"
                  >
                    Sort By
                  </span>
                  <Select
                    readOnly
                    varrient="primary"
                    selected={selectedSortByOption.option}
                    options={sortingByOptions}
                    onSelection={(option) =>
                      setSelectedSortByOption({
                        ...selectedSortByOption,
                        option: option.value,
                      })
                    }
                    placeholder={"Choose property"}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <span
                    style={{ width: "120px" }}
                    className="body-3 dark-100 text-start"
                  >
                    Direction
                  </span>
                  <Select
                    readOnly
                    varrient="primary"
                    selected={selectedsortOption.option}
                    options={sortOptions}
                    onSelection={(option) =>
                      setSelectedsortOption({
                        ...selectedsortOption,
                        option: option.value,
                      })
                    }
                    placeholder={"Choose direction"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isOnWebScreen ? (
        <div className="candidate-test-history-view-with-sidebar">
          <div
            className={`candidate-test-history-left-sidebar-container hide-scrollbar ${
              isRightMenuOpen && "candidate-test-history-left-sidebar-open"
            }`}
          >
            <div className={`candidate-test-history-detail-container`}>
              <div className="candidate-test-history-header">
                <span className="headline-3 dark-100 text-start">{`Test History`}</span>
              </div>
              <div className="candidate-test-history">
                {NavBar()}
                <div className="test-history-cards hide-scrollbar">
                  {testHistory?.loading ||
                  testHistory?.test_history_result === null ||
                  loading ||
                  testHistory?.test_history_result?.page_no ? (
                    <div className="processing-indicator-wrapper medium-indicator">
                      <div
                        className="processing-indicator primary"
                        style={{ width: "40px", height: "40px" }}
                      />
                    </div>
                  ) : (
                    <>
                      {testData === null || !testData?.length ? (
                        <div
                          className="w-100 d-flex flex-column align-items center justify-content-center"
                          style={{
                            height: "190px",
                            background: "#fff",
                            gap: "12px",
                          }}
                        >
                          <span className="headline-medium medium">
                            No test history to show!
                          </span>
                          <span className="body-2 medium black-400">
                            Try Adjusting your filters.
                          </span>
                        </div>
                      ) : (
                        <>
                          {testData?.map((data, index) => {
                            return (
                              <TestHistoryCard
                                key={index}
                                data={data}
                                isRightMenuOpen={isRightMenuOpen}
                                onClick={() => {
                                  handleTestSelect(data);
                                }}
                                selectedTest={selectedTest}
                              />
                            );
                          })}
                          <div className="w-100 d-flex flex-row align-items-end justify-content-end">
                            <div className="table-pagination-container">
                              <span className="body-2 dark-50">{`Showing ${
                                (currentPage - 1) * itemsPerPage + 1
                              } - ${Math.min(
                                currentPage * itemsPerPage,
                                totalItems
                              )} `}</span>
                              <span className="body-2 dark-100">{` of ${totalItems}`}</span>
                              <BackArrow
                                className="pointer"
                                style={{ width: "20px", height: "20px" }}
                                onClick={() => {
                                  currentPage < 1 ? {} : prevSlide();
                                }}
                              />
                              <ForwardArrow
                                className="pointer"
                                style={{ width: "20px", height: "20px" }}
                                onClick={() => {
                                  currentPage === totalPages ? {} : nextSlide();
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {isRightMenuOpen && (
            <div
              className={`candidate-test-history-right-sidebar-container hide-scrollbar candidate-test-history-right-sidebar-open `}
            >
              <TestHistoryRightSideMenu
                test={selectedTest}
                selectedAssessmentIndex={testData?.findIndex(
                  (d) => d?.id === selectedTest?.id
                )}
                assessmentsLength={testData?.length}
                setSelectedTest={(index) => setSelectedTest(testData[index])}
                handleClose={() => {
                  handleTestUnSelect();
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="candidate-test-history-view-with-sidebar">
          {isRightMenuOpen ? (
            <div
              className={`candidate-test-history-right-sidebar-container hide-scrollbar candidate-test-history-right-sidebar-open `}
            >
              <TestHistoryRightSideMenu
                test={selectedTest}
                selectedAssessmentIndex={testData?.findIndex(
                  (d) => d?._id === selectedTest?._id
                )}
                assessmentsLength={testData?.length}
                setSelectedTest={(index) => setSelectedTest(testData[index])}
                handleClose={() => {
                  handleTestUnSelect();
                }}
              />
            </div>
          ) : (
            <div
              className={`candidate-test-history-left-sidebar-container hide-scrollbar ${
                isRightMenuOpen && "candidate-test-history-left-sidebar-open"
              }`}
            >
              <div className={`candidate-test-history-detail-container`}>
                <div className="candidate-test-history-header">
                  <span className="headline-3 dark-100 text-start">{`Test History`}</span>
                </div>
                <div className="candidate-test-history">
                  {NavBar()}
                  <div className="test-history-cards hide-scrollbar">
                    {testHistory.loading ||
                    testHistory?.test_history_result === null ||
                    loading ? (
                      <div className="processing-indicator-wrapper medium-indicator">
                        <div
                          className="processing-indicator primary"
                          style={{ width: "40px", height: "40px" }}
                        />
                      </div>
                    ) : (
                      <>
                        {(testData === null || !testData?.length) && (
                          <div
                            className="w-100 d-flex flex-column align-items center justify-content-center"
                            style={{
                              height: "190px",
                              background: "#fff",
                              gap: "12px",
                            }}
                          >
                            <span className="headline-medium medium">
                              No test history to show!
                            </span>
                            <span className="body-2 medium black-400">
                              Try Adjusting your filters.
                            </span>
                          </div>
                        )}
                        {testData?.map((data, index) => {
                          return (
                            <TestHistoryCard
                              key={index}
                              data={data}
                              isRightMenuOpen={isRightMenuOpen}
                              onClick={() => {
                                handleTestSelect(data);
                              }}
                              selectedTest={selectedTest}
                            />
                          );
                        })}
                        <div className="w-100 d-flex flex-row align-items-end justify-content-end">
                          <div className="table-pagination-container">
                            <span className="body-2 dark-50">{`Showing ${
                              (currentPage - 1) * itemsPerPage + 1
                            } - ${Math.min(
                              currentPage * itemsPerPage,
                              totalItems
                            )} `}</span>
                            <span className="body-2 dark-100">{` of ${totalItems}`}</span>
                            <BackArrow
                              className="pointer"
                              style={{ width: "20px", height: "20px" }}
                              onClick={() => {
                                currentPage < 1 ? {} : prevSlide();
                              }}
                            />
                            <ForwardArrow
                              className="pointer"
                              style={{ width: "20px", height: "20px" }}
                              onClick={() => {
                                currentPage === totalPages ? {} : nextSlide();
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
